export default () => ({
  avatarBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  name: {
    marginTop: 30,
    fontSize: 24,
    fontWeight: 600,
    color: '#1B1B1B',
  },
  dashedBorder: {
    margin: [30, 0],
    height: 1,
    backgroundImage: 'linear-gradient(to right, #e4e4e4 60%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '8px 1px',
    backgroundRepeat: 'repeat-x',
  },
  info: {
    padding: [0, 27, 0, 29],
  },
  sectionHeading: {
    marginBottom: 12,
    fontSize: 14,
    fontWeight: 600,
  },
  sectionContent: {
    fontSize: 16,
  },
});
