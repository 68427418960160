import React from 'react';
import PropTypes from 'prop-types';
import { deepmerge } from '@mui/utils';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';

import MuiButtonTheme from './MuiButtonTheme';
import MuiPaperTheme from './MuiPaperTheme';
import MuiTableHeadTheme from './MuiTableHeadTheme';
import MuiTableCellTheme from './MuiTableCellTheme';
import MuiDialogContentTheme from './MuiDialogContentTheme';
import MuiIconButtonTheme from './MuiIconButtonTheme';
import MuiCheckboxTheme from './MuiCheckboxTheme';
import MuiButtonBaseTheme from './MuiButtonBaseTheme';
import MuiInputLabelTheme from './MuiInputLabelTheme';
import MuiOutlinedInputTheme from './MuiOutlinedInputTheme';
import MuiSelectTheme from './MuiSelectTheme';
import MuiMenuTheme from './MuiMenuTheme';
import MuiSwitchTheme from './MuiSwitchTheme';
import MuiTabTheme from './MuiTabTheme';
import MuiTabsTheme from './MuiTabsTheme';

const theme = {
  components: {
    MuiButton: MuiButtonTheme,
    MuiPaper: MuiPaperTheme,
    MuiTableHead: MuiTableHeadTheme,
    MuiTableCell: MuiTableCellTheme,
    MuiDialogContent: MuiDialogContentTheme,
    MuiIconButton: MuiIconButtonTheme,
    MuiCheckbox: MuiCheckboxTheme,
    MuiButtonBase: MuiButtonBaseTheme,
    MuiInputLabel: MuiInputLabelTheme,
    MuiOutlinedInput: MuiOutlinedInputTheme,
    MuiSelect: MuiSelectTheme,
    MuiMenu: MuiMenuTheme,
    MuiSwitch: MuiSwitchTheme,
    MuiTabs: MuiTabsTheme,
    MuiTab: MuiTabTheme,
  },
  palette: {
    primary: {
      main: '#424FF5',
      dark: '#3D49DB',
    },
    warning: {
      main: '#F59842',
      contrastText: '#000',
    },
  },
  typography: {
    fontFamily: '"Matter", Helvetica, sans-serif',
    body1: {
      fontFamily: '"Matter", Helvetica, sans-serif',
      fontSize: 16,
    },
    button: {
      fontFamily: '"Matter", Helvetica, sans-serif',
    },
  },
};

const ThemeProvider = ({ children }) => (
  <MuiThemeProvider theme={magicTheme => createTheme(deepmerge(magicTheme, theme))}>
    {children}
  </MuiThemeProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ThemeProvider;
