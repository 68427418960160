import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';

import Pagination from 'components/UIKit/Pagination';

import { invitationListSelectors as selectors } from 'redux/selectors';
import { invitationListActions as actions } from 'redux/actions';

import InvitationTableSettingsLeftBlock from './InvitationTableSettingsLeftBlock';
import InvitationListLimitSelector from './InvitationListLimitSelector';

import styles from './InvitationTableSettings.styles';

const InvitationTableSettings = ({ classes }) => {
  const dispatch = useDispatch();

  const total = useSelector(selectors.totalItemsSelector);
  const totalPages = useSelector(selectors.totalPagesSelector);
  const page = useSelector(selectors.pageSelector);

  const handlePageChange = (newPage) => {
    dispatch(actions.getInvitationListRequest({ page: newPage }));
  };

  const perPage = useSelector(selectors.limitSelector);

  const renderPerPageInfo = () => {
    if (total > 0) {
      return <Fragment>{(perPage * (page - 1)) + 1}-{Math.min(perPage * page, total)} of {total}</Fragment>;
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <InvitationTableSettingsLeftBlock />
      <div className={classes.perPageInfo}>
        {renderPerPageInfo()}
      </div>
      {totalPages > 1 &&
        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
      }
      <InvitationListLimitSelector />
    </div>
  );
};

InvitationTableSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(InvitationTableSettings);
