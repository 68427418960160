const rootSelector = state => state.invitationList;

export const invitationsSelector = state => rootSelector(state).items;

export const totalItemsSelector = state => rootSelector(state).totalItems;

export const pageSelector = state => rootSelector(state).page;

export const limitSelector = state => rootSelector(state).limit;

export const totalPagesSelector = (state) => {
  const totalItems = totalItemsSelector(state);
  const perPage = limitSelector(state);

  return Math.ceil(totalItems / perPage);
};

export const searchQuerySelector = state => rootSelector(state).searchQuery;
