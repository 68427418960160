import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';
import { IconButton, Menu, MenuItem } from '@mip/magic';
import { ThreeDotMenu } from 'icons';
import UserDeleteDialog from 'components/dialogs/UserDeleteDialog';
import ModifyRoleDialog from 'components/dialogs/ModifyRoleDialog';

import { useDispatch, useSelector } from 'react-redux';
import { userDeletionActions, userRoleChangeActions } from 'redux/actions';
import { userDeletionSelectors, userRoleChangeSelectors } from 'redux/selectors';

import styles from './MoreActionsCell.styles';

const MoreActionsCell = ({ classes, user }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const deletionUserId = useSelector(userDeletionSelectors.deletionUserIdSelector);
  const roleChangeUserId = useSelector(userRoleChangeSelectors.roleChangeUserIdSelector);

  const id = user.user_id;

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModifyRoleDialog = () => {
    handleCloseMenu();
    dispatch(userRoleChangeActions.setRoleChangeUserId({ id }));
  };

  const handleCloseModifyRoleDialog = () => {
    dispatch(userRoleChangeActions.setRoleChangeUserId({ id: null }));
  };

  const handleOpenDeleteDialog = () => {
    handleCloseMenu();
    dispatch(userDeletionActions.setDeletionUserId({ id }));
  };

  const handleCloseDeleteDialog = () => {
    dispatch(userDeletionActions.setDeletionUserId({ id: null }));
  };

  return (
    <TableCell align="right" className={classes.root}>
      <IconButton onClick={handleOpenMenu}>
        <ThreeDotMenu />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMenu}
      >
        <MenuItem
          onClick={handleOpenModifyRoleDialog}
        >
          Modify role
        </MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleOpenDeleteDialog}
        >
          Delete user
        </MenuItem>
      </Menu>
      {roleChangeUserId === id && (
        <ModifyRoleDialog
          user={user}
          onClose={handleCloseModifyRoleDialog}
        />
      )}
      {deletionUserId === id && (
        <UserDeleteDialog
          id={id}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </TableCell>
  );
};

MoreActionsCell.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withTheme(styles)(MoreActionsCell);
