import React from 'react';
import { all, put, takeLatest } from 'redux-saga/effects';
import { userRoleChangeActions as actions, memberListActions, notify } from 'redux/actions';
import { request } from 'api/portal';

export function* changeUserRoleFlow({ payload: { id, role, email } }) {
  try {
    yield request({
      method: 'post',
      url: '/members/_change-role',
      data: {
        id,
        role,
      },
    });
    yield put(actions.changeUserRoleSuccess({ id, role }));
    yield put(notify({ title: <div>You successfully modified role for <b>{email}</b></div>, type: 'success' }));

    yield put(memberListActions.updateMemberRole({ id, role }));
  } catch (e) {
    yield put(actions.changeUserRoleFailure());
    yield put(notify({ title: 'Failed to modify user role', type: 'error' }));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.changeUserRole.type, changeUserRoleFlow),
  ]);
}
