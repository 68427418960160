export default () => ({
  root: {
    padding: [7, 12],

    '& > span': {
      marginRight: 4,
    },

    '& svg': {
      fill: '#ffffff',
    },
  },
});
