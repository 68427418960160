import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';

import { AvatarPlaceholder } from 'icons';

import styles from './Avatar.styles';

const Avatar = ({ classes, image }) => (
  <div className={classes.root}>
    {image ?
      <div className={classes.image} style={{ backgroundImage: `url(${image})` }} /> :
      <div className={classes.placeholder}><AvatarPlaceholder /></div>
    }
  </div>
);

Avatar.propTypes = {
  classes: PropTypes.object.isRequired,
  image: PropTypes.string,
};

Avatar.defaultProps = {
  image: undefined,
};

export default withTheme(styles)(Avatar);
