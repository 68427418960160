export default ({
  styleOverrides: {
    root: {
      minWidth: 70,
      minHeight: 30,
      marginRight: 10,
      padding: '5px 10px',
      fontFamily: '"Matter", Helvetica, sans-serif',
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '17px',
      border: '1px solid #E4E4E4',
      borderRadius: 5,
      color: '#1B1B1B',
      backgroundColor: '#F6F6F6',

      '&:last-of-type': {
        marginRight: 0,
      },

      '&.Mui-selected': {
        color: '#424FF5',
        backgroundColor: '#E4E5F9',
        borderColor: 'transparent',
      },
    },
  },
});
