import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';
import { IconButton, Menu, MenuItem } from '@mip/magic';
import { ThreeDotMenu } from 'icons';
import RevokeInvitationDialog from 'components/dialogs/RevokeInvitationDialog';

import { useDispatch, useSelector } from 'react-redux';
import { userInvitationActions as actions } from 'redux/actions';
import { userInvitationSelectors as selectors } from 'redux/selectors';

import styles from './MoreActionsCell.styles';

const MoreActionsCell = ({ classes, id, email }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const revokeInvitationId = useSelector(selectors.revokeInvitationIdSelector);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenRevokeDialog = () => {
    handleCloseMenu();
    dispatch(actions.setRevokeInvitationId({ id }));
  };

  const handleCloseRevokeDialog = () => {
    dispatch(actions.setRevokeInvitationId({ id: null }));
  };

  return (
    <TableCell align="right" className={classes.root}>
      <IconButton onClick={handleOpenMenu}>
        <ThreeDotMenu />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMenu}
      >
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleOpenRevokeDialog}
        >
          Revoke invitation
        </MenuItem>
      </Menu>
      {revokeInvitationId === id && (
        <RevokeInvitationDialog
          id={id}
          email={email}
          onClose={handleCloseRevokeDialog}
        />
      )}
    </TableCell>
  );
};

MoreActionsCell.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
};

export default withTheme(styles)(MoreActionsCell);
