import React from 'react';
import { useNavigate, useParams, useSearchParams, Navigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const withAuth0Provider = WrappedComponent => (props) => {
  const { orgName } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParamsOrgName = searchParams.get('organization_name');

  if (searchParamsOrgName && !searchParams.get('invitation')) { // for reset password case
    localStorage.setItem('last-org-name', searchParamsOrgName);

    return <Navigate to={`/${searchParamsOrgName}`} />;
  }

  const onRedirectCallback = (appState) => {
    const returnTo = appState?.returnTo;

    if (returnTo) {
      navigate(returnTo, { replace: true });
    }
  };

  const getOrganization = () => {
    if (searchParams.get('error')) {
      return undefined;
    }

    return orgName || searchParams.get('organization') || localStorage.getItem('last-org-name') || undefined;
  };

  return (
    <Auth0Provider
      domain={window.__RUNTIME_CONFIG__.AUTH_DOMAIN}
      clientId={window.__RUNTIME_CONFIG__.AUTH_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: window.__RUNTIME_CONFIG__.AUTH_AUDIENCE,
        organization: getOrganization(),
        invitation: searchParams.get('invitation') || undefined,
      }}
      cacheLocation="localstorage"
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      <WrappedComponent {...props} />
    </Auth0Provider>
  );
};

export default withAuth0Provider;
