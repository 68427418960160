import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';

import { useDidMount, useWillUnmount } from '@mip/utils';

import { portalUiService } from 'api';

import { REPORT_ISSUE_LINK } from 'constants/commonConstants';

const jsScript = window.__RUNTIME_CONFIG__.DEEP_SIGHTS_WIDGET_URL;
const scriptSrc = `${jsScript}?v=${new Date().getTime()}`;

const generateToken = async () => {
  try {
    const { data } = await portalUiService.generateToken();

    return data.access_token;
  } catch (e) {
    return null;
  }
};

const DSWidget = () => {
  const rootRef = useRef(null);
  const widgetRef = useRef(null);
  const { orgName } = useParams();

  const getPrimaryArtifactUrl = ({ artifactId }) => `/${orgName}/artifact-viewer/${artifactId}`;

  const getPrimaryArtifactPageUrl = ({ artifactId, pageNumber }) =>
    `/${orgName}/artifact-viewer/${artifactId}?page=${pageNumber}`;

  const termsAndConditionsLink = orgName.includes('-trial') ?
    'https://app.deepsights.ai/static/Market%20Logic%20DeepSights%20Trial%20Terms%20&%20Conditions.pdf' :
    undefined;

  useDidMount(() => {
    const script = document.createElement('script');
    script.src = scriptSrc;
    script.async = true;
    script.onload = () => {
      widgetRef.current = new window.DeepSightsWidget({
        getOAuthToken: (cb) => {
          generateToken().then(token => cb(token)).catch(() => cb(null));
        },
        container: rootRef.current,
        viewMode: 'expanded',
        reportIssuePageLink: REPORT_ISSUE_LINK,
        termsAndConditionsLink,
        isReportCreationEnabled: true,
        isAnswersV2Enabled: [
          'sales-05-fmcg',
          'educationuk-trial',
          'hp-deepsights-trial',
          'test-bordercon-connectors',
          'e2e-test-portal',
          'keysight-deepsights-trial',
        ].includes(orgName),
        integrationName: 'standalone',
        getPrimaryArtifactUrl,
        getPrimaryArtifactPageUrl,
        getScsItemUrl: ({ itemId }) => `/redirect?scs_item_id=${itemId}`,
      });
    };
    document.body.appendChild(script);
  });

  useWillUnmount(() => {
    if (widgetRef.current) {
      widgetRef.current.destroy();
    }
  });

  return (
    <div ref={rootRef} style={{ height: '100%' }} />
  );
};

export default DSWidget;
