import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    notify: (state, { payload }) => {
      const id = `${Math.random()}${+new Date()}`;
      state.push({ ...payload, id });
    },
    close: (state, { payload: { id } }) =>
      state.filter(item => item.id !== id),
  },
});

export const { notify, close } = slice.actions;

export const { actions } = slice;

export default slice.reducer;
