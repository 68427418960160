const downloadByLink = (url, title) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';

  /* eslint-disable func-names */
  xhr.onload = function () {
    if (this.status === 200) {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(this.response);
      link.download = title;

      const event = new MouseEvent('click');
      link.dispatchEvent(event);
    }
  };

  xhr.send();
};

export default downloadByLink;
