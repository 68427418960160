import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { artifactListSelectors as selectors } from 'redux/selectors';

import { getUrlParamFromSorting } from 'utils/sortingHelpers/artifactListSortingHelpers';

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useSelector(selectors.pageSelector);
  const status = useSelector(selectors.statusSelector);
  const limit = useSelector(selectors.limitSelector);
  const sorting = useSelector(selectors.sortingSelector);
  const searchQuery = useSelector(selectors.searchQuerySelector);

  useEffect(() => {
    if (page) {
      searchParams.set('page', page);
    }
    if (status !== 'all') {
      searchParams.set('status', status);
    } else {
      searchParams.delete('status');
    }
    if (limit === 10) {
      searchParams.delete('limit');
    } else {
      searchParams.set('limit', limit);
    }
    if (searchQuery) {
      searchParams.set('q', searchQuery);
    } else {
      searchParams.delete('q');
    }
    searchParams.set('sort', getUrlParamFromSorting(sorting.field_name, sorting.sorting_direction));
    setSearchParams(searchParams);
  }, [page, searchParams, setSearchParams, status, limit, sorting, searchQuery]);
};
