export default ({
  styleOverrides: {
    root: {
      fontSize: 16,
      letterSpacing: 'normal',
      color: '#1B1B1B',
      padding: '0 16px',
      height: 46,
      borderBottom: '1px dashed #E4E4E4',
    },
  },
});
