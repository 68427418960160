import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useAuth0 } from '@auth0/auth0-react';

import Avatar from './Avatar';

import styles from './UserProfileContent.styles';

const UserProfileContent = ({ classes }) => {
  const { user } = useAuth0();

  return (
    <Fragment>
      <div className={classes.avatarBlock}>
        <Avatar image={user.picture} />
        <div className={classes.name}>{user.nickname}</div>
      </div>
      <div className={classes.dashedBorder} />
      <div className={classes.info}>
        <div className={classes.sectionHeading}>
          Email Address
        </div>
        <div className={classes.sectionContent}>{user.email}</div>
      </div>
    </Fragment>
  );
};

UserProfileContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(UserProfileContent);
