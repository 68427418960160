export const COLUMNS = {
  EMAIL: 'EMAIL',
  CREATED_AT: 'CREATED_AT',
  ROLE: 'ROLE',
};

export const COLUMNS_LIST = [
  {
    type: COLUMNS.EMAIL,
    title: 'Email',
    isAlwaysVisible: true,
  },
  {
    type: COLUMNS.CREATED_AT,
    title: 'Invitation Date',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.ROLE,
    title: 'Role',
    isDefaultVisible: true,
  },
];
