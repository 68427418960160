/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deletionUserId: null,
  isDeleting: false,
};

export const slice = createSlice({
  name: 'userDeletion',
  initialState,
  reducers: {
    setDeletionUserId: (state, { payload: { id } }) => {
      state.deletionUserId = id;
    },
    deleteUser: (state) => {
      state.isDeleting = true;
    },
    deleteUserSuccess: (state) => {
      state.isDeleting = false;
      state.deletionUserId = null;
    },
    deleteUserFailure: (state) => {
      state.isDeleting = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
