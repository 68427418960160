export default () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    width: 264,
    height: '100%',
    padding: 29,
  },
});
