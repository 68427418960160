import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import alerts from './alerts/alertsReducer';
import artifactList from './artifacts/artifactList/artifactListReducer';
import artifactDeletion from './artifacts/artifactDeletion/artifactDeletionReducer';
import main from './main/mainReducer';
import artifactCreation from './artifacts/artifactCreation/artifactCreationReducer';
import artifactStatistics from './artifacts/artifactStatistics/artifactStatisticsReducer';
import user from './user/userReducer';
import sagas from './sagas';
import memberList from './userManagement/memberList/memberListReducer';
import invitationList from './userManagement/invitationList/invitationListReducer';
import userInvitation from './userManagement/userInvitation/userInvitationReducer';
import userDeletion from './userManagement/userDeletion/userDeletionReducer';
import userRoleChange from './userManagement/userRoleChange/userRoleChangeReducer';
import layout from './layout/layoutReducer';

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: combineReducers({
    alerts,
    artifactList,
    main,
    artifactDeletion,
    artifactCreation,
    user,
    artifactStatistics,
    memberList,
    invitationList,
    userInvitation,
    userDeletion,
    userRoleChange,
    layout,
  }),
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    serializableCheck: false,
  }).prepend(sagaMiddleware),
});

sagaMiddleware.run(sagas);

export default store;
