import { all, put, takeLatest } from 'redux-saga/effects';
import { notify } from 'redux/actions';
import { artifactService } from 'api';
import { actions } from './artifactStatisticsReducer';
import getArtifactsByStatusObject from './utils/getArtifactsByStatusObject';

export function* getStatisticsByStatusFlow() {
  try {
    const { data } = yield artifactService.getArtifactsStats();
    const statistics = {
      maxArtifacts: data.max_artifacts,
      artifactsByStatus: getArtifactsByStatusObject(data.status_buckets),
    };
    yield put(actions.getStatisticsSuccess(statistics));
  } catch (e) {
    yield put(notify({ title: 'Error when getting artifact statistics', type: 'error' }));
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getStatistics.type, getStatisticsByStatusFlow),
  ]);
}
