/* eslint-disable max-len */
export const hintList = [
  {
    dataHint: 'addFiles',
    title: 'Add files',
    description: 'Upload additional files to your repository by using the ‘Add files’ button or dragging and dropping them on the page. Please note that we only support PDF files.',
    placement: 'bottom',
  },
  {
    dataHint: 'statusSelect',
    title: 'Status',
    description: 'Easily refine your search results by selecting the file status filter.',
    placement: 'bottom',
  },
  {
    dataHint: 'columns',
    title: 'Columns',
    description: 'Tailor your table view to your preferences by conveniently showing or hiding columns.',
    placement: 'bottom',
  },
  {
    dataHint: 'results',
    title: 'Results',
    description: 'Adjust the number of results per page to match your preference and optimize your viewing experience.',
    placement: 'bottom',
  },
  {
    dataHint: 'fileUploader',
    title: 'Add files',
    description: 'Upload additional files to your repository by using the ‘Add files’ button or drag and drop them on the page. Please note that we only support PDF files.',
    placement: 'left',
  },
  {
    dataHint: 'storage',
    title: 'Storage',
    description: 'Track your storage space and easily visualize the types of files you have with our storage visualization feature.',
    placement: 'left',
  },
];
