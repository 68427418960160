export default () => ({
  selectedInfo: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 18,
  },
  actions: {
    position: 'relative',
    top: -1,
    marginLeft: 30,
  },
});
