export default () => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minHeight: 388,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: '#494949',
  },
  progressCircle: {
    position: 'relative',
    margin: '30px auto 0',
    width: '83%',
  },
  value: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    textAlign: 'center',
  },
  numberOfFiles: {
    fontWeight: 600,
    fontSize: 40,
  },
  maxFiles: {
    marginTop: 5,
    color: '#767676',
  },
  legend: {
    marginTop: 30,
    color: '#1B1B1B',
    minHeight: 135,
  },
  legendItem: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  legendLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  legendColor: {
    width: 15,
    height: 15,
    marginRight: 10,
    borderRadius: '50%',
  },
  legendCount: {
    fontWeight: 600,
  },
});
