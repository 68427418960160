import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';

import AvatarPlaceholder from './AvatarPlaceholder';

import styles from './InvitationEmailCell.styles';

const InvitationEmailCell = ({ classes, email }) => (
  <TableCell className={classes.root}>
    <div className={classes.wrapper}>
      <AvatarPlaceholder />
      <div className={classes.name}>{email}</div>
    </div>
  </TableCell>
);

InvitationEmailCell.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
};

export default withTheme(styles)(InvitationEmailCell);
