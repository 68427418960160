const rootSelector = state => state.artifactStatistics;

export const artifactsByStatusSelector = state => rootSelector(state).artifactsByStatus;

export const isLoadedSelector = state => rootSelector(state).isLoaded;

export const totalSelector = (state) => {
  const { processing, failed, completed } = artifactsByStatusSelector(state);

  return processing + failed + completed;
};

export const maxArtifactsSelector = state => rootSelector(state).maxArtifacts;

export const processingCountSelector = state => artifactsByStatusSelector(state).processing;
