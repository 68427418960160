import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LinearProgress } from '@mip/magic';

import { useDidMount } from '@mip/utils';

import { portalUiService } from 'api';

import { notify } from 'redux/actions';

const RedirectPage = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const tryToRedirect = async () => {
    try {
      const { data: { url } } = await portalUiService.resolveScsItemUrl(searchParams.get('scs_item_id'));

      window.location.replace(url);
    } catch (e) {
      dispatch(notify({ title: 'Something went wrong', type: 'error' }));
    }
  };

  useDidMount(() => {
    tryToRedirect();
  });

  return (
    <LinearProgress />
  );
};

export default RedirectPage;
