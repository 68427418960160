import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';

import { userInvitationActions as actions } from 'redux/actions';
import { userInvitationSelectors as selectors } from 'redux/selectors';

import InviteUsersDialogContent from './InviteUsersDialogContent';

import styles from './InviteUsersDialog.styles';

const InviteUsersDialog = ({ classes, isInvitationsPage }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectors.isInviteUsersDialogOpenSelector);
  const isLoading = useSelector(selectors.isLoadingSelector);

  const { formState: { errors } } = useForm();

  const handleClose = () => {
    dispatch(actions.setInviteUsersDialogOpen(false));
  };

  return (
    <Dialog classes={classes} open={isOpen} onClose={handleClose}>
      <DialogHeader title={<span className={classes.title}>Invite users</span>}>
        <Button className={classes.cancelBtn} onClick={handleClose}>Cancel</Button>
        <Button
          form="invitation-form"
          className={classes.submitBtn}
          variant="raised"
          color="primary"
          type="submit"
          disabled={!!errors.items}
          loading={isLoading}
        >
          Invite
        </Button>
      </DialogHeader>
      <DialogContent>
        <InviteUsersDialogContent isInvitationsPage={isInvitationsPage} />
      </DialogContent>
    </Dialog>
  );
};

InviteUsersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isInvitationsPage: PropTypes.bool.isRequired,
};

export default withTheme(styles)(InviteUsersDialog);
