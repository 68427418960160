import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withTheme } from '@mip/theming';
import cx from 'classnames';

import IconButton from '../IconButton';

import styles from './NavigationItem.styles';

const NavigationItem = ({ classes, name, icon, isExpanded, active, linkTo, dataQa, onClick }) => (
  <div className={classes.root}>
    {isExpanded ? (
      <Link
        to={linkTo || undefined}
        className={cx(classes.link, active && classes.activeLink)}
        data-qa={dataQa}
        onClick={onClick}
      >
        <div className={classes.icon}>{icon}</div>
        <div className={classes.name}>{name}</div>
      </Link>
  ) : (
    <IconButton
      active={active}
      component={linkTo ? Link : undefined}
      to={linkTo || undefined}
      data-qa={dataQa}
      onClick={onClick}
    >
      {icon}
    </IconButton>
  )}
  </div>
);

NavigationItem.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  active: PropTypes.bool,
  linkTo: PropTypes.string,
  dataQa: PropTypes.string,
  onClick: PropTypes.func,
};

NavigationItem.defaultProps = {
  active: undefined,
  linkTo: undefined,
  dataQa: undefined,
  onClick: undefined,
};

export default withTheme(styles)(NavigationItem);
