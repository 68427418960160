import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button } from '@mip/magic';
import { Button as MaterialButton } from '@mui/material';
import DialogHeader from 'components/UIKit/DialogHeader';
import { artifactCreationSelectors as selectors } from 'redux/selectors';
import { artifactCreationActions as actions } from 'redux/actions';

import { Warning as WarningIcon } from '@mip/magic/icons';

import styles from './CancellAllConfirmDialog.styles';

const CancellAllConfirmDialog = ({ classes }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectors.isCancelAllConfirmDialogOpenSelector);

  const handleSubmit = () => {
    window.location.reload();
  };

  const handleClose = () => {
    dispatch(actions.setCancelAllConfirmDialogOpen(false));
  };

  return (
    <Dialog classes={classes} open={isOpen} onClose={handleClose}>
      <DialogHeader
        title={(<span className={classes.title}><WarningIcon className={classes.titleIcon} /> Cancel uploads</span>)}
      >
        <Button className={classes.cancel} onClick={handleClose}>Close</Button>
        <MaterialButton
          color="warning"
          variant="contained"
          onClick={handleSubmit}
        >
          Cancel All
        </MaterialButton>
      </DialogHeader>
      <DialogContent>
        Are you sure you want to cancel uploading the files?
      </DialogContent>
    </Dialog>
  );
};

CancellAllConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(CancellAllConfirmDialog);
