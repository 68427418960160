import React from 'react';
import PropTypes from 'prop-types';

import { MenuItem, Switch } from '@mip/magic';

const MenuItemWithSwitch = ({ selected, children, dataQa, ...props }) => (
  <MenuItem {...props} data-qa={dataQa} style={{ paddingRight: 3 }}>
    {children}
    <div style={{ margin: '-5px 0 -5px auto' }}>
      <Switch
        checked={selected}
        size="small"
        inputProps={{
          'data-qa': dataQa ? `${dataQa}-switch-${selected}` : undefined,
        }}
      />
    </div>
  </MenuItem>
);

MenuItemWithSwitch.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.any.isRequired,
  dataQa: PropTypes.string.isRequired,
};

MenuItemWithSwitch.defaultProps = {
  selected: false,
};

export default MenuItemWithSwitch;
