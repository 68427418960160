import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MuiTableContainer from '@mui/material/TableContainer';

const TableContainer = ({ className, ...otherProps }) => {
  const tableContainerRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      setScrolled(e.currentTarget.scrollTop > 0);
    };
    const scrollElement = tableContainerRef.current;

    scrollElement.addEventListener('scroll', handleScroll);

    return () => { scrollElement.removeEventListener('scroll', handleScroll); };
  }, []);

  return (
    <MuiTableContainer
      className={cx(className, scrolled && 'MuiTableContainer-scrolled')}
      ref={tableContainerRef}
      {...otherProps}
    />
  );
};

TableContainer.propTypes = {
  className: PropTypes.string,
};

TableContainer.defaultProps = {
  className: undefined,
};

export default TableContainer;
