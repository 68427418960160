import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Snackbar } from '@mip/magic';
import { withTheme } from '@mip/theming';
import {
  Error as ErrorIcon, Warning as WarningIcon, CheckCircle as SuccessIcon, Info as InfoIcon,
} from '@mip/magic/icons';

import styles from './Notification.styles';
import { MOUSE_EVENT_MOUSE_UP, TYPES, VARIANTS } from '../definitions';

const NotificationComp = ({ onClose, timeout, data: {
  id, title, type, Icon,
} }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleClose = (event) => {
    if (!event || event.type !== MOUSE_EVENT_MOUSE_UP) {
      setOpen(false);
      setTimeout(() => onClose(id, event), 100);
    }
  };

  /* eslint-disable no-param-reassign */
  if (!Icon) {
    switch (type) {
      case TYPES.ERROR: {
        Icon = ErrorIcon;
        break;
      }
      case TYPES.WARNING: {
        Icon = WarningIcon;
        break;
      }
      case TYPES.SUCCESS: {
        Icon = SuccessIcon;
        break;
      }
      case TYPES.INFO: {
        Icon = InfoIcon;
        break;
      }
      default:
    }
  }
  /* eslint-enable no-param-reassign */

  return (
    <Snackbar
      open={open}
      autoHideDuration={timeout}
      onClose={handleClose}
      severity={type}
      message={title}
    />
  );
};

NotificationComp.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    content: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    Icon: PropTypes.element,
    variant: PropTypes.oneOf(Object.values(VARIANTS)),
    type: PropTypes.oneOf(Object.values(TYPES)),
    action: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  timeout: PropTypes.number.isRequired,
};

const Notification = withTheme(styles)(NotificationComp);

export { Notification };
