export default ({
  variants: [
    {
      props: { size: 'standard' },
      style: {
        padding: '10px 14px',
      },
    },
  ],
  styleOverrides: {
    root: {
      fontFamily: '"Matter", Helvetica, sans-serif',
      lineHeight: '18px',
      fontSize: 16,
      borderRadius: 5,

      '&.MuiButton-contained:hover': {
        boxShadow: 'none',
      },
    },
    startIcon: {
      marginRight: 10,
      marginLeft: 0,
    },
  },
});
