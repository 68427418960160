import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';

import FileUploader from 'components/FileUploader';

import styles from './ArtifactsEmptyContent.styles';

const ArtifactsEmptyContent = ({ classes }) => (
  <div className={classes.root}>
    <FileUploader
      classes={{
        root: classes.fileUploader,
        iconBlock: classes.fileUploaderIconBlock,
        icon: classes.fileUploaderIcon,
        title: classes.fileUploaderTitle,
      }}
      isEmpty
    />
  </div>
);

ArtifactsEmptyContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsEmptyContent);
