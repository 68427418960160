import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';
import { userDeletionActions as actions } from 'redux/actions';
import { userDeletionSelectors as selectors } from 'redux/selectors';

import { Error as ErrorIcon } from '@mip/magic/icons';

import styles from './UserDeleteDialog.styles';

const UserDeleteDialog = ({ classes, id, onClose }) => {
  const dispatch = useDispatch();

  const isDeleting = useSelector(selectors.isDeletingSelector);

  const handleDelete = () => {
    dispatch(actions.deleteUser({ id }));
  };

  return (
    <Dialog classes={classes} open onClose={onClose}>
      <DialogHeader title={(<span className={classes.title}><ErrorIcon color="error" /> Delete user</span>)}>
        <Button className={classes.cancelBtn} onClick={onClose}>Cancel</Button>
        <Button
          className={classes.submitBtn}
          color="error"
          variant="raised"
          loading={isDeleting}
          onClick={handleDelete}
        >
          Delete
        </Button>
      </DialogHeader>
      <DialogContent>
        You will delete the user permanently. This action can’t be undone.
      </DialogContent>
    </Dialog>
  );
};

UserDeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTheme(styles)(UserDeleteDialog);
