import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch } from 'react-redux';
import { useForm, useFieldArray } from 'react-hook-form';

import { Button, IconButton } from '@mip/magic';
import SimpleBar from '@mip/magic/components/SimpleBar';
import { Add, Bin } from 'icons';

import { userInvitationActions as actions, invitationListActions, notify } from 'redux/actions';

import { USER_ROLES } from 'constants/userRolesConstants';
import { portalUiService } from 'api';

import FormBlock from './FormBlock';

import styles from '../InviteUsersDialog.styles';

const InviteUsersDialogContent = ({ classes, isInvitationsPage }) => {
  const dispatch = useDispatch();

  const { control, handleSubmit } = useForm({
    defaultValues: {
      items: [
        { email: '', role: USER_ROLES.CONTENT_CONSUMER },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'items',
  });

  const validate = () => {
    dispatch(notify({
      title: 'Validation failed',
      type: 'error',
    }));
  };

  const onSubmit = async (data) => {
    dispatch(actions.setIsLoading(true));
    try {
      await portalUiService.inviteUsers({ invitations: data.items });
      dispatch(notify({
        title: 'Users were successfully invited',
        type: 'success',
      }));
      dispatch(actions.setInviteUsersDialogOpen(false));
      if (isInvitationsPage) {
        dispatch(invitationListActions.getInvitationListRequest({ page: 1 }));
      }
    } catch (e) {
      dispatch(notify({
        title: 'Something went wrong',
        type: 'error',
      }));
    }
    dispatch(actions.setIsLoading(false));
  };

  return (
    <div>
      <SimpleBar>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <form id="invitation-form" onSubmit={handleSubmit(onSubmit, validate)}>
          {fields.map((item, index) => (
            <div key={item.id} className={classes.block}>
              <div className={classes.fields}>
                <FormBlock
                  index={index}
                  control={control}
                  item={item}
                />
              </div>
              <IconButton
                className={classes.removeBtn}
                size="small"
                disabled={fields.length < 2}
                onClick={() => remove(index)}
              >
                <Bin />
              </IconButton>
            </div>
            ))}
          <div className={classes.addUser}>
            <Button
              className={classes.addBtn}
              startIcon={<Add />}
              onClick={() => append({ email: '', role: USER_ROLES.CONTENT_CONSUMER })}
            >
              Add another user
            </Button>
            <div className={classes.line} />
          </div>
        </form>
      </SimpleBar>
    </div>
  );
};

InviteUsersDialogContent.propTypes = {
  classes: PropTypes.object.isRequired,
  isInvitationsPage: PropTypes.bool.isRequired,
};

export default withTheme(styles)(InviteUsersDialogContent);
