export default () => ({
  root: {
    width: 24,
    height: 24,
  },
  dashed: {
    fill: 'transparent',
    stroke: '#767676',
    strokeWidth: 4,
    strokeDasharray: '17, 17.5',
  },
});
