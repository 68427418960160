/* https://stackoverflow.com/questions/19834763/chart-js-set-doughnut-background-color/67764687#67764687 */

function handler(chart, args, options) {
  const { ctx, width, height } = chart;

  const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1).controller;
  const { outerRadius } = chart.getDatasetMeta(0).controller;
  const radiusLength = outerRadius - innerRadius;

  if (options.enabled) {
    const x = width / 2;
    const y = height / 2;

    ctx.beginPath();
    ctx.arc(x, y, outerRadius - (radiusLength / 2), 0, 2 * Math.PI);
    ctx.lineWidth = radiusLength;
    ctx.strokeStyle = options.color;
    ctx.stroke();
  }
}

export default {
  id: 'doughnutChartBackground',
  beforeDatasetsDraw: handler,
};
