import { all, put, takeLatest, select } from 'redux-saga/effects';
import { notify, setIsGlobalLoading } from 'redux/actions';
import { request } from 'api/portal';
import { actions } from './invitationListReducer';
import * as selectors from './invitationListSelectors';

export function* getInvitationListFlow({ payload = {} }) {
  let { page } = payload;
  if (!page) {
    page = yield select(selectors.pageSelector);
  }
  const size = yield select(selectors.limitSelector);
  const searchQuery = yield select(selectors.searchQuerySelector);

  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield request({
      method: 'post',
      url: '/invitations/_search',
      data: {
        page: page - 1,
        size,
        title: searchQuery,
      },
    });

    yield put(actions.getInvitationListSuccess({ data }));
  } catch (e) {
    yield put(actions.getInvitationListFailure());
    yield put(notify({ title: 'Something went wrong', type: 'error' }));
  }
  yield put(setIsGlobalLoading(false));
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getInvitationListRequest.type, getInvitationListFlow),
  ]);
}
