export default () => ({
  paper: {
    width: 372,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    padding: [29, 0],
    boxSizing: 'border-box',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 30,
    padding: [0, 27, 0, 29],
  },
  closeButton: {
    position: 'relative',
    top: 2,
    right: -4,
    width: 20,
    height: 20,

    '&:hover': {
      backgroundColor: 'transparent !important',
    },
    '& svg': {
      width: 13,
      height: 13,
    },
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    color: '#494949',
  },
});
