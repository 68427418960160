import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useDidMount, useWillUnmount } from '@mip/utils';

import { artifactListActions as actions, artifactStatisticsActions } from 'redux/actions';
import { artifactListSelectors as selectors,
  artifactCreationSelectors,
  artifactStatisticsSelectors,
} from 'redux/selectors';

import UploadProgressDialog from 'components/dialogs/UploadProgressDialog';
import CancellAllConfirmDialog from 'components/dialogs/CancellAllConfirmDialog';
import ArtifactsBlock from './ArtifactsBlock';
import UploadingFiles from './UploadingFiles';
import useUrlParamsUpdate from './hooks/useUrlParamsUpdate';

const FileUploaderPageContent = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const artifacts = useSelector(selectors.artifactsSelector);
  const hasUploadingFiles = useSelector(artifactCreationSelectors.hasUploadingFiles);
  const isStatisticLoaded = useSelector(artifactStatisticsSelectors.isLoadedSelector);

  useUrlParamsUpdate();

  useDidMount(() => {
    dispatch(actions.initStoreFromUrlParams({ searchParams }));
    dispatch(actions.getArtifactListRequest({ }));
    dispatch(artifactStatisticsActions.getStatistics());
  });

  useWillUnmount(() => {
    dispatch(actions.reset());
  });

  if (!artifacts || !isStatisticLoaded) {
    return null;
  }

  return (
    <Fragment>
      {hasUploadingFiles && <UploadingFiles />}
      <ArtifactsBlock />
      <UploadProgressDialog />
      <CancellAllConfirmDialog />
    </Fragment>
  );
};

export default FileUploaderPageContent;
