import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';

import { Document } from 'icons';

import styles from './ArtifactNameCell.styles';

const ArtifactNameCell = ({ classes, title }) => (
  <TableCell className={classes.root}>
    <Document />
    <span className={classes.title}>{title}</span>
  </TableCell>
);

ArtifactNameCell.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default withTheme(styles)(ArtifactNameCell);
