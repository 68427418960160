import { useState } from 'react';
import { transformArrayToObj } from 'utils';

import useColumnsVisibilityLocalStorage from './hooks/useColumnsVisibilityLocalStorage';
import { getAlwaysAndDefaultVisibleColumns, getVisibleColumns } from './utils';

const useColumnsVisibilty = (tableId, columns) => {
  const { alwaysVisibleColumns, defaultVisibleColumns } = getAlwaysAndDefaultVisibleColumns(columns);

  const defaultVisibleColumnsObj = transformArrayToObj(defaultVisibleColumns);

  const {
    setLocalStorageItem,
    getLocalStorageItem,
  } = useColumnsVisibilityLocalStorage(tableId, defaultVisibleColumnsObj);

  const [columnsVisibilityState, setColumnsVisibility] = useState(getLocalStorageItem());

  const alwaysVisibleColumnsObj = transformArrayToObj(alwaysVisibleColumns);

  const visibleColumns = getVisibleColumns(columns, columnsVisibilityState, alwaysVisibleColumnsObj);

  const handleColumnsVisibilityChange = (value) => {
    setColumnsVisibility(value);
    setLocalStorageItem(value);
  };

  return { visibleColumns, setColumnsVisibility: handleColumnsVisibilityChange };
};

export default useColumnsVisibilty;
