import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useColumnsVisibilty from './hooks/columnsVisibilty';

export const TableSettingsContext = createContext();

const TableSettingsProvider = ({ children, tableId, columns }) => {
  const { visibleColumns, setColumnsVisibility } = useColumnsVisibilty(tableId, columns);

  return (
    <TableSettingsContext.Provider
      value={{
        columns,
        visibleColumns,
        setColumnsVisibility,
      }}
    >
      {children}
    </TableSettingsContext.Provider>
  );
};


TableSettingsProvider.propTypes = {
  children: PropTypes.any.isRequired,
  tableId: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
};

export default TableSettingsProvider;
