export default () => ({
  root: {
    marginTop: 15,
  },
  table: {
    marginBottom: 25,
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 30,
    marginRight: 19,
  },
});
