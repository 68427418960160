import React, { useState, useEffect } from 'react';
import { throttle } from 'throttle-debounce';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import useForceRerender from 'hooks/useForceRerender';

import { mainActions } from 'redux/actions';

import Hint from '../Hint';

import getHintElements from '../utils/getHintElements';

const HintsController = () => {
  const dispatch = useDispatch();
  const forceRerender = useForceRerender();

  const [currentVisibleIndex, setCurrentVisibleIndex] = useState(0);

  const hints = getHintElements();

  const isFirstHint = currentVisibleIndex === 0;
  const isLastHint = currentVisibleIndex === hints.length - 1;

  useEffect(() => {
    const throttleFunc = throttle(100, forceRerender);
    window.addEventListener('resize', throttleFunc);
    return () => {
      window.removeEventListener('resize', throttleFunc);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigateBack = (e) => {
    e.stopPropagation();
    setCurrentVisibleIndex(isFirstHint ? hints.length - 1 : currentVisibleIndex - 1);
  };

  const handleNavigateForward = (e) => {
    e.stopPropagation();
    setCurrentVisibleIndex(isLastHint ? 0 : currentVisibleIndex + 1);
  };

  const handleDotClick = ({ index }) => {
    setCurrentVisibleIndex(index);
  };

  const handleBackdropClick = () => {
    dispatch(mainActions.setHintsFlowEnabled(false));
  };

  if (hints.length === 0) {
    return null;
  }

  return (
    <Backdrop
      open
      sx={{ zIndex: 1500, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}
      onClick={handleBackdropClick}
    >
      {hints.map((hint, index) => (
        <Hint
          key={hint.dataHint}
          style={hint.coordinates}
          index={index}
          content={hint}
          isOpen={currentVisibleIndex === index}
          onDotClick={handleDotClick}
          onNavigateBack={handleNavigateBack}
          onNavigateForward={handleNavigateForward}
        />
      ))}
    </Backdrop>
  );
};

export default HintsController;
