import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { withTheme } from '@mip/theming';
import { Button } from '@mip/magic';

import { Upload } from 'icons';
import { artifactCreationActions as actions } from 'redux/actions';

import { supportedFileTypeList } from 'constants/artifactListConstants';

import styles from './FileUploader.styles';

const FileUploader = ({ classes, isEmpty }) => {
  const dispatch = useDispatch();

  const handleFilesInput = (event) => {
    dispatch(actions.createArtifactsRequest({ files: event.target.files }));
    // eslint-disable-next-line no-param-reassign
    event.target.value = '';
  };

  return (
    <div className={classes.root} data-qa="fileUploader">
      {isEmpty && <div data-hint="fileUploader" style={{ position: 'absolute', top: -18, left: '48%' }} />}
      <div className={classes.iconBlock}><Upload className={classes.icon} /></div>
      <div className={classes.title}>
        Drag and Drop files anywhere on the page or click Add files
      </div>
      <div className={classes.description}>
        PDF, DOC(X), PPT(X)
      </div>
      <Button className={classes.button} component="label" variant="raised" color="primary">
        Add files
        <input
          type="file"
          hidden
          accept={supportedFileTypeList.join(',')}
          multiple
          onChange={handleFilesInput}
        />
        {!isEmpty && <div data-hint="addFiles" style={{ position: 'absolute', bottom: 16, left: '34%' }} />}
      </Button>
    </div>
  );
};

FileUploader.propTypes = {
  classes: PropTypes.object.isRequired,
  isEmpty: PropTypes.bool,
};

FileUploader.defaultProps = {
  isEmpty: false,
};

export default withTheme(styles)(FileUploader);
