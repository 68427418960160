export default () => ({
  root: {
    width: 160,
    height: 160,
    borderRadius: '50%',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  },
  placeholder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#EAEBFF',
    width: '100%',
    height: '100%',
  },
});
