import { COLUMNS } from 'constants/artifactListConstants';

export const defaultSorting = {
  field_name: COLUMNS.UPLOAD_DATE,
  sorting_direction: 'DESC',
};

const fieldNameToParam = {
  UPLOAD_DATE: 'created',
  NAME: 'title',
  PUBLICATION_DATE: 'published',
};

const paramToFieldName = {
  created: COLUMNS.UPLOAD_DATE,
  title: COLUMNS.NAME,
  published: COLUMNS.PUBLICATION_DATE,
};

export const getUrlParamFromSorting = (fieldName, direction) =>
  `${fieldNameToParam[fieldName]}_${direction.toLowerCase()}`;

export const getSortingFromUrlParam = (sortParam) => {
  const regex = /^(created|title|published)_(asc|desc)$/;

  const match = sortParam && sortParam.match(regex);

  if (!match) {
    return defaultSorting;
  }

  return ({
    field_name: paramToFieldName[match[1]],
    sorting_direction: match[2].toUpperCase(),
  });
};
