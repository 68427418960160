import ErrorScreen from 'components/ErrorScreen';
import React from 'react';

const description = 'Sorry, but you don’t have permission\nto access this page';

const AccessDeniedPage = () => (
  <ErrorScreen
    title="Access denied"
    description={description}
    errorCode={403}
  />
);

export default AccessDeniedPage;
