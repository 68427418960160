/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  roleChangeUserId: null,
  isInProgress: false,
};

export const slice = createSlice({
  name: 'userRoleChange',
  initialState,
  reducers: {
    setRoleChangeUserId: (state, { payload: { id } }) => {
      state.roleChangeUserId = id;
    },
    changeUserRole: (state) => {
      state.isInProgress = true;
    },
    changeUserRoleSuccess: (state) => {
      state.isInProgress = false;
      state.roleChangeUserId = null;
    },
    changeUserRoleFailure: (state) => {
      state.isInProgress = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
