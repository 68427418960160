import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';

import { Chip } from 'components/UIKit';

import { userRolesProps } from 'constants/userRolesConstants';

import styles from './RoleCell.styles';

const RoleCell = ({ classes, role }) => (
  <TableCell className={classes.root}>
    <Chip
      title={userRolesProps[role].title}
      color={userRolesProps[role].color}
    />
  </TableCell>
);

RoleCell.propTypes = {
  classes: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
};

export default withTheme(styles)(RoleCell);
