import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';
import { IconButton, Menu, MenuItem } from '@mip/magic';
import { ThreeDotMenu } from 'icons';
import ArtifactDeleteDialog from 'components/dialogs/ArtifactDeleteDialog';

import { useDispatch, useSelector } from 'react-redux';
import { artifactDeletionActions as actions, notify } from 'redux/actions';
import { artifactDeletionSelectors as selectors } from 'redux/selectors';

import { artifactService } from 'api';

import downloadByLink from 'utils/downloadByLink';

import styles from './MoreActionsCell.styles';

const MoreActionsCell = ({ classes, id, fileName, passedValidation }) => {
  const dispatch = useDispatch();
  const { orgName } = useParams();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const deletionArtifactId = useSelector(selectors.deletionArtifactIdSelector);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewFile = async () => {
    window.open(`/${orgName}/artifact-viewer/${id}`, '_blank');
    handleCloseMenu();
  };

  const handleDownloadFile = async () => {
    try {
      const { data } = await artifactService.getArtifactGcsObjectLink({ artifactId: id });
      window.mls_qa_export_url = data.signed_link;
      downloadByLink(data.signed_link, fileName);
    } catch (e) {
      dispatch(notify({ title: 'Something went wrong', type: 'error' }));
    }
    handleCloseMenu();
  };

  const handleOpenDeleteDialog = () => {
    handleCloseMenu();
    dispatch(actions.setDeletionArtifactId({ id }));
  };

  const handleCloseDeleteDialog = () => {
    dispatch(actions.setDeletionArtifactId({ id: null }));
  };

  return (
    <TableCell align="right" className={classes.root}>
      <IconButton onClick={handleOpenMenu}>
        <ThreeDotMenu />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleCloseMenu}
      >
        <MenuItem disabled={!passedValidation} onClick={handleViewFile}>View File</MenuItem>
        <MenuItem onClick={handleDownloadFile}>Download</MenuItem>
        <MenuItem
          sx={{ color: 'error.main' }}
          onClick={handleOpenDeleteDialog}
        >
          Delete
        </MenuItem>
      </Menu>
      {deletionArtifactId === id && (
        <ArtifactDeleteDialog
          artifactId={id}
          artifactTitle={fileName}
          onClose={handleCloseDeleteDialog}
        />
      )}
    </TableCell>
  );
};

MoreActionsCell.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  passedValidation: PropTypes.bool.isRequired,
};

export default withTheme(styles)(MoreActionsCell);
