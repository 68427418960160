export default ({
  styleOverrides: {
    root: {
      fontFamily: '"Matter", Helvetica, sans-serif',
      marginBottom: 4,
      color: 'rgba(27, 27, 27, 0.8)',
      fontSize: 13,
      fontWeight: 600,
      lineHeight: '14px',

      '&.Mui-focused': {
        color: 'rgba(27, 27, 27, 0.8)',
      },
    },
  },
});
