import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';

import Avatar from './Avatar';

import styles from './MemberNameCell.styles';

const MemberNameCell = ({ classes, member }) => (
  <TableCell className={classes.root}>
    <div className={classes.wrapper}>
      <Avatar member={member} />
      <div className={classes.name}>{member.name}</div>
    </div>
  </TableCell>
);

MemberNameCell.propTypes = {
  classes: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
};

export default withTheme(styles)(MemberNameCell);
