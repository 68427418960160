import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '@mip/utils';
import { useSearchParams } from 'react-router-dom';

import { withTheme } from '@mip/theming';
import { Paper } from '@mip/magic';

import { memberListActions as actions } from 'redux/actions';
import { memberListSelectors as selectors } from 'redux/selectors';

import TableSettingsProvider from 'components/TableSettingsProvider';

import { COLUMNS_LIST } from 'constants/memberListConstants';

import MemberTable from './MemberTable';
import MemberTableSettings from './MemberTableSettings';
import UserManagementActions from '../UserManagementActions';
import SearchBox from '../SearchBox';
import InvitationButton from '../InvitationButton';

import useUrlParamsUpdate from './hooks/useUrlParamsUpdate';

import styles from './MemberTabContent.styles';

const MemberTabContent = ({ classes }) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const getMemberList = () => {
    dispatch(actions.getMemberListRequest({}));
  };

  useDidMount(() => {
    dispatch(actions.initStoreFromUrlParams({ searchParams }));
    getMemberList();
  });

  const totalItems = useSelector(selectors.totalItemsSelector);

  const searchQuery = useSelector(selectors.searchQuerySelector);

  useUrlParamsUpdate();

  const searchMembers = ({ value }) => {
    dispatch(actions.setSearchQuery({ query: value }));
    dispatch(actions.getMemberListRequest({ page: 1 }));
  };

  const clearSearch = () => {
    dispatch(actions.setSearchQuery({ query: '' }));
    dispatch(actions.getMemberListRequest({ page: 1 }));
  };

  return (
    <TableSettingsProvider
      tableId="memberList"
      columns={COLUMNS_LIST}
    >
      <UserManagementActions>
        <SearchBox
          isDisabled={totalItems === 0}
          searchQuery={searchQuery}
          onSearch={searchMembers}
          onClearSearch={clearSearch}
        />
        <InvitationButton />
      </UserManagementActions>
      <Paper className={classes.paper}>
        <MemberTableSettings />
        <MemberTable />
      </Paper>
    </TableSettingsProvider>
  );
};

MemberTabContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(MemberTabContent);
