import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';

import BulkDeleteDialog from 'components/dialogs/BulkDeleteDialog';

import Pagination from 'components/UIKit/Pagination';

import { artifactListSelectors as selectors } from 'redux/selectors';
import { artifactListActions as actions } from 'redux/actions';

import ArtifactsTableSettingsLeftBlock from './ArtifactsTableSettingsLeftBlock';
import ArtifactListLimitSelector from './ArtifactListLimitSelector';

import styles from './ArtifactsTableSettings.styles';

const ArtifactsTableSettings = ({ classes }) => {
  const dispatch = useDispatch();

  const total = useSelector(selectors.totalItemsSelector);

  const totalPages = useSelector(selectors.totalPagesSelector);
  const page = useSelector(selectors.pageSelector);

  const handlePageChange = (newPage) => {
    dispatch(actions.getArtifactListRequest({ page: newPage }));
  };

  const perPage = useSelector(selectors.limitSelector);

  const renderPerPageInfo = () => {
    if (total > 0) {
      return <Fragment>{(perPage * (page - 1)) + 1}-{Math.min(perPage * page, total)} of {total}</Fragment>;
    }

    return null;
  };

  return (
    <div className={classes.root}>
      <ArtifactsTableSettingsLeftBlock />
      <div className={classes.perPageInfo}>
        {renderPerPageInfo()}
      </div>
      <BulkDeleteDialog />
      {totalPages > 1 &&
        <Pagination count={totalPages} page={page} onChange={handlePageChange} />
      }
      <ArtifactListLimitSelector />
    </div>
  );
};

ArtifactsTableSettings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsTableSettings);
