import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';
import { artifactDeletionActions as actions } from 'redux/actions';
import { artifactDeletionSelectors as selectors } from 'redux/selectors';

import { Error as ErrorIcon } from '@mip/magic/icons';

import styles from './BulkDeleteDialog.styles';

const BulkDeleteDialog = ({ classes }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectors.isBulkDeleteDialogOpenSelector);
  const isBulkDeleting = useSelector(selectors.isBulkDeletingSelector);

  const handleSubmit = () => {
    dispatch(actions.bulkDeleteArtifacts());
  };

  const handleClose = () => {
    dispatch(actions.closeBulkDeleteDialog());
  };

  return (
    <Dialog classes={classes} open={isOpen} onClose={handleClose}>
      <DialogHeader title={(<span className={classes.title}><ErrorIcon color="error" /> Delete files</span>)}>
        <Button className={classes.cancel} onClick={handleClose}>Cancel</Button>
        <Button
          color="error"
          variant="raised"
          loading={isBulkDeleting}
          onClick={handleSubmit}
        >
          Delete
        </Button>
      </DialogHeader>
      <DialogContent>
        <div className={classes.confirmText}>
          Are you sure you want to delete the selected files? This action can’t be undone.
        </div>
      </DialogContent>
    </Dialog>
  );
};

BulkDeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(BulkDeleteDialog);
