import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { memberListSelectors as selectors } from 'redux/selectors';

import { COLUMNS } from 'constants/memberListConstants';
import { formatLastLoginDate, formatCreatedAtDate } from 'utils/dateHelpers';

import { TableContainer, RoleCell } from 'components/UIKit';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import MemberNameCell from './MemberNameCell';
import MoreActionsCell from './MoreActionsCell';

import styles from './MemberTable.styles';

const MemberTable = ({ classes }) => {
  const members = useSelector(selectors.membersSelector);

  const { visibleColumns } = useTableSettings();

  const renderHeaderCell = (column) => {
    switch (column.type) {
      case COLUMNS.NAME:
        return <TableCell column={column} sx={{ minWidth: '320px' }}>{column.title}</TableCell>;
      case COLUMNS.EMAIL:
        return <TableCell sx={{ width: '320px' }}>{column.title}</TableCell>;
      case COLUMNS.LAST_ACTIVITY:
        return <TableCell sx={{ width: '165px' }} align="right">{column.title}</TableCell>;
      case COLUMNS.USER_SINCE:
        return <TableCell column={column} align="right" sx={{ width: '165px' }}>{column.title}</TableCell>;
      case COLUMNS.ROLE:
        return <TableCell column={column} sx={{ width: '165px' }}>{column.title}</TableCell>;
      default:
        return null;
    }
  };

  const renderBodyCell = (column, member) => {
    switch (column) {
      case COLUMNS.NAME:
        return <MemberNameCell member={member} />;
      case COLUMNS.EMAIL:
        return <TableCell>{member.email}</TableCell>;
      case COLUMNS.LAST_ACTIVITY:
        return <TableCell align="right">{formatLastLoginDate(member.last_login)}</TableCell>;
      case COLUMNS.USER_SINCE:
        return <TableCell align="right">{formatCreatedAtDate(member.created_at)}</TableCell>;
      case COLUMNS.ROLE:
        return <RoleCell role={member.role} />;
      default:
        return null;
    }
  };

  if (!members) {
    return null;
  }

  return (
    <Fragment>
      <TableContainer className={classes.root}>
        <Table className={classes.table} stickyHeader data-qa="memberTable">
          <TableHead>
            <TableRow>
              {visibleColumns.map(column => <Fragment key={column.type}>{renderHeaderCell(column)}</Fragment>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map(member => (
              <TableRow key={member.user_id}>
                {visibleColumns.map(column =>
                  <Fragment key={column.type}>{renderBodyCell(column.type, member)}</Fragment>)}
                <MoreActionsCell user={member} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

MemberTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(MemberTable);
