export default () => ({
  root: {
    display: 'flex',
  },
  field: {
    width: '50%',

    '&:first-child': {
      marginRight: 15,
    },
  },
  textField: {
    '& .MuiFormLabel-root': {
      marginBottom: 6,
      fontSize: 14,
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: '-20px',
      left: 0,
    },
  },
  inputLabel: {
    marginBottom: 6,
    fontSize: 14,
  },
});
