import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from '@mip/magic';

import { USER_ROLES } from 'constants/userRolesConstants';

import styles from '../ModifyRoleDialog.styles';

const ModifyRoleDialogContent = ({ classes, email, role, onSetRole }) => (
  <div>
    <div>You are about to modify the user role for the following user.</div>
    <div className={classes.emailBlock}>
      <div className={classes.user}>User</div>
      <div>{email}</div>
    </div>
    <InputLabel className={classes.inputLabel}>Select role</InputLabel>
    <FormControl sx={{ minWidth: '100%' }} className={classes.formField}>
      <Select
        value={role}
        label="Select role"
        onChange={e => onSetRole(e.target.value)}
      >
        <MenuItem value={USER_ROLES.CONTENT_CONSUMER}>Content Consumer</MenuItem>
        <MenuItem value={USER_ROLES.CONTENT_MANAGER}>Content Manager</MenuItem>
        <MenuItem value={USER_ROLES.PLATFORM_OWNER}>Platform Owner</MenuItem>
      </Select>
    </FormControl>
  </div>
);

ModifyRoleDialogContent.propTypes = {
  classes: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  onSetRole: PropTypes.func.isRequired,
};

export default withTheme(styles)(ModifyRoleDialogContent);
