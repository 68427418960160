import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from 'redux/actions';
import { IconButton } from '@mip/magic';
import { Close as CloseIcon } from '@mip/magic/icons';
import Backdrop from '@mui/material/Backdrop';
import Drawer from '@mui/material/Drawer';
import { mainSelectors } from 'redux/selectors';

import UserProfileContent from './UserProfileContent';

import styles from './UserProfile.styles';

const UserProfile = ({ classes }) => {
  const dispatch = useDispatch();

  const isUserProfileOpen = useSelector(mainSelectors.isUserProfileOpenSelector);

  const handleClose = () => {
    dispatch(mainActions.setUserProfileOpen(false));
  };

  return (
    <Fragment>
      <Backdrop style={{ position: 'absolute', zIndex: 1100 }} open={isUserProfileOpen} />
      <Drawer
        classes={classes}
        anchor="right"
        open={isUserProfileOpen}
        BackdropProps={{ invisible: true }}
        onClose={handleClose}
      >
        <div className={classes.heading}>
          <div className={classes.title}>Your Profile</div>
          <IconButton className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <UserProfileContent />
      </Drawer>
    </Fragment>
  );
};

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(UserProfile);
