/* eslint-disable max-len */
import React from 'react';

const ArrowIcon = () => (
  <svg viewBox="0 0 13 7" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
    <path d="M0.560303 0L0.5 0.068232L3.94792 4.62625C4.8146 5.77196 5.24793 6.34482 5.77813 6.55001C6.24259 6.72977 6.75741 6.72977 7.22187 6.55001C7.75207 6.34482 8.1854 5.77196 9.05208 4.62626L12.5 0.068232L12.4397 0H0.560303Z" />
  </svg>
);

export default ArrowIcon;
