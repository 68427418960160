import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { withTheme } from '@mip/theming';
import { Paper } from '@mip/magic';

import { artifactStatisticsSelectors } from 'redux/selectors';

import TableSettingsProvider from 'components/TableSettingsProvider';

import { COLUMNS_LIST } from 'constants/artifactListConstants';

import ArtifactsTableSettings from './ArtifactsTableSettings';
import ArtifactsTable from './ArtifactsTable';
import ArtifactsEmptyContent from './ArtifactsEmptyContent';
import SearchBox from './SearchBox';

import styles from './ArtifactsBlock.styles';

const ArtifactsBlock = ({ classes }) => {
  const total = useSelector(artifactStatisticsSelectors.totalSelector);

  const renderContent = () => {
    if (total > 0) {
      return (
        <TableSettingsProvider
          tableId="artifactList"
          columns={COLUMNS_LIST}
        >
          <ArtifactsTableSettings />
          <ArtifactsTable />
        </TableSettingsProvider>
      );
    }

    return <ArtifactsEmptyContent />;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.filesCount}>
          <h2 className={classes.title}>Your Files</h2>
          <span className={classes.total}>
            <span className={classes.totalCount}>{total}</span> {total === 1 ? 'file' : 'files'}
          </span>
        </div>
        <SearchBox isDisabled={total === 0} />
      </div>
      <Paper className={classes.paper}>
        {renderContent()}
      </Paper>
    </div>
  );
};

ArtifactsBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsBlock);
