export default () => ({
  paper: {
    width: 480,
    maxWidth: 480,
  },
  title: {
    fontSize: 20,
  },
  cancelBtn: {
    padding: '8px 20px !important',
  },
  submitBtn: {
    padding: '8px 20px !important',
    minWidth: 82,
  },
  emailBlock: {
    marginTop: 13,
    marginBottom: 20,
  },
  user: {
    marginBottom: 10,
    fontSize: 14,
    fontWeight: 600,
  },
  inputLabel: {
    marginBottom: 6,
    fontSize: 14,
  },
});
