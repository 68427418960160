export default () => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(106.56deg, #7F7FD5 22.67%, #86A8E7 68.59%, #91EAE4 100%)',
    paddingLeft: 52,
    color: '#fff',
    boxSizing: 'border-box',
  },
  logo: {
    position: 'absolute',
    top: 47,
    left: 52,
  },
  title: {
    fontSize: 64,
    marginTop: '39vh',
    marginBottom: 0,
  },
  description: {
    marginTop: 10,
    fontSize: 24,
    lineHeight: '26px',
  },
  errorCode: {
    position: 'absolute',
    left: 52,
    bottom: -67,
    fontWeight: 600,
    fontSize: 360,
    color: 'rgba(255, 255, 255, 0.1)',
  },
  button: {
    marginTop: 30,
  },
});
