/* eslint-disable max-len */
import React from 'react';

export default props => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="40" height="40" rx="20" fill="#F0F0F0" />
    <path d="M13.4498 18.9913C12.6108 18.7188 11.6001 18.8259 10.9574 19.4293C10.663 19.7067 10.457 20.0862 10.4471 20.4852C10.4226 21.5412 11.6345 22.3149 12.5912 22.3489C13.8227 22.3976 15.1229 21.9694 16.2759 21.4779C15.5645 20.4122 14.7009 19.4001 13.4449 18.9913H13.4498Z" fill="#91EAE4" />
    <path d="M20 29.9011C24.1802 29.9011 27.8207 27.5849 29.6802 24.1786C29.5232 24.2954 29.3613 24.4122 29.1994 24.5241C28.5714 24.9523 27.9139 25.3221 27.2221 25.6384C25.7649 26.3002 24.0674 26.7771 22.4532 26.7284C19.6418 26.6457 18.1503 24.4803 16.8109 22.3149C16.6392 22.0375 16.4625 21.7602 16.2761 21.4828C15.1182 21.9743 13.8229 22.4025 12.5914 22.3538C11.6298 22.3149 10.4228 21.5509 10.4474 20.4901C10.4572 20.0863 10.6632 19.7067 10.9576 19.4342C11.6004 18.8308 12.6062 18.7238 13.45 18.9963C14.7061 19.405 15.5647 20.4172 16.281 21.4828C16.4871 21.3952 16.6882 21.3076 16.8845 21.2152C18.1994 20.6167 19.4358 19.8722 20.6476 19.0984C20.736 19.0449 20.8194 18.9865 20.9077 18.933C18.9304 17.2055 17.6352 14.8017 16.9385 12.2616C16.7667 11.6436 16.6146 10.9381 16.0995 10.505C15.0348 9.60476 13.4108 10.3736 12.5375 11.1668C11.7475 11.8821 11.2422 12.3784 10.6485 13.2592C10.624 13.303 10.5946 13.3468 10.5651 13.3857C9.56913 15.0256 9 16.9428 9 18.9914C9 25.0156 13.926 29.9011 20 29.9011Z" fill="#86A8E7" />
    <path d="M27.0112 16.257C25.0536 16.4322 23.2726 17.4492 21.6388 18.4711C21.3935 18.6268 21.1433 18.7825 20.8979 18.9382C21.5309 19.4929 22.2276 19.9698 23.0077 20.3688C23.4149 20.5781 23.8417 20.7532 24.2735 20.9041C24.9506 21.1377 25.662 21.308 26.3783 21.3615C26.6923 21.3858 27.0112 21.3907 27.3203 21.3761C28.2918 21.3323 29.2633 21.0549 30.0286 20.4613C30.5978 20.0233 30.9903 19.3469 30.9903 18.6365V18.544C30.9805 18.3202 30.9314 18.0915 30.8284 17.8677C30.2151 16.4808 28.3556 16.1353 27.0112 16.257Z" fill="#91EAE4" />
    <path d="M30.99 18.6416C30.99 18.6659 30.99 18.6951 30.9851 18.7195C30.9556 19.3958 30.5778 20.0382 30.0283 20.4615C29.2679 21.0454 28.3111 21.3228 27.3495 21.3715C27.3397 21.3715 27.3348 21.3715 27.3249 21.3715C27.0109 21.3861 26.6969 21.3812 26.3829 21.3569C25.6666 21.3033 24.9552 21.133 24.2781 20.8995C23.8464 20.7535 23.4195 20.5734 23.0123 20.3642C22.2371 19.97 21.5355 19.4883 20.9026 18.9384C20.8142 18.992 20.7308 19.0504 20.6425 19.1039C19.4307 19.8776 18.1943 20.6221 16.8794 21.2206C16.6831 21.3082 16.4819 21.3958 16.2759 21.4882C16.4623 21.7656 16.6389 22.0478 16.8107 22.3203C18.155 24.4857 19.6416 26.6463 22.453 26.7339C24.0671 26.7825 25.7647 26.3057 27.2219 25.6439C27.9137 25.3324 28.5761 24.9578 29.1992 24.5295C29.3611 24.4176 29.523 24.3057 29.68 24.184C30.519 22.6415 30.9998 20.8751 30.9998 19.0017C30.9998 18.8849 30.9949 18.7633 30.99 18.6513V18.6416Z" fill="#7F7FD5" />
  </svg>
);
