export default () => ({
  paper: {
    width: 480,
    maxWidth: 480,
  },
  title: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
  titleIcon: {
    fill: '#F59842',
  },
});
