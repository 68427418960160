export default () => ({
  root: {
    position: 'relative',
    border: '2px dashed #E4E4E4',
    borderRadius: 10,
    padding: [29, 14, 28, 15],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  iconBlock: {
    width: 45,
    height: 45,
    background: '#EAEBFF',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: '#424FF5',
    height: 'auto',
  },
  title: {
    marginTop: 15,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 16,
    color: '#1B1B1B',
    lineHeight: 1.3,
  },
  description: {
    marginTop: 10,
    color: 'rgba(27, 27, 27, 0.6)',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
    minHeight: 34,
  },
  button: {
    position: 'relative',
    marginTop: 30,
    padding: [10, 20],
  },
});
