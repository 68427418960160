import React, { Fragment } from 'react';

import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';

const InvitationTableSettingsLeftBlock = () => (
  <Fragment>
    <ColumnVisibilitySelect />
  </Fragment>
);

export default InvitationTableSettingsLeftBlock;
