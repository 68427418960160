import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button, LinearProgress } from '@mip/magic';
import SimpleBar from '@mip/magic/components/SimpleBar';
import DialogHeader from 'components/UIKit/DialogHeader';
import { Document } from 'icons';

import { artifactCreationActions as actions } from 'redux/actions';
import { artifactCreationSelectors as selectors } from 'redux/selectors';

import styles from './UploadProgressDialog.styles';

const UploadProgressDialog = ({ classes }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(selectors.isUploadProgressDialogOpenSelector);
  const uploadingFiles = useSelector(selectors.uploadingFilesSelector);

  const handleOpenCancelConfirmDialog = () => {
    dispatch(actions.setCancelAllConfirmDialogOpen(true));
  };

  const handleClose = () => {
    dispatch(actions.setUploadProgressDialogOpen(false));
  };

  return (
    <Dialog classes={classes} open={isOpen} onClose={handleClose}>
      <DialogHeader title="Uploading files">
        <Button className={classes.cancel} onClick={handleClose}>Close</Button>
      </DialogHeader>
      <DialogContent>
        <SimpleBar>
          <div className={classes.heading}>
            <div>{uploadingFiles.length} <span className={classes.uploads}>uploads</span></div>
            <div className={classes.cancelAll} onClick={handleOpenCancelConfirmDialog}>Cancel All</div>
          </div>
          {uploadingFiles.map(file => (
            <div className={classes.row}>
              <div className={classes.titleBlock}>
                <Document />
                <div className={classes.title}>
                  {file.file.name}
                </div>
              </div>
              <div className={classes.status}>
                {file.isUploaded ?
                  <div className={classes.finished} /> :
                  <LinearProgress className={classes.inProgress} />
              }
              </div>
            </div>
          ))}
        </SimpleBar>
      </DialogContent>
    </Dialog>
  );
};

UploadProgressDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(UploadProgressDialog);
