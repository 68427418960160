export default ({
  variants: [
    {
      props: { size: 'small' },
      style: {
        paddingLeft: 10,
      },
    },
  ],
});
