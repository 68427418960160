import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { LinearProgress, Paper } from '@mip/magic';
import cx from 'classnames';

import { withTheme } from '@mip/theming';

import { mainSelectors, layoutSelectors } from 'redux/selectors';
import { layoutActions } from 'redux/actions';

import UserProfile from 'components/UserProfile';
import HelpMenu from 'components/HelpMenu';

import Navigation from './Navigation';

import styles from './PageWrapper.styles';

const PageWrapper = ({
  classes,
  children,
  title,
  isFullWidthContent,
  rightPanelContent,
  displayHelpButton,
  ...otherProps
}) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(mainSelectors.isLoadingSelector);

  const isNavigationExpanded = useSelector(layoutSelectors.isNavigationExpandedSelector);

  const handleToggleExpand = () => {
    dispatch(layoutActions.setNavigationExpanded(!isNavigationExpanded));
  };

  return (
    <div className={classes.root} {...otherProps}>
      <div className={cx(classes.navigationContainer, isNavigationExpanded && classes.expandedNavigation)}>
        <Navigation onToggleExpand={handleToggleExpand} />
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.progressWrapper}>
          {isLoading && <LinearProgress className={classes.progress} />}
        </div>
        <div className={classes.content}>
          <div className={cx(classes.mainContent, isFullWidthContent && classes.isFullWidthContent)}>
            <div className={classes.topContent}>
              {title && <h1 className={classes.header}>{title}</h1>}
              {displayHelpButton && <HelpMenu style={{ marginLeft: 'auto', position: 'relative', top: -7 }} />}
            </div>
            {children}
          </div>
          {rightPanelContent &&
            <Paper className={classes.rightPanel}>
              {rightPanelContent}
            </Paper>
          }
        </div>
      </div>
      <UserProfile />
    </div>
  );
};

PageWrapper.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  title: PropTypes.string,
  isFullWidthContent: PropTypes.bool,
  rightPanelContent: PropTypes.any,
  displayHelpButton: PropTypes.bool,
};

PageWrapper.defaultProps = {
  isFullWidthContent: false,
  rightPanelContent: undefined,
  displayHelpButton: false,
  title: null,
};

export default withTheme(styles)(PageWrapper);
