import { LocalStorageCache } from '@auth0/auth0-react';

const getRefreshToken = () => {
  const localStorageCache = new LocalStorageCache();
  const keys = localStorageCache.allKeys();

  // eslint-disable-next-line no-restricted-syntax
  for (const key of keys) {
    if (key.includes('auth0spa')) {
      const refreshToken = localStorageCache.get(key)?.body?.['refresh_token'];
      const audience = localStorageCache.get(key)?.body?.audience;

      if (refreshToken && audience === window.__RUNTIME_CONFIG__.AUTH_AUDIENCE) {
        return refreshToken;
      }
    }
  }

  return null;
};

export default getRefreshToken;
