export default () => ({
  root: {
    maxWidth: 0,
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    flex: 1,
    marginLeft: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    minWidth: 0,
  },
});
