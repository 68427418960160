export default () => ({
  root: {
    position: 'relative',
    width: 185,
    borderRadius: 10,
    paddingRight: 12,
    transition: 'width 0.3s ease-out',

    '&.Mui-focused': {
      width: 389,
    },
  },
  input: {
    paddingLeft: 12,
    paddingRight: 24,
    boxSizing: 'border-box',
  },
  clearButton: {
    position: 'absolute',
    top: 4,
    right: 38,
    opacity: 0,
    visibility: 'hidden',
    transition: 'opacity 0.3s ease-in-out, visibility 0.3s ease-in-out',

    '& svg': {
      width: 13,
      height: 13,
    },
  },
  clearButtonVisible: {
    opacity: 1,
    visibility: 'visible',
  },
});
