import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useDidMount } from '@mip/utils';
import { useSearchParams } from 'react-router-dom';

import { withTheme } from '@mip/theming';
import { Paper } from '@mip/magic';

import { invitationListActions as actions } from 'redux/actions';
import { invitationListSelectors as selectors } from 'redux/selectors';

import TableSettingsProvider from 'components/TableSettingsProvider';

import { COLUMNS_LIST } from 'constants/invitationListConstants';

import InvitationTable from './InvitationTable';
import InvitationTableSettings from './InvitationTableSettings';
import UserManagementActions from '../UserManagementActions';
import SearchBox from '../SearchBox';
import InvitationButton from '../InvitationButton';

import useUrlParamsUpdate from './hooks/useUrlParamsUpdate';

import styles from './InvitationTabContent.styles';

const InvitationTabContent = ({ classes }) => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const getInvitationList = () => {
    dispatch(actions.getInvitationListRequest({}));
  };

  useDidMount(() => {
    dispatch(actions.initStoreFromUrlParams({ searchParams }));
    getInvitationList();
  });

  const totalItems = useSelector(selectors.totalItemsSelector);

  const searchQuery = useSelector(selectors.searchQuerySelector);

  useUrlParamsUpdate();

  const searchInvitations = ({ value }) => {
    dispatch(actions.setSearchQuery({ query: value }));
    dispatch(actions.getInvitationListRequest({ page: 1 }));
  };

  const clearSearch = () => {
    dispatch(actions.setSearchQuery({ query: '' }));
    dispatch(actions.getInvitationListRequest({ page: 1 }));
  };

  return (
    <TableSettingsProvider
      tableId="invitationList"
      columns={COLUMNS_LIST}
    >
      <UserManagementActions>
        <SearchBox
          isDisabled={totalItems === 0}
          searchQuery={searchQuery}
          onSearch={searchInvitations}
          onClearSearch={clearSearch}
        />
        <InvitationButton />
      </UserManagementActions>
      <Paper className={classes.paper}>
        <InvitationTableSettings />
        <InvitationTable />
      </Paper>
    </TableSettingsProvider>
  );
};

InvitationTabContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(InvitationTabContent);
