export default () => ({
  root: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 34,
  },
  inviteBtn: {
    padding: [7, 12],

    '& > span': {
      marginRight: 4,
    },
  },
});
