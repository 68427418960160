/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deletionArtifactId: null,
  isDeleting: false,
  isBulkDeleteDialogOpen: false,
  isBulkDeleting: false,
};

export const slice = createSlice({
  name: 'artifactDeletion',
  initialState,
  reducers: {
    setDeletionArtifactId: (state, { payload: { id } }) => {
      state.deletionArtifactId = id;
    },
    deleteArtifact: (state) => {
      state.isDeleting = true;
    },
    deleteArtifactSuccess: (state) => {
      state.isDeleting = false;
      state.deletionArtifactId = null;
    },
    deleteArtifactFailure: (state) => {
      state.isDeleting = false;
    },
    openBulkDeleteDialog: (state) => {
      state.isBulkDeleteDialogOpen = true;
    },
    closeBulkDeleteDialog: (state) => {
      state.isBulkDeleteDialogOpen = false;
    },
    bulkDeleteArtifacts: (state) => {
      state.isBulkDeleting = true;
    },
    bulkDeleteArtifactsSuccess: (state) => {
      state.isBulkDeleting = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
