import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { userInvitationActions } from 'redux/actions';
import { withTheme } from '@mip/theming';

import { Button } from '@mip/magic';
import { Add } from 'icons';

import styles from './InvitationButton.styles';

const InvitationButton = ({ classes }) => {
  const dispatch = useDispatch();

  const handleOpenInviteUsersDialog = () => {
    dispatch(userInvitationActions.setInviteUsersDialogOpen(true));
  };

  return (
    <Button
      className={classes.root}
      variant="raised"
      color="primary"
      startIcon={<Add />}
      onClick={handleOpenInviteUsersDialog}
    >
      Invite users
    </Button>
  );
};

InvitationButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(InvitationButton);
