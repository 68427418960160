import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';

import { userRoleChangeActions as actions } from 'redux/actions';
import { userRoleChangeSelectors as selectors } from 'redux/selectors';

import ModifyRoleDialogContent from './ModifyRoleDialogContent';

import styles from './ModifyRoleDialog.styles';

const ModifyRoleDialog = ({ classes, user }) => {
  const dispatch = useDispatch();

  const isInProgress = useSelector(selectors.isInProgressSelector);

  const [role, setRole] = useState(user.role);

  const handleClose = () => {
    dispatch(actions.setRoleChangeUserId({ id: null }));
  };

  const handleSubmit = () => {
    dispatch(actions.changeUserRole({ id: user.user_id, role: user.role, email: user.email }));
  };

  return (
    <Dialog classes={classes} open onClose={handleClose}>
      <DialogHeader title={<span className={classes.title}>Modify user role</span>}>
        <Button className={classes.cancelBtn} onClick={handleClose}>Close</Button>
        <Button
          className={classes.submitBtn}
          variant="raised"
          color="primary"
          type="submit"
          loading={isInProgress}
          onClick={handleSubmit}
        >
          Modify
        </Button>
      </DialogHeader>
      <DialogContent>
        <ModifyRoleDialogContent email={user.email} role={role} onSetRole={setRole} />
      </DialogContent>
    </Dialog>
  );
};

ModifyRoleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withTheme(styles)(ModifyRoleDialog);
