export const COLUMNS = {
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  LAST_ACTIVITY: 'LAST_ACTIVITY',
  USER_SINCE: 'USER_SINCE',
  ROLE: 'ROLE',
};

export const COLUMNS_LIST = [
  {
    type: COLUMNS.NAME,
    title: 'Name',
    isAlwaysVisible: true,
  },
  {
    type: COLUMNS.EMAIL,
    title: 'Email',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.LAST_ACTIVITY,
    title: 'Last Activity',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.USER_SINCE,
    title: 'User Since',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.ROLE,
    title: 'Role',
    isDefaultVisible: true,
  },
];
