import { all, put, takeLatest, select } from 'redux-saga/effects';
import { notify, setIsGlobalLoading } from 'redux/actions';
import { statuses } from 'constants/artifactListConstants';

import { artifactService } from 'api';

import { actions } from './artifactListReducer';
import * as selectors from './artifactListSelectors';

const sortbyColumns = {
  UPLOAD_DATE: 'origin.creation_time',
  NAME: 'title',
  PUBLICATION_DATE: 'publication_date',
};

export function* getArtifactListFlow({ payload = {} }) {
  let { page } = payload;
  if (!page) {
    page = yield select(selectors.pageSelector);
  }
  const status = yield select(selectors.statusSelector);
  const size = yield select(selectors.limitSelector);
  const sorting = yield select(selectors.sortingSelector);
  const searchQuery = yield select(selectors.searchQuerySelector);

  yield put(setIsGlobalLoading(true));
  try {
    const { data } = yield artifactService.searchArtifacts({
      page: page - 1,
      statuses: statuses[status],
      size,
      sorting: {
        ...sorting,
        field_name: sortbyColumns[sorting.field_name],
      },
      searchTerm: searchQuery || undefined,
    });

    if (data.items.length === 0 && page !== 1) {
      yield put(actions.getArtifactListRequest({
        page: Math.floor(data.total_items / size),
      }));
      return;
    }

    yield put(actions.getArtifactListSuccess({ data }));
  } catch (e) {
    yield put(actions.getArtifactListFailure());
    yield put(notify({ title: 'Something went wrong', type: 'error' }));
  }
  yield put(setIsGlobalLoading(false));
}

export function* pollArtifactsFlow({ payload: { ids } }) {
  try {
    const { data } = yield artifactService.searchArtifacts({ ids });
    yield put(actions.pollArtifactsSuccess({ artifacts: data.items }));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.getArtifactListRequest.type, getArtifactListFlow),
    takeLatest(actions.pollArtifacts, pollArtifactsFlow),
  ]);
}
