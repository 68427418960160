export default ({
  styleOverrides: {
    root: {
      '&&': {
        padding: 3,

        svg: {
          width: 20,
          height: 20,
        },
      },
      '.MuiCheckbox-uncheckedIcon': {
        fill: '#fcfcfc',
        stroke: '#e4e4e4',
      },
      '.MuiCheckbox-checkedIcon-background': {
        fill: '#424ff5',
        stroke: '#e4e4e4',
      },
    },
  },
});
