export default ({
  styleOverrides: {
    root: {
      background: '#FAFAFA',

      '& .MuiTableCell-root': {
        fontFamily: '"Matter", Helvetica, sans-serif',
        fontSize: 14,
        fontWeight: 500,
        color: 'rgba(27, 27, 27, 0.6)',
        height: 40,
        boxSizing: 'border-box',
        borderBottom: 'none',

        '&:first-of-type': {
          overflow: 'hidden',

          '&:before': {
            content: '""',
            position: 'absolute',
            left: 0,
            top: 0,
            height: '100%',
            width: '100vw',
            pointerEvents: 'none',
            boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.28), 0px 0px 1px rgba(0, 0, 0, 0.17)',
            opacity: 0,
            transition: 'opacity 0.3s',

            '.MuiTableContainer-scrolled &': {
              opacity: 1,
            },
          },
        },
      },
    },
  },
});
