/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isUserProfileOpen: false,
  isHintsFlowEnabled: false,
};

export const slice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setIsGlobalLoading: (state, { payload: isLoading }) => {
      state.isLoading = isLoading;
    },
    setUserProfileOpen: (state, { payload }) => {
      state.isUserProfileOpen = payload;
    },
    setHintsFlowEnabled: (state, { payload }) => {
      state.isHintsFlowEnabled = payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
