import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';
import { Table, TableHead, TableRow, TableCell, TableBody, Checkbox } from '@mui/material';
import { format } from 'date-fns';

import { artifactListActions as actions } from 'redux/actions';
import { artifactListSelectors as selectors } from 'redux/selectors';

import { COLUMNS } from 'constants/artifactListConstants';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import ArtifactListTableContainer from './ArtifactListTableContainer';
import ArtifactNameCell from './ArtifactNameCell';
import ArtifactStatusCell from './ArtifactStatusCell';
import MoreActionsCell from './MoreActionsCell/MoreActionsCell';
import SortableHeadCell from './ArtifactsTableSortableHeadCell/ArtifactsTableSortableHeadCell';

import styles from './ArtifactsTable.styles';

const formatDate = date => (date ? format(new Date(date), 'dd.MM.yyyy') : '');

const ArtifactsTable = ({ classes }) => {
  const dispatch = useDispatch();
  const artifacts = useSelector(selectors.artifactsSelector);
  const selectedArtifacts = useSelector(selectors.selectedArtifactsSelector);
  const areAllPageArtifactsSelected = useSelector(selectors.areAllPageArtifactsSelectedSelector);
  const areArtifactsPartiallySelected = useSelector(selectors.areArtifactsPartiallySelectedSelector);

  const { visibleColumns } = useTableSettings();

  const handleRowSelect = (artifactId, value) => {
    dispatch(actions.selectArtifact({ artifactId, value }));
  };

  const handleSelectAllPageArtifacts = (value) => {
    dispatch(actions.selectAllArtifactsOnPage({ value }));
  };

  const renderHeaderCell = (column) => {
    switch (column.type) {
      case COLUMNS.NAME:
        return <TableCell column={column} sx={{ minWidth: '165px' }}>{column.title}</TableCell>;
      case COLUMNS.STATUS:
        return <TableCell sx={{ width: '165px' }}>{column.title}</TableCell>;
      case COLUMNS.TOTAL_PAGES:
        return <TableCell sx={{ width: '165px' }} align="right">{column.title}</TableCell>;
      case COLUMNS.PUBLICATION_DATE:
        return <SortableHeadCell column={column} title={column.title} align="right" sx={{ width: '165px' }} />;
      case COLUMNS.UPLOAD_DATE:
        return <SortableHeadCell column={column} title={column.title} align="right" sx={{ width: '165px' }} />;
      default:
        return null;
    }
  };

  const renderBodyCell = (column, artifact) => {
    switch (column) {
      case COLUMNS.NAME:
        return <ArtifactNameCell title={artifact.original_file_name || artifact.file_name || artifact.title} />;
      case COLUMNS.STATUS:
        return <ArtifactStatusCell status={artifact.status} binaryRejectionReason={artifact.binary_rejection_reason} />;
      case COLUMNS.TOTAL_PAGES:
        return <TableCell align="right">{artifact.total_pages ? artifact.total_pages : ''}</TableCell>;
      case COLUMNS.PUBLICATION_DATE:
        return <TableCell align="right">{formatDate(artifact.publication_date)}</TableCell>;
      case COLUMNS.UPLOAD_DATE:
        return <TableCell align="right">{formatDate(artifact.origin.creation_time)}</TableCell>;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <ArtifactListTableContainer className={classes.root}>
        <Table className={classes.table} stickyHeader data-qa="atifactsTable">
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '30px', padding: '0 0 0 10px' }}>
                <Checkbox
                  checked={areAllPageArtifactsSelected}
                  indeterminate={areArtifactsPartiallySelected}
                  onChange={e => handleSelectAllPageArtifacts(e.target.checked)}
                />
              </TableCell>
              {visibleColumns.map(column => <Fragment key={column.type}>{renderHeaderCell(column)}</Fragment>)}
              <TableCell sx={{ width: '165px' }} align="right" />{/* actions column */}
            </TableRow>
          </TableHead>
          <TableBody>
            {artifacts.map(artifact => (
              <TableRow
                key={artifact.id}
                style={{ background: selectedArtifacts[artifact.id] ? '#f7f7ff' : undefined }}
              >
                <TableCell sx={{ width: '30px', padding: '0 0 0 10px' }}>
                  <Checkbox
                    checked={Boolean(selectedArtifacts[artifact.id])}
                    onChange={e => handleRowSelect(artifact.id, e.target.checked)}
                  />
                </TableCell>
                {visibleColumns.map(column =>
                  <Fragment key={column.type}>{renderBodyCell(column.type, artifact)}</Fragment>)}
                <MoreActionsCell
                  id={artifact.id}
                  fileName={artifact.file_name}
                  passedValidation={artifact.total_pages > 0}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ArtifactListTableContainer>
    </Fragment>
  );
};

ArtifactsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsTable);
