import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { memberListSelectors as selectors } from 'redux/selectors';

export default () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const page = useSelector(selectors.pageSelector);
  const limit = useSelector(selectors.limitSelector);
  const searchQuery = useSelector(selectors.searchQuerySelector);

  useEffect(() => {
    if (page) {
      searchParams.set('page', page);
    }
    if (limit === 10) {
      searchParams.delete('limit');
    } else {
      searchParams.set('limit', limit);
    }
    if (searchQuery) {
      searchParams.set('q', searchQuery);
    } else {
      searchParams.delete('q');
    }
    setSearchParams(searchParams);
  }, [page, searchParams, searchQuery, setSearchParams, limit]);
};
