export default () => ({
  root: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',

    '&:not(:last-child)': {
      marginBottom: 12,
    },
  },
  link: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    fontSize: 15,
    fontWeight: 500,
    color: '#788293',
    textDecoration: 'none',
    borderRadius: 8,

    '& svg': {
      color: '#7F7F7F',
    },

    '&:hover': {
      backgroundColor: '#F2F3F4',
      color: '#0F0F0F',

      '& svg': {
        color: '#0F0F0F',
      },
    },
  },
  activeLink: {
    backgroundColor: '#F2F3F4',

    '& svg': {
      color: '#0F0F0F',
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    width: 40,
    marginRight: 10,
    flexShrink: 0,
  },
  name: {
    whiteSpace: 'nowrap',
  },
});
