/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNavigationExpanded: false,
};

export const slice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setNavigationExpanded: (state, { payload: isExpanded }) => {
      state.isNavigationExpanded = isExpanded;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
