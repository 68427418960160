import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';
import { userInvitationActions as actions } from 'redux/actions';
import { userInvitationSelectors as selectors } from 'redux/selectors';

import { Error as ErrorIcon } from '@mip/magic/icons';

import styles from './RevokeInvitationDialog.styles';

const RevokeInvitationDialog = ({ classes, id, email, onClose }) => {
  const dispatch = useDispatch();

  const isRevokeInProgress = useSelector(selectors.isRevokeInProgressSelector);

  const handleSubmit = () => {
    dispatch(actions.revokeInvitation({ id }));
  };

  return (
    <Dialog classes={classes} open onClose={onClose}>
      <DialogHeader title={(<span className={classes.title}><ErrorIcon color="error" /> Revoke invitation</span>)}>
        <Button className={classes.cancelBtn} onClick={onClose}>Cancel</Button>
        <Button
          className={classes.submitBtn}
          color="error"
          variant="raised"
          loading={isRevokeInProgress}
          onClick={handleSubmit}
        >
          Revoke
        </Button>
      </DialogHeader>
      <DialogContent>
        Are you sure you want to revoke invitation for <b>{email}</b>?
        <p>This action can’t be undone.</p>
      </DialogContent>
    </Dialog>
  );
};

RevokeInvitationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTheme(styles)(RevokeInvitationDialog);
