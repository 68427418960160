import ErrorScreen from 'components/ErrorScreen';
import React from 'react';

const NotFoundPage = () => (
  <ErrorScreen
    title="Page not found"
    description="Sorry, we can’t find the page you’re looking for"
    errorCode={404}
  />
);

export default NotFoundPage;
