import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import cx from 'classnames';

import styles from './Chip.styles';

const Chip = ({ classes, title, color }) => (
  <div className={cx(classes.root, classes[color])}>
    {title}
  </div>
);

Chip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['purple', 'blue', 'green', 'orange']),
};

Chip.defaultProps = {
  color: 'blue',
};

export default withTheme(styles)(Chip);
