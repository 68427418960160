export const styles = () => ({
  root: {
    position: 'absolute',
  },
  content: {
    maxWidth: 238,
    fontFamily: 'Matter',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '130%',
    color: 'rgba(255, 255, 255, 0.8)',
  },
  title: {
    marginBottom: 15,
    fontSize: 18,
    fontWeight: 600,
    color: '#fff',
  },
  dashedBorder: {
    margin: [15, 0],
    height: 1,
    backgroundImage: 'linear-gradient(to right, #8E95F9 60%, rgba(255,255,255,0) 0%)',
    backgroundPosition: 'bottom',
    backgroundSize: '8px 1px',
    backgroundRepeat: 'repeat-x',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    backgroundColor: 'transparent',
    padding: 0,
    border: 'none',
    cursor: 'pointer',
    color: '#B3B9FB',
    fontFamily: 'inherit',
    fontSize: 'inherit',

    '&:hover': {
      textDecoration: 'underline',
    },
  },
  navigation: {
    display: 'flex',
  },
  doneButton: {
    backgroundColor: '#21287A',

    '&:hover': {
      backgroundColor: '#21287A !important',
    },
  },
});
