import React from 'react';
import { FormControl, InputLabel } from '@mip/magic';

import Select from 'components/UIKit/Select';
import MenuItemWithSwitch from 'components/UIKit/MenuItemWithSwitch';

import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

const ColumnVisibilitySelect = () => {
  const { columns, visibleColumns, setColumnsVisibility } = useTableSettings();

  const handleChange = (e) => {
    const obj = {};

    e.target.value.forEach((column) => {
      obj[column] = true;
    });

    setColumnsVisibility(obj);
  };

  return (
    <FormControl sx={{ width: 185, mt: '-19px' }}>
      <InputLabel>Columns</InputLabel>
      <Select
        value={visibleColumns.map(({ type }) => type)}
        multiple
        SelectDisplayProps={{ 'data-qa': 'columnVisibilitySelect' }}
        onChange={handleChange}
      >
        {columns.map(column => (
          <MenuItemWithSwitch
            key={column.type}
            value={column.type}
            dataQa={`columnVisibilitySelect-menuItem-${column.type}`}
            disabled={column.isAlwaysVisible}
          >
            {column.title}
          </MenuItemWithSwitch>
        ))}
      </Select>
      <div data-hint="columns" style={{ position: 'absolute', bottom: 18, left: '41%' }} />
    </FormControl>
  );
};

export default ColumnVisibilitySelect;
