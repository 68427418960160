/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { defaultSorting, getSortingFromUrlParam } from 'utils/sortingHelpers/artifactListSortingHelpers';

const initialState = {
  artifacts: null,
  totalItems: null,
  page: 1,
  selectedArtifacts: {},
  status: 'all',
  limit: 10,
  columnsVisibility: ['status', 'totalPages'],
  sorting: defaultSorting,
  searchQuery: '',
};

export const slice = createSlice({
  name: 'artifactList',
  initialState,
  reducers: {
    initStoreFromUrlParams: (state, { payload: { searchParams } }) => {
      state.page = Number(searchParams.get('page')) || 1;
      state.status = searchParams.get('status') || 'all';
      state.limit = Number(searchParams.get('limit')) || 10;
      state.sorting = getSortingFromUrlParam(searchParams.get('sort'));
      state.searchQuery = searchParams.get('q') || '';
    },
    getArtifactListRequest: (state, { payload: { page } }) => {
      if (page) {
        state.page = page;
      }
    },
    getArtifactListSuccess: (state, { payload: { data } }) => {
      state.artifacts = data.items;
      state.totalItems = data.total_items;
    },
    getArtifactListFailure: () => {},
    pollArtifacts: () => {},
    pollArtifactsSuccess: (state, { payload: { artifacts } }) => {
      if (state.artifacts) {
        state.artifacts =
          state.artifacts.map(artifact => artifacts.find(({ id }) => artifact.id === id) || artifact);
      }
    },
    selectArtifact: (state, { payload: { artifactId, value } }) => {
      state.selectedArtifacts[artifactId] = value;
    },
    selectAllArtifactsOnPage: (state, { payload: { value } }) => {
      state.artifacts.forEach((artifact) => {
        state.selectedArtifacts[artifact.id] = value;
      });
    },
    deselectAll: (state) => {
      state.selectedArtifacts = initialState.selectedArtifacts;
    },
    setStatus: (state, { payload: { value } }) => {
      state.status = value;
    },
    setLimit: (state, { payload: { value } }) => {
      state.limit = value;
    },
    setColumnsVisibility: (state, { payload: { value } }) => {
      state.columnsVisibility = value;
    },
    setSorting: (state, { payload }) => {
      state.sorting = payload;
    },
    setSearchQuery: (state, { payload: { query } }) => {
      state.searchQuery = query;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
