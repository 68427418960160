import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { Controller } from 'react-hook-form';

import TextFieldController from 'components/formElements/TextFieldController';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mip/magic';

import { USER_ROLES } from 'constants/userRolesConstants';

import styles from './FormBlock.styles';

const FormBlock = ({ classes, control, item, index }) => (
  <div className={classes.root}>
    <div className={classes.field}>
      <TextFieldController
        className={classes.textField}
        control={control}
        name={`items[${index}].email`}
        label="Email"
        defaultValue={item.email}
        placeholder="ex. email@company.com"
        rules={{
          pattern: {
            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            message: 'Wrong email format',
          },
        }}
      />
    </div>
    <div className={classes.field}>
      <InputLabel className={classes.inputLabel}>Platform role</InputLabel>
      <Controller
        name={`items[${index}].role`}
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControl sx={{ minWidth: '100%' }} className={classes.formField}>
            <Select
              value={value}
              label="Platform role"
              onChange={onChange}
            >
              <MenuItem value={USER_ROLES.CONTENT_CONSUMER}>Content Consumer</MenuItem>
              <MenuItem value={USER_ROLES.CONTENT_MANAGER}>Content Manager</MenuItem>
              <MenuItem value={USER_ROLES.PLATFORM_OWNER}>Platform Owner</MenuItem>
            </Select>
          </FormControl>
        )}
      />
    </div>
  </div>
);

FormBlock.propTypes = {
  classes: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default withTheme(styles)(FormBlock);
