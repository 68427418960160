import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { withTheme } from '@mip/theming';

import FileUploader from 'components/FileUploader';
import StorageBlock from 'components/StorageBlock';

import { artifactStatisticsSelectors } from 'redux/selectors';

import styles from './RightPanelContent.styles';

const RightPanelContent = ({ classes }) => {
  const isStatisticLoaded = useSelector(artifactStatisticsSelectors.isLoadedSelector);
  const totalArtifacts = useSelector(artifactStatisticsSelectors.totalSelector);

  const renderContent = () => {
    if (!isStatisticLoaded) {
      return null;
    }

    return (
      <Fragment>
        {totalArtifacts > 0 && <FileUploader />}
        <StorageBlock />
      </Fragment>
    );
  };

  return (
    <div className={classes.root} data-qa="rightPanel">
      {renderContent()}
    </div>
  );
};

RightPanelContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(RightPanelContent);
