import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';

import MuiPaper from '@mui/material/Paper';
import MuiFade from '@mui/material/Fade';
import MuiPopper from '@mui/material/Popper';
import ArrowIcon from './ArrowIcon';

import getStyles from './HintPopover.styles';

const HintPopover = ({
  classes,
  children,
  open,
  ...otherProps
}) => {
  const [arrowRef, setArrowRef] = useState(null);

  return (
    <Fragment>
      <MuiPopper
        transition
        open={open}
        className={classes.popper}
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        {...otherProps}
      >
        {({ TransitionProps }) => (
          <MuiFade {...TransitionProps} timeout={350}>
            <div>
              <MuiPaper className={classes.paper}>
                {children}
              </MuiPaper>
              <div ref={setArrowRef} className={classes.arrow}>
                <ArrowIcon />
              </div>
            </div>
          </MuiFade>
        )}
      </MuiPopper>
    </Fragment>
  );
};

HintPopover.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  placement: PropTypes.oneOf([
    'top-start', 'top', 'top-end',
    'left-start', 'left', 'left-end',
    'right-start', 'right', 'right-end',
    'bottom-start', 'bottom', 'bottom-end',
  ]),
  children: PropTypes.any.isRequired,
};

HintPopover.defaultProps = {
  placement: 'bottom',
};

export default withTheme(getStyles)(HintPopover);
