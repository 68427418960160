export default ({
  styleOverrides: {
    select: {
      '&&': {
        paddingRight: 27,
      },
    },
    icon: {
      '&&': {
        color: 'rgba(27, 27, 27, 0.6)',
      },
    },
  },
});
