import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

import { invitationListSelectors as selectors } from 'redux/selectors';

import { COLUMNS } from 'constants/invitationListConstants';
import { formatCreatedAtDate } from 'utils/dateHelpers';

import { TableContainer, RoleCell } from 'components/UIKit';
import useTableSettings from 'components/TableSettingsProvider/useTableSettings';

import InvitationEmailCell from './InvitationEmailCell';
import MoreActionsCell from './MoreActionsCell';

import styles from './InvitationTable.styles';

const InvitationTable = ({ classes }) => {
  const invitations = useSelector(selectors.invitationsSelector);

  const { visibleColumns } = useTableSettings();

  const renderHeaderCell = (column) => {
    switch (column.type) {
      case COLUMNS.EMAIL:
        return <TableCell column={column} sx={{ minWidth: '165px' }}>{column.title}</TableCell>;
      case COLUMNS.CREATED_AT:
        return <TableCell column={column} align="right" sx={{ width: '165px' }}>{column.title}</TableCell>;
      case COLUMNS.ROLE:
        return <TableCell column={column} sx={{ width: '165px' }}>{column.title}</TableCell>;
      default:
        return null;
    }
  };

  const renderBodyCell = (column, invitation) => {
    switch (column) {
      case COLUMNS.EMAIL:
        return <InvitationEmailCell email={invitation.invitee.email} />;
      case COLUMNS.CREATED_AT:
        return <TableCell align="right">{formatCreatedAtDate(invitation.created_at)}</TableCell>;
      case COLUMNS.ROLE:
        return <RoleCell role={invitation.role} />;
      default:
        return null;
    }
  };

  if (!invitations) {
    return null;
  }

  return (
    <Fragment>
      <TableContainer className={classes.root}>
        <Table className={classes.table} stickyHeader data-qa="invitationTable">
          <TableHead>
            <TableRow>
              {visibleColumns.map(column => <Fragment key={column.type}>{renderHeaderCell(column)}</Fragment>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map(invitation => (
              <TableRow
                key={invitation.id}
              >
                {visibleColumns.map(column =>
                  <Fragment key={column.type}>{renderBodyCell(column.type, invitation)}</Fragment>)}
                <MoreActionsCell id={invitation.id} email={invitation.invitee.email} />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fragment>
  );
};

InvitationTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(InvitationTable);
