import React, { useState, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import withContentManagerRequired from 'hocs/withContentManagerRequired';

import PageWrapper from 'components/PageWrapper';
import HintsController from 'components/Hints/HintsController';

import { artifactCreationActions as actions } from 'redux/actions';
import { mainSelectors } from 'redux/selectors';

import FileUploaderPageContent from './FileUploaderPageContent';
import RightPanelContent from './RightPanelContent';
import DndPlaceholder from './DndPlaceholder';
import PollArtifactsDataController from './PollArtifactsDataController';

const FileUploaderPage = () => {
  const dispatch = useDispatch();
  const [isDragging, setIsDragging] = useState(false);
  const dragCounter = useRef(0);

  const handleDragEnter = () => {
    if (dragCounter.current === 0) {
      setIsDragging(true);
    }
    dragCounter.current += 1;
  };

  const handleDragLeave = () => {
    dragCounter.current -= 1;

    if (dragCounter.current === 0) {
      setIsDragging(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    dragCounter.current = 0;
    setIsDragging(false);
    dispatch(actions.createArtifactsRequest({ files: e.dataTransfer.files }));
  };

  const handleDragOver = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const isHintsFlowEnabled = useSelector(mainSelectors.isHintsFlowEnabledSelector);

  return (
    <Fragment>
      <PageWrapper
        title="File Uploader"
        rightPanelContent={<RightPanelContent />}
        displayHelpButton
        onDrop={handleDrop}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
      >
        <FileUploaderPageContent />
        <PollArtifactsDataController />
      </PageWrapper>
      <DndPlaceholder visible={isDragging} />
      {isHintsFlowEnabled && <HintsController />}
    </Fragment>
  );
};

export default withContentManagerRequired(FileUploaderPage);
