import axios from 'axios';
import { portalUiService } from 'api';

const request = async (options, isSecondTry) => {
  const {
    method,
    url,
    params,
    data,
    headers,
  } = options;

  const tokenResponse = await portalUiService.generateToken();
  const token = tokenResponse.data?.['access_token'];

  try {
    const res = await axios({
      method,
      url,
      params,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
        ...headers,
      },
      baseURL: window.__RUNTIME_CONFIG__.API_CORE_BASE_URL,
    });

    return res;
  } catch (e) {
    if (!isSecondTry && token && e.response?.status === 401) {
      const res = await request(options, true);
      return res;
    }
    throw e;
  }
};

export default request;
