import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';
import { artifactStatisticsSelectors as selectors } from 'redux/selectors';

import StorageDoughnutChart from './StorageDoughnutChart';

import styles from './StorageBlock.styles';

const StorageBlock = ({ classes }) => {
  const statistics = useSelector(selectors.artifactsByStatusSelector);
  const maxFiles = useSelector(selectors.maxArtifactsSelector);

  const { completed, processing, failed } = statistics;

  const documentsByStatus = {
    completed: {
      color: '#91EAE4',
      title: 'Completed files',
      count: completed,
    },
    processing: {
      color: '#424FF5',
      title: 'Files in progress',
      count: processing,
    },
    failed: {
      color: '#F59842',
      title: 'Failed files',
      count: failed,
    },
    remaining: {
      color: '#F4F4F4',
      title: 'Remaining space',
      count: maxFiles - completed - processing - failed,
    },
  };

  const renderLegend = status => (
    <div className={classes.legendItem} key={status.title}>
      <div className={classes.legendLeft}>
        <div className={classes.legendColor} style={{ backgroundColor: status.color }} />
        <div>{status.title}</div>
      </div>
      <div className={classes.legendCount}>{status.count}</div>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.title}>Storage</div>
      {Object.keys(statistics).length === 0 ? null : (
        <Fragment>
          <div className={classes.progressCircle}>
            <StorageDoughnutChart documentsByStatus={documentsByStatus} maxFiles={maxFiles} />
            <div className={classes.value}>
              <div className={classes.numberOfFiles}>{completed + processing + failed}</div>
              <div className={classes.maxFiles}>of {maxFiles} files</div>
            </div>
          </div>
          <div className={classes.legend}>
            {Object.keys(documentsByStatus).map(status => (documentsByStatus[status].count > 0 ?
              renderLegend(documentsByStatus[status]) :
              null))}
          </div>
        </Fragment>
      )}
      <div data-hint="storage" style={{ position: 'absolute', top: '31%', left: -45 }} />
    </div>
  );
};

StorageBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(StorageBlock);
