export default () => ({
  wrapper: {
    padding: 0,
  },
  root: {
    height: 50,
    boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.17), 0px 0px 1px rgba(0, 0, 0, 0.17)',
    padding: [0, 20],
    transition: 'none',
  },
  title: {
    fontFamily: '"Matter", Helvetica, sans-serif',
    fontSize: 18,
    fontWeight: 600,
    lineHeight: '20px',
  },
});
