import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import PageWrapper from 'components/PageWrapper';
import UserProfile from 'components/UserProfile';

import DSWidget from './DSWidget';

const HomePage = () => {
  const [renderKey, setRenderKey] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('new')) {
      searchParams.delete('new');
      setSearchParams(searchParams);
      setRenderKey(Math.random());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <PageWrapper isFullWidthContent>
      <DSWidget key={renderKey} />
      <UserProfile />
    </PageWrapper>
  );
};

export default HomePage;
