import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TenantHomePage from 'pages/TenantHomePage';
import FileUploaderPage from 'pages/FileUploaderPage';
import HomePage from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import ArtifactViewer from 'pages/ArtifactViewerPage';
import UserManagementPage from 'pages/UserManagementPage';
import RedirectPage from 'pages/RedirectPage';

import { USER_MANAGEMENT_ENABLED } from 'featureToggles';

import PrivateRoute from './PrivateRoute';

const privateRoutes = [
  {
    path: '/:orgName/',
    element: <TenantHomePage />,
  },
  {
    path: '/:orgName/file-uploader',
    element: <FileUploaderPage />,
  },
  {
    path: '/:orgName/artifact-viewer/:artifactId',
    element: <ArtifactViewer />,
  },
  {
    path: '/:orgName/redirect',
    element: <RedirectPage />,
  },
];

if (USER_MANAGEMENT_ENABLED) {
  privateRoutes.push({
    path: '/:orgName/user-management/:tab?',
    element: <UserManagementPage />,
  });
}

const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<HomePage />} />
      {privateRoutes.map(({ path, element }) => (
        <Route
          key={path}
          path={path}
          element={<PrivateRoute>{element}</PrivateRoute>}
        />
      ))}
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </Router>
);

export default AppRouter;
