export default ({ colors }) => ({
  error: {
    '&& > svg': {
      fill: colors.palette.red[5],
    },
  },
  warning: {
    '&& > svg': {
      fill: colors.palette.orange[5],
    },
  },
  info: {
    '&& > svg': {
      fill: colors.palette.blue[6],
    },
  },
  success: {
    '&& > svg': {
      fill: colors.palette.green[8],
    },
  },
  noContent: {
    '& h2': {
      marginTop: 8,
    },
  },
});
