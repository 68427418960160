import React from 'react';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { userInvitationActions as actions, invitationListActions, notify } from 'redux/actions';
import { request } from 'api/portal';
import * as selectors from '../invitationList/invitationListSelectors';

export function* revokeInvitationFlow({ payload: { id } }) {
  try {
    yield request({
      method: 'post',
      url: '/invitations/_revoke',
      data: {
        id,
      },
    });
    yield put(actions.revokeInvitationSuccess({ id }));
    yield put(notify({ title: <div>Invitation has been revoked</div>, type: 'success' }));

    const currentPage = yield select(selectors.pageSelector);
    yield put(invitationListActions.getInvitationListRequest({ page: currentPage }));
  } catch (e) {
    yield put(actions.revokeInvitationFailure());
    yield put(notify({ title: 'Failed to revoke invitation', type: 'error' }));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.revokeInvitation.type, revokeInvitationFlow),
  ]);
}
