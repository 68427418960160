export default () => ({
  popper: {
    zIndex: 1500,
    '& > div': {
      position: 'relative',
    },
    '&[data-popper-placement*="top"]': {
      '&>div': {
        marginBottom: 10,
      },
      '& $arrow': {
        bottom: -13,
      },
    },
    '&[data-popper-placement*="bottom"]': {
      '&>div': {
        marginTop: 10,
      },
      '& $arrow': {
        top: -14,

        '& svg': {
          transform: 'rotate(180deg)',
        },
      },
    },
    '&[data-popper-placement*="left"]': {
      '&>div': {
        marginRight: 10,
      },
      '& $arrow': {
        right: -13,

        '& svg': {
          transform: 'rotate(270deg)',
        },
      },
    },
    '&[data-popper-placement*="right"]': {
      '&>div': {
        marginLeft: 10,
      },
      '& $arrow': {
        left: -13,

        '& svg': {
          transform: 'rotate(90deg)',
        },
      },
    },
  },
  paper: {
    borderRadius: 3,
    padding: 13,
    backgroundColor: '#424FF5',
    boxSizing: 'border-box',
    boxShadow: '0px 3px 8px rgba(0, 0, 0, 0.17), 0px 0px 1px rgba(0, 0, 0, 0.17)',
  },
  arrow: {
    width: 13,
    height: 13,

    '& svg': {
      transformOrigin: ['50%', '7px'],
      display: 'block',
      color: '#424FF5',
    },
  },
});
