import members from './membersMock';
import invitations from './invitationsMock';

const axios = require('axios');
const MockAdapter = require('axios-mock-adapter');

const mock = new MockAdapter(axios);

mock.onPost('/members/_search').reply((config) => {
  const { page = 0, size = 10, title = '' } = JSON.parse(config.data);
  const offset = page * size;

  if (title !== '') {
    const filteredMembers = members.filter(el => el.name.toLowerCase().includes(title.toLowerCase()));
    return [
      200,
      {
        items: filteredMembers,
        total_items: filteredMembers.length,
      },
    ];
  }

  return [
    200,
    {
      items: members.slice(offset, offset + size),
      total_items: members.length,
    },
  ];
});

mock.onPost('/invitations/_search').reply((config) => {
  const { page = 0, size = 10, title = '' } = JSON.parse(config.data);
  const offset = page * size;

  if (title !== '') {
    const filteredInvitations = invitations.filter(el => el.invitee.email.toLowerCase().includes(title.toLowerCase()));
    return [
      200,
      {
        items: filteredInvitations,
        total_items: filteredInvitations.length,
      },
    ];
  }

  return [
    200,
    {
      items: invitations.slice(offset, offset + size),
      total_items: invitations.length,
    },
  ];
});

mock.onPost('/invitations').reply((config) => {
  const data = JSON.parse(config.data);

  const newInvitations = data.invitations.map(invitation => ({
    id: Math.random(),
    invitee: {
      email: invitation.email,
    },
    created_at: '2023-08-24T12:41:18.545Z',
    role: invitation.role,
  }));

  invitations.unshift(...newInvitations);

  return [200, {}];
});

mock.onPost('/invitations/_revoke').reply((config) => {
  const data = JSON.parse(config.data);

  const index = invitations.findIndex(({ id }) => data.id === id);

  if (index !== -1) {
    invitations.splice(index, 1);
  }

  return [200, {}];
});

mock.onDelete('/members/_delete').reply((config) => {
  const data = JSON.parse(config.data);

  // eslint-disable-next-line camelcase
  const index = members.findIndex(({ user_id }) => data.id === user_id);

  if (index !== -1) {
    members.splice(index, 1);
  }

  return [200, {}];
});

mock.onPost('/members/_change-role').reply((config) => {
  const data = JSON.parse(config.data);

  // eslint-disable-next-line camelcase
  const user = members.find(({ user_id }) => data.id === user_id);

  user.role = data.role;

  return [200, {}];
});

mock.onAny().passThrough();
