export default ({
  styleOverrides: {
    root: {
      '& .MuiMenuItem-root:hover': {
        backgroundColor: 'rgba(228, 228, 228, 0.2)',
      },
      '&& .Mui-selected': {
        backgroundColor: 'rgba(228, 228, 228, 0.4)',
      },
      '&& .MuiMenuItem-root:active': {
        backgroundColor: 'rgba(228, 228, 228, 0.6)',
      },
    },
  },
});
