import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { IconButton } from '@mip/magic';
import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from '@mip/magic/icons';

import styles from './Pagination.styles';

const Pagination = ({ count, page, onChange }) => {
  const handleGoToPrevPage = () => {
    onChange(page - 1);
  };

  const handleGoToNextPage = () => {
    onChange(page + 1);
  };

  return (
    <div>
      <IconButton size="small" disabled={page === 1} data-qa="pagination-prevPage" onClick={handleGoToPrevPage}>
        <ArrowLeftIcon />
      </IconButton>
      <IconButton size="small" disabled={page === count} data-qa="pagination-nextPage" onClick={handleGoToNextPage}>
        <ArrowRightIcon />
      </IconButton>
    </div>
  );
};

Pagination.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withTheme(styles)(Pagination);
