import React from 'react';
import { withTheme } from '@mip/theming';

import { Select as MagicSelect } from '@mip/magic';

import getStyles from './Select.styles';

const Select = props => <MagicSelect {...props} />;

export default withTheme(getStyles)(Select);
