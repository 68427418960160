import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';

import { useDidMount } from '@mip/utils';

import { setTokenGenerator } from 'api/portal';
import withAuth0Provider from 'hocs/withAuth0Provider';
import withAuthRequired from 'hocs/withAuthRequired';

import { userActions } from 'redux/actions';
import { userSelectors } from 'redux/selectors';
import { LinearProgress } from '@mip/magic';
import ErrorScreen from 'components/ErrorScreen';


const PrivateRoute = ({ children }) => {
  const dispatch = useDispatch();
  const isUserResolved = useSelector(userSelectors.isResolvedSelector);
  const { orgName } = useParams();
  const navigate = useNavigate();
  const auth0 = useAuth0();
  const [isSuspended, setIsSuspended] = useState(false);

  const resolveUserInfo = async () => {
    try {
      const accessToken = await auth0.getAccessTokenSilently();
      const { portalRole } = jwtDecode(accessToken);
      try {
        dispatch(userActions.setUser({ portalRole }));
      } catch (e) {
        if (e.response?.status === 401 && e.response?.data.reason.includes('suspended')) {
          setIsSuspended(true);
        }
      }
    } catch (e) {
      await auth0.loginWithRedirect();
    }
  };

  const identifyAppcues = () => {
    if (window.Appcues) {
      window.Appcues.identify(auth0?.user?.sub);
    }
  };

  useDidMount(() => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: auth0.user.sub,
          email: auth0.user.email,
        },
        account: {
          id: auth0.user.org_name,
        },
      });
    }

    identifyAppcues();
    setTimeout(identifyAppcues, 1000);
    setTimeout(identifyAppcues, 2000);

    setTokenGenerator(auth0.getAccessTokenSilently);
    if (!isUserResolved) {
      resolveUserInfo();
    }
    if (!orgName) {
      navigate(`/${auth0.user.org_name}`, { replace: true });
    }
  });

  if (orgName && isUserResolved) {
    return children;
  }

  if (isSuspended) {
    return (
      <ErrorScreen
        title="Portal is suspended"
        errorCode={401}
        withHomeButton={false}
      />
    );
  }

  return <LinearProgress />;
};

PrivateRoute.propTypes = {
  children: PropTypes.any,
};

PrivateRoute.defaultProps = {
  children: undefined,
};

export default withAuth0Provider(withAuthRequired(PrivateRoute));
