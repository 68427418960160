import { finishedProcessingStatusesSet } from 'constants/artifactListConstants';

const rootSelector = state => state.artifactList;

export const artifactsSelector = state => rootSelector(state).artifacts;

export const totalItemsSelector = state => rootSelector(state).totalItems;

export const pageSelector = state => rootSelector(state).page;

export const statusSelector = state => rootSelector(state).status;

export const limitSelector = state => rootSelector(state).limit;

export const totalPagesSelector = (state) => {
  const totalItems = totalItemsSelector(state);
  const perPage = limitSelector(state);

  return Math.ceil(totalItems / perPage);
};

export const selectedArtifactsSelector = state => rootSelector(state).selectedArtifacts;

export const selectedArtifactsIdsSelector = (state) => {
  const selected = selectedArtifactsSelector(state);
  return Object.keys(selected).filter(k => selected[k]);
};

export const artifactsInProgressSelector = (state) => {
  const artifacts = artifactsSelector(state);

  if (!artifacts) {
    return [];
  }

  return artifacts.filter(item => !finishedProcessingStatusesSet.has(item.status));
};

export const areAllPageArtifactsSelectedSelector = (state) => {
  const artifacts = artifactsSelector(state);
  const selectedArtifacts = selectedArtifactsSelector(state);

  return artifacts.length > 0 && artifacts.every(artifact => selectedArtifacts[artifact.id]);
};

export const areArtifactsPartiallySelectedSelector = (state) => {
  const artifacts = artifactsSelector(state);
  const selectedArtifacts = selectedArtifactsSelector(state);

  const areSomeSelected = artifacts.some(artifact => selectedArtifacts[artifact.id]);
  const areAllSelected = areAllPageArtifactsSelectedSelector(state);

  return areSomeSelected && !areAllSelected;
};

export const sortingSelector = state => rootSelector(state).sorting;

export const searchQuerySelector = state => rootSelector(state).searchQuery;
