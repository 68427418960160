export default () => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 32,
    padding: [0, 19],
  },
  perPageInfo: {
    marginLeft: 'auto',
    marginRight: 10,
    fontSize: 16,
    lineHeight: '17px',
    color: '#767676',
  },
  number: {
    fontSize: 16,
    color: '#1b1b1b',
  },
});
