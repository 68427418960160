export default () => ({
  root: {
    marginTop: 30,
    padding: [16, 19, 15, 19],
  },
  header: {
    display: 'flex',
  },
  title: {
    margin: 0,
    fontSize: 20,
    fontWeight: 600,
    lineHeight: '22px',
    color: 'rgba(27, 27, 27, 0.8)',
  },
  total: {
    marginLeft: 30,
    marginTop: 3,
    color: 'rgba(27, 27, 27, 0.6)',
    fontSize: 16,
  },
  totalCount: {
    color: '#1B1B1B',
  },
  linearProgress: {
    marginTop: 15,
    height: 9,

    '& .MuiLinearProgress-bar': {
      borderRadius: 10,
    },
  },
  statistics: {
    marginTop: 10,
    color: 'rgba(27, 27, 27, 0.6)',
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    marginLeft: 'auto',
    fontWeight: 500,
  },
  viewProgress: {
    cursor: 'pointer',
  },
  cancelAll: {
    marginLeft: 10,
    color: '#D12525',
    cursor: 'pointer',
  },
});
