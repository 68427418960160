import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { withTheme } from '@mip/theming';
import { Tooltip } from '@mip/magic';
import { useDispatch, useSelector } from 'react-redux';
import { artifactListActions as actions } from 'redux/actions';
import { artifactListSelectors as selectors } from 'redux/selectors';

import { SortingState } from 'icons';

import styles from './ArtifactsTableSortableHeadCell.styles';

const SortableHeadCell = ({ classes, column, align, sx }) => {
  const dispatch = useDispatch();

  const sorting = useSelector(selectors.sortingSelector);

  const isDescending = sorting.sorting_direction === 'DESC';

  const handleSort = () => {
    dispatch(actions.setSorting({
      field_name: column.type,
      sorting_direction: isDescending ? 'ASC' : 'DESC',
    }));
    dispatch(actions.getArtifactListRequest({ page: 1 }));
  };

  const getIconClassName = () => {
    if (sorting.field_name !== column.type) {
      return undefined;
    }

    return isDescending ? classes.sortingStateArrowDown : classes.sortingStateArrowUp;
  };

  return (
    <Tooltip
      title={isDescending ? 'Click to sort ascending' : 'Click to sort descending'}
      placement="top"
    >
      <TableCell
        className={classes.root}
        sx={sx}
        align={align}
        onClick={handleSort}
      >
        {column.title}
        <SortingState
          className={getIconClassName()}
        />
      </TableCell>
    </Tooltip>
  );
};

SortableHeadCell.propTypes = {
  classes: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  align: PropTypes.string,
  sx: PropTypes.object,
};

SortableHeadCell.defaultProps = {
  align: undefined,
  sx: undefined,
};

export default withTheme(styles)(SortableHeadCell);
