import { request } from 'api/portal';

export const generateToken = () => request({
  method: 'POST',
  url: '/oauth/_generate-ds-token',
  cache: {
    ttl: 1000 * 60 * 9,
    interpretHeader: false,
  },
});

export const revokeToken = () => request({
  method: 'post',
  url: '/oauth/_revoke',
});

export const resolveScsItemUrl = itemId => request({
  method: 'post',
  url: `/scs-items/${itemId}/_resolve-url`,
});

export const inviteUsers = ({ invitations }) => request({
  method: 'post',
  url: '/invitations',
  data: {
    invitations,
  },
});
