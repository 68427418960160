import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button } from '@mip/magic';
import { withTheme } from '@mip/theming';

import Logo from './Logo';

import styles from './ErrorScreen.styles';

const ErrorScreen = ({ classes, title, description, errorCode, withHomeButton, style }) => (
  <div className={classes.root} style={style}>
    <Logo className={classes.logo} />
    <div className={classes.errorCode}>{errorCode}</div>
    <h1 className={classes.title}>{title}</h1>
    {description && <div className={classes.description}>{description}</div>}
    {withHomeButton && (
      <Button
        className={classes.button}
        variant="raised"
        color="primary"
        component={Link}
        to="/"
      >
        Go back
      </Button>
    )}
  </div>
);

ErrorScreen.propTypes = {
  style: PropTypes.object,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  errorCode: PropTypes.number.isRequired,
  withHomeButton: PropTypes.bool,
};

ErrorScreen.defaultProps = {
  style: undefined,
  description: null,
  withHomeButton: true,
};

export default withTheme(styles)(ErrorScreen);
