/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadingFiles: [],
  isUploadProgressDialogOpen: false,
  hasUploadingFiles: false,
  isCancelAllConfirmDialogOpen: false,
};

export const slice = createSlice({
  name: 'artifactCreation',
  initialState,
  reducers: {
    createArtifactsRequest: () => {},
    addUploadingFiles: (state, { payload: { files } }) => {
      if (!state.hasUploadingFiles) {
        state.uploadingFiles = [];
        state.hasUploadingFiles = true;
      }
      state.uploadingFiles.push(...files);
    },
    fileUploadSuccess: (state, { payload: { fileId } }) => {
      const file = state.uploadingFiles.find(({ id }) => fileId === id);

      if (file) {
        file.isUploaded = true;
      }
    },
    fileUploadFailure: (state, { payload: { fileId } }) => {
      state.uploadingFiles = state.uploadingFiles.filter(({ id }) => id !== fileId);
    },
    finishFileUploading: (state) => {
      state.hasUploadingFiles = false;
    },
    setUploadProgressDialogOpen: (state, { payload }) => {
      state.isUploadProgressDialogOpen = payload;
    },
    setCancelAllConfirmDialogOpen: (state, { payload }) => {
      state.isCancelAllConfirmDialogOpen = payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
