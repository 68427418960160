import axios from 'axios';
import { all, put, fork, takeLatest, takeEvery, select } from 'redux-saga/effects';
import { v4 as uuid } from 'uuid';

import { artifactStatisticsActions, artifactListActions, notify } from 'redux/actions';

import { supportedFileTypesSet } from 'constants/artifactListConstants';

import { artifactService } from 'api';

import { actions } from './artifactCreationReducer';
import * as selectors from './artifactCreationSelectors';

const fileTypes = {
  'application/pdf': 'PDF',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
  'application/msword': 'DOC',
  'application/vnd.ms-powerpoint': 'PPT',
};

function* createArtifact({ id, file }) {
  try {
    const signedLinkData = yield artifactService.generateSignedLink({
      fileType: fileTypes[file.type],
      fileName: file.name,
    });

    yield axios({
      method: 'put',
      url: `${signedLinkData.data.signed_link}`,
      data: file,
      headers: {
        'Content-Type': file.type,
        'x-goog-if-generation-match': 0,
      },
    });

    yield artifactService.createArtifact({
      gcsObjectId: signedLinkData.data.gcs_object_id,
      originalFileName: file.name,
    });

    yield put(actions.fileUploadSuccess({ fileId: id }));
  } catch (e) {
    yield put(actions.fileUploadFailure({ fileId: id }));
    yield put(notify({ title: 'Failed to upload the file', type: 'error' }));
  }
}

export function* createArtifactsRequestFlow({ payload: { files } }) {
  const uploadingFiles = [];

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];

    if (supportedFileTypesSet.has(file.type)) {
      uploadingFiles.push({
        id: uuid(),
        file: files[i],
        isUploaded: false,
      });
    }
  }

  if (uploadingFiles.length !== files.length) {
    yield put(notify({ title: 'Only PDF, PPT(X) and DOC(X) files are supported', type: 'warning' }));
    return;
  }

  yield put(actions.addUploadingFiles({ files: uploadingFiles }));

  for (let i = 0; i < uploadingFiles.length; i += 1) {
    yield fork(createArtifact, uploadingFiles[i]);
  }
}

export function* fileUploadSuccessFlow() {
  const areAllFilesUploaded = yield select(selectors.areAllFilesUploadedSelector);

  if (areAllFilesUploaded) {
    yield put(actions.finishFileUploading());
    yield put(artifactStatisticsActions.getStatistics());
    yield put(artifactListActions.getArtifactListRequest({}));
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.createArtifactsRequest.type, createArtifactsRequestFlow),
    takeEvery(actions.fileUploadSuccess.type, fileUploadSuccessFlow),
  ]);
}
