export default () => ({
  paper: {
    width: 480,
    maxWidth: 480,
  },
  title: {
    fontSize: 20,
  },
  cancelBtn: {
    padding: '8px 20px !important',
  },
  submitBtn: {
    padding: '8px 20px !important',
    minWidth: 82,
  },
  block: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  fields: {
    flex: 1,
    marginRight: 15,
  },
  addBtn: {
    padding: [8, 12],

    '& > span': {
      marginRight: 4,
    },
  },
  removeBtn: {
    position: 'relative',
    top: 7,
  },
  addUser: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      fill: '#767676',
    },
  },
  line: {
    marginLeft: 10,
    flexGrow: 1,
    height: 1,
    background: '#E4E4E4',
  },
});
