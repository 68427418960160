import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, Button } from '@mip/magic';
import DialogHeader from 'components/UIKit/DialogHeader';
import { artifactDeletionActions as actions } from 'redux/actions';
import { artifactDeletionSelectors as selectors } from 'redux/selectors';

import { Error as ErrorIcon } from '@mip/magic/icons';

import styles from './ArtifactDeleteDialog.styles';

const ArtifactDeleteDialog = ({ classes, artifactId, artifactTitle, onClose }) => {
  const dispatch = useDispatch();

  const isDeleting = useSelector(selectors.isDeletingSelector);

  const handleSubmit = () => {
    dispatch(actions.deleteArtifact({ id: artifactId, title: artifactTitle }));
  };

  return (
    <Dialog classes={classes} open onClose={onClose}>
      <DialogHeader title={(<span className={classes.title}><ErrorIcon color="error" /> Delete file</span>)}>
        <Button className={classes.cancel} onClick={onClose}>Cancel</Button>
        <Button
          color="error"
          variant="raised"
          loading={isDeleting}
          onClick={handleSubmit}
        >
          Delete
        </Button>
      </DialogHeader>
      <DialogContent>
        Are you sure you want to permanently delete <b>{artifactTitle}</b>? This action can’t be undone.
      </DialogContent>
    </Dialog>
  );
};

ArtifactDeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  artifactId: PropTypes.string.isRequired,
  artifactTitle: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default withTheme(styles)(ArtifactDeleteDialog);
