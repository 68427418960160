import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import cx from 'classnames';
import { ArrowRight, ArrowLeft } from 'icons';

import { styles } from './ArrowButton.styles';

const ArrowButton = ({ classes, style, left, onClick, ...otherProps }) => (
  <button
    style={style}
    className={cx(classes.root, left && classes.left)}
    onClick={onClick}
    {...otherProps}
  >
    {left ? <ArrowLeft /> : <ArrowRight />}
  </button>
);

ArrowButton.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object,
  left: PropTypes.bool,
  onClick: PropTypes.func,
};

ArrowButton.defaultProps = {
  onClick: undefined,
  style: undefined,
  left: undefined,
};

export default withTheme(styles)(ArrowButton);
