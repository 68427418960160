export default () => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fileUploader: {
    width: '100%',
    maxWidth: 755,
    margin: 'auto',
    paddingTop: 57,
    paddingBottom: 58,
  },
  fileUploaderIconBlock: {
    width: 56,
    height: 56,
  },
  fileUploaderIcon: {
    width: 30,
  },
  fileUploaderTitle: {
    marginTop: 30,
  },
});
