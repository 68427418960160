import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';

import { ThemeProvider as MagicThemeProvider } from '@mip/magic';

import store from 'redux/store';

import { NotificationsRender } from 'components/NotificationsRender';
import AppRouter from './components/AppRouter';
import ThemeProvider from './components/ThemeProvider/ThemeProvider';

import './fonts/index.css';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
// eslint-disable-next-line function-paren-newline
root.render(
  <IntlProvider locale="en">
    <Provider store={store}>
      <MagicThemeProvider>
        <ThemeProvider>
          <AppRouter />
          <NotificationsRender />
        </ThemeProvider>
      </MagicThemeProvider>
    </Provider>
  </IntlProvider>,
// eslint-disable-next-line function-paren-newline
);

const isSelenium = window.document.documentElement.getAttribute('webdriver') || navigator.webdriver;

if (!isSelenium) {
  if (
    window.location.href.includes('portal-deepsights-ai.mlsdevcloud.com') ||
    window.location.href.includes('http://localhost:3000')
  ) {
    window.beamer_config = {
      product_id: 'aUaDZzyU53964',
    };

    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    scriptElement.defer = 'defer';
    document.head.appendChild(scriptElement);
  }

  if (
    window.location.href.includes('portal-deepsights-ai.mlsdevcloud.com') ||
    window.location.href.includes('http://localhost:3000')
  ) {
    const scriptElement = document.createElement('script');

    scriptElement.innerHTML = `
      window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
      heap.load("2183982467");
    `;
    scriptElement.async = true;

    document.body.appendChild(scriptElement);
  }

  if (
    window.location.href.includes('portal-deepsights-ai.mlsdevcloud.com') ||
    window.location.href.includes('http://localhost:3000')
  ) {
    const scriptElement = document.createElement('script');

    scriptElement.innerHTML = `
      (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
      })('76f53c65-6464-4f7f-4261-0aa3b0c3fa36');
    `;
    scriptElement.async = true;

    document.body.appendChild(scriptElement);
  }

  if (
    window.location.href.includes('portal-deepsights-ai.mlsdevcloud.com') ||
    window.location.href.includes('http://localhost:3000')
  ) {
    const script1 = document.createElement('script');
    script1.type = 'text/javascript';
    script1.textContent = 'window.AppcuesSettings = { enableURLDetection: true };';

    // Create the second script element for the external script
    const script2 = document.createElement('script');
    script2.src = '//fast.appcues.com/210596.js';

    // Append both script elements to the document head (or body, as preferred)
    document.head.appendChild(script1);
    document.head.appendChild(script2);
  }
}

