import { isSameDay, format } from 'date-fns';

export const isToday = (date) => {
  const today = new Date();
  return isSameDay(today, new Date(date));
};

export const formatLastLoginDate = (date) => {
  if (!date) {
    return '';
  }
  return isToday(new Date(date)) ? 'Today' : format(new Date(date), 'MMMM dd, yyyy');
};

export const formatCreatedAtDate = (date) => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'MMMM dd, yyyy');
};
