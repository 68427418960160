import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@mip/magic';
import { Help } from 'icons';
import { useDispatch } from 'react-redux';
import { mainActions } from 'redux/actions';
import { REPORT_ISSUE_LINK } from 'constants/commonConstants';

const HelpMenu = ({ style }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowHints = () => {
    dispatch(mainActions.setHintsFlowEnabled(true));
    handleClose();
  };

  const handleReportIssueClick = () => {
    window.open(REPORT_ISSUE_LINK, '_blank', 'noopener,noreferrer');
    handleClose();
  };

  return (
    <div style={style}>
      <IconButton size="large" data-qa="ds-helpButton" onClick={handleClick}>
        <Help />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handleClose}
      >
        <MenuItem onClick={handleShowHints}>Show hints</MenuItem>
        {REPORT_ISSUE_LINK && <MenuItem onClick={handleReportIssueClick}>Report an issue</MenuItem>}
      </Menu>
    </div>
  );
};

HelpMenu.propTypes = {
  style: PropTypes.object.isRequired,
};

export default HelpMenu;
