const items = [
  {
    id: 'uinv_qkwDeDKZKCW5nPci',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932743@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA',
    created_at: '2023-08-24T12:41:18.545Z',
    expires_at: '2023-08-31T12:41:18.545Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_mHvn0TAXMSmef7gR',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932741@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy',
    created_at: '2023-08-24T12:41:18.028Z',
    expires_at: '2023-08-31T12:41:18.028Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_Nq9q1NATgsUIN35E',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932742@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: '4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91',
    created_at: '2023-08-24T12:41:17.971Z',
    expires_at: '2023-08-31T12:41:17.971Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_UF738PVgYQuvaL15',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932745@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=pJt120JYabBWJAunglL9ytiBsuvkWAoh&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'pJt120JYabBWJAunglL9ytiBsuvkWAoh',
    created_at: '2023-08-24T12:41:17.630Z',
    expires_at: '2023-08-31T12:41:17.630Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_DnMhwHlCvxmHoIf14',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932744@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP',
    created_at: '2023-08-24T12:41:17.559Z',
    expires_at: '2023-08-31T12:41:17.559Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_qkwDeDKZKCW5nPci6',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932743@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA',
    created_at: '2023-08-24T12:41:18.545Z',
    expires_at: '2023-08-31T12:41:18.545Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_mHvn0TAXMSmef7gR',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932741@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy',
    created_at: '2023-08-24T12:41:18.028Z',
    expires_at: '2023-08-31T12:41:18.028Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_Nq9q1NATgsUIN35E',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932742@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: '4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91',
    created_at: '2023-08-24T12:41:17.971Z',
    expires_at: '2023-08-31T12:41:17.971Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_UF738PVgYQuvaL15',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932745@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=pJt120JYabBWJAunglL9ytiBsuvkWAoh&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'pJt120JYabBWJAunglL9ytiBsuvkWAoh',
    created_at: '2023-08-24T12:41:17.630Z',
    expires_at: '2023-08-31T12:41:17.630Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_DnMhwHlCvxmHoIf1',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932744@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP',
    created_at: '2023-08-24T12:41:17.559Z',
    expires_at: '2023-08-31T12:41:17.559Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_qkwDeDKZKCW5nPci1',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932743@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'Vf8TikrCWyv9OxsQFvA1N1eCyBL6YQBA',
    created_at: '2023-08-24T12:41:18.545Z',
    expires_at: '2023-08-31T12:41:18.545Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_mHvn0TAXMSmef7gR2',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932741@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'NsSoYewh4BFWsMYkH5r80d0k2ncJWbAy',
    created_at: '2023-08-24T12:41:18.028Z',
    expires_at: '2023-08-31T12:41:18.028Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_Nq9q1NATgsUIN35E3',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932742@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: '4Y9KBDS4OVlaBF0cy73wVoE63Z1MSg91',
    created_at: '2023-08-24T12:41:17.971Z',
    expires_at: '2023-08-31T12:41:17.971Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_UF738PVgYQuvaL154',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932745@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=pJt120JYabBWJAunglL9ytiBsuvkWAoh&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'pJt120JYabBWJAunglL9ytiBsuvkWAoh',
    created_at: '2023-08-24T12:41:17.630Z',
    expires_at: '2023-08-31T12:41:17.630Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
  {
    id: 'uinv_DnMhwHlCvxmHoIf15',
    connection_id: 'con_Z2OYSDmuW9kMllTB',
    client_id: 'uKl83k54CfWeMOiJD3NCVC7Sk1R9ohxq',
    inviter: {
      name: 'Irina Rogozhova',
    },
    invitee: {
      email: 'happy_beaver9837932744@gmail.com',
    },
    invitation_url: 'https://portal-deepsights-ai.mlsdevcloud.com/?invitation=A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP&organization=org_RdkBiJK6W3kifNCn&organization_name=mercedes-benz-org',
    ticket_id: 'A4jSPpC2D0BWuDiuqrvkJUr9MmIZVQwP',
    created_at: '2023-08-24T12:41:17.559Z',
    expires_at: '2023-08-31T12:41:17.559Z',
    organization_id: 'org_RdkBiJK6W3kifNCn',
    role: 'CONTENT_MANAGER',
  },
];

// eslint-disable-next-line no-param-reassign
items.forEach((item) => { item.id = Math.random(); });

export default items;
