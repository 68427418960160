import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mip/magic';

const TextFieldController = ({
  className,
  control,
  name,
  label,
  helperText,
  rules,
  maxLength,
  multiline,
  placeholder,
  dataQa,
}) => {
  const handleKeyPress = (e) => {
    if (!multiline && e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          className={className}
          fullWidth
          multiline={multiline}
          value={value}
          label={label}
          variant="outlined"
          maxLength={maxLength}
          error={Boolean(error)}
          helperText={error ? error.message : helperText}
          data-qa={dataQa}
          placeholder={placeholder}
          autoComplete="off"
          onKeyPress={handleKeyPress}
          onChange={onChange}
        />
    )}
      rules={{
      required: 'Field is required',
      ...rules,
    }}
    />
  );
};

TextFieldController.propTypes = {
  className: PropTypes.string,
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  rules: PropTypes.object,
  maxLength: PropTypes.number,
  multiline: PropTypes.bool,
  dataQa: PropTypes.string,
  placeholder: PropTypes.string,
};

TextFieldController.defaultProps = {
  className: undefined,
  helperText: undefined,
  rules: undefined,
  maxLength: undefined,
  multiline: undefined,
  dataQa: undefined,
  placeholder: undefined,
};

export default TextFieldController;
