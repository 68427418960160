export default ({
  styleOverrides: {
    root: {
      '& svg': {
        width: 20,
        height: 20,
      },
    },
    sizeLarge: {
      '& svg': {
        width: 24,
        height: 24,
      },
    },
  },
});
