import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { alertsSelectors } from 'redux/selectors';
import { close } from 'redux/alerts/alertsReducer';

import { Notification } from './Notification';

const NotificationsRender = ({ autoHiding, timeout }) => {
  const dispatch = useDispatch();
  const notifications = useSelector(alertsSelectors.alertsSelector);

  return notifications.map(data => (
    <Notification
      key={data.id}
      data={data}
      onClose={id => dispatch(close({ id }))}
      autoHiding={autoHiding}
      timeout={timeout}
    />
  ));
};

NotificationsRender.propTypes = {
  autoHiding: PropTypes.bool,
  timeout: PropTypes.number,
};

NotificationsRender.defaultProps = {
  autoHiding: true,
  timeout: 5000,
};

export { NotificationsRender };
