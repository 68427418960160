// Snackbar fires onclose because ClickAway happens
export const MOUSE_EVENT_MOUSE_UP = 'mouseup';

export const VARIANTS = {
  NOTIFICATION: 'notification',
  SYSTEM: 'system',
};

export const TYPES = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
};
