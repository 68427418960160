import React from 'react';
import { IconButton as MagicIconButton } from '@mip/magic';

import { withTheme } from '@mip/theming';

import styles from './IconButton.styles';

const IconButton = props => (
  <MagicIconButton {...props} />
);

export default withTheme(styles)(IconButton);
