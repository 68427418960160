import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';

import { styles } from './PulsatingDot.styles';

const PulsatingDot = ({ classes, style, onClick }) => (
  <div className={classes.root} style={style} onClick={onClick}>
    <div className={classes.pulsation} />
    <div className={classes.circle} />
  </div>
);

PulsatingDot.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

PulsatingDot.defaultProps = {
  onClick: undefined,
};

export default withTheme(styles)(PulsatingDot);
