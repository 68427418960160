import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';

import styles from './AvatarPlaceholder.styles';

const AvatarPlaceholder = ({ classes }) => (
  <div className={classes.root}>
    <svg viewBox="0 0 110 110">
      <circle cx="55" cy="55" r="50" className={classes.dashed} />
      <foreignObject x="5" y="5" height="24px" width="24px" />
    </svg>
  </div>
);

AvatarPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(AvatarPlaceholder);
