import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { Stack } from '@mui/material';

const UserManagementActions = ({ children }) => {
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return domReady ? createPortal(
    <Stack direction="row" spacing="8px">{children}</Stack>,
    document.getElementById('user-management-actions-container'),
  ) : null;
};

UserManagementActions.propTypes = {
  children: PropTypes.array.isRequired,
};

export default UserManagementActions;
