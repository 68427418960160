export default () => ({
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0,

    '& svg': {
      marginBottom: -1,
    },
  },
  title: {
    marginLeft: 5,
  },
});
