import { all, put, takeLatest, select } from 'redux-saga/effects';
import { userDeletionActions as actions, memberListActions, notify } from 'redux/actions';
import { request } from 'api/portal';
import * as selectors from '../memberList/memberListSelectors';

export function* deleteUserFlow({ payload: { id } }) {
  try {
    yield request({
      method: 'delete',
      url: '/members/_delete',
      data: {
        id,
      },
    });
    yield put(actions.deleteUserSuccess({ id }));
    yield put(notify({ title: 'User was successfully deleted', type: 'success' }));

    const currentPage = yield select(selectors.pageSelector);
    yield put(memberListActions.getMemberListRequest({ page: currentPage }));
  } catch (e) {
    yield put(actions.deleteUserFailure());
    yield put(notify({ title: 'Failed to delete user', type: 'error' }));
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.deleteUser.type, deleteUserFlow),
  ]);
}
