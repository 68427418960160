/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  items: null,
  totalItems: null,
  page: 1,
  limit: 10,
  searchQuery: '',
};

export const slice = createSlice({
  name: 'memberList',
  initialState,
  reducers: {
    initStoreFromUrlParams: (state, { payload: { searchParams } }) => {
      state.page = Number(searchParams.get('page')) || 1;
      state.role = searchParams.get('role') || 'any';
      state.limit = Number(searchParams.get('limit')) || 10;
      state.searchQuery = searchParams.get('q') || '';
    },
    getMemberListRequest: (state, { payload: { page } }) => {
      if (page) {
        state.page = page;
      }
    },
    getMemberListSuccess: (state, { payload: { data } }) => {
      state.items = data.items;
      state.totalItems = data.total_items;
    },
    getMemberListFailure: () => {},
    setLimit: (state, { payload: { value } }) => {
      state.limit = value;
    },
    updateMemberRole: (state, { payload: { id, role } }) => {
      state.items.map(item => (item.id === id ? { ...item, role } : item));
    },
    setSearchQuery: (state, { payload: { query } }) => {
      state.searchQuery = query;
    },
    reset: () => initialState,
  },
});

export const { actions } = slice;

export default slice.reducer;
