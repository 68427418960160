import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { mainActions } from 'redux/actions';

import { withTheme } from '@mip/theming';
import { LeftPanel as ExpandIcon } from '@mip/magic/icons';
import { IconButton as MagicIconButton, Tooltip } from '@mip/magic';

import { UploadNavButton, Home, Logout, User, UserManagement } from 'icons';

import { userSelectors, layoutSelectors } from 'redux/selectors';

import useLogout from 'hooks/useLogout';

import { USER_MANAGEMENT_ENABLED } from 'featureToggles';

import Logo from './logo';
import NavigationItem from './NavigationItem';

import styles from './Navigation.styles';

const Navigation = ({ classes, onToggleExpand }) => {
  const { pathname } = useLocation();
  const { orgName } = useParams();
  const isContentManager = useSelector(userSelectors.isContentManagerSelector);

  const isNavigationExpanded = useSelector(layoutSelectors.isNavigationExpandedSelector);

  const dispatch = useDispatch();

  const logout = useLogout();

  const handleOpenUserProfile = () => {
    dispatch(mainActions.setUserProfileOpen(true));
  };

  const LogoBlock = () => (
    <Tooltip
      title={isNavigationExpanded ? 'Collapse' : 'Expand'}
      placement="right"
    >
      <MagicIconButton
        className={classes.logoButton}
        onClick={onToggleExpand}
      >
        <Logo className={classes.logo} />
        <div className={classes.expandIconWrapper}>
          <ExpandIcon className={classes.expandIcon} />
        </div>
      </MagicIconButton>
    </Tooltip>
  );

  return (
    <div className={classes.root}>
      <div className={classes.logoBlock}>
        <LogoBlock />
        {isNavigationExpanded && <span className={classes.logoTitle}>DeepSights</span>}
      </div>
      <div className={classes.divider} />
      <div className={classes.content}>
        <div className={classes.topLinks}>
          <div className={classes.topLinksTitle}>Menu</div>
          <NavigationItem
            name="Home"
            icon={<Home />}
            active={pathname === `/${orgName}`}
            component={Link}
            linkTo={`/${orgName}?new=1`}
            dataQa="navigation-home"
            isExpanded={isNavigationExpanded}
          />
          {isContentManager &&
            <NavigationItem
              name="Upload"
              icon={<UploadNavButton />}
              active={pathname === `/${orgName}/file-uploader`}
              component={Link}
              linkTo={`/${orgName}/file-uploader`}
              dataQa="fileUploaderLink"
              isExpanded={isNavigationExpanded}
            />
          }
          {USER_MANAGEMENT_ENABLED &&
            <NavigationItem
              name="User Management"
              icon={<UserManagement width={19} />}
              active={pathname.includes(`/${orgName}/user-management`)}
              component={Link}
              linkTo={`/${orgName}/user-management`}
              dataQa="navigation-userManagement"
              isExpanded={isNavigationExpanded}
            />
          }
        </div>
        <div className={classes.bottomLinks}>
          <NavigationItem
            name="User Profile"
            icon={<User width={19} />}
            dataQa="navigation-userProfile"
            isExpanded={isNavigationExpanded}
            onClick={handleOpenUserProfile}
          />
          <NavigationItem
            name="Log Out"
            icon={<Logout width={17} />}
            dataQa="navigation-logout"
            isExpanded={isNavigationExpanded}
            onClick={() => logout()}
          />
        </div>
      </div>
    </div>
  );
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
  onToggleExpand: PropTypes.func.isRequired,
};

export default withTheme(styles)(Navigation);
