const text = {
  color: 'rgba(27, 27, 27, 0.6)',

  '&:hover': {
    background: 'rgba(27, 27, 27, 0.06)',
  },

  '&:active': {
    background: 'rgba(27, 27, 27, 0.12)',
  },

  '&.Mui-disabled': {
    color: 'rgba(27, 27, 27, 0.4)',
  },

  '&.Mui-focusVisible': {
    background: 'linear-gradient(0deg, rgba(27, 27, 27, 0.17), rgba(27, 27, 27, 0.17)), #FFFFFF',
  },
};

export default {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      '&.MuiButton-containedPrimary': {
        '&.Mui-disabled': {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.86), rgba(255, 255, 255, 0.86)), #424FF5',
          color: 'rgba(66, 79, 245, 0.4)',
          opacity: 1,
        },

        '&:hover': {
          background: 'linear-gradient(0deg, rgba(27, 27, 27, 0.12), rgba(27, 27, 27, 0.12)), #424FF5',
        },

        '&:active': {
          background: 'linear-gradient(0deg, rgba(27, 27, 27, 0.28), rgba(27, 27, 27, 0.28)), #424FF5',
        },

        '& .MuiCircularProgress-root': {
          color: '#424FF599',
        },
      },
      '&.MuiButton-containedSecondary': {
        '&:active': {
          background: 'linear-gradient(0deg, rgba(66, 79, 245, 0.28), rgba(66, 79, 245, 0.28)), #EAEBFF',
        },
      },
      '&.MuiButton-containedWarning': {
        '&.Mui-disabled': {
          background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.56), rgba(255, 255, 255, 0.56)), #F59842',
          color: 'rgba(27, 27, 27, 0.4)',
        },

        '&:hover': {
          background: 'linear-gradient(0deg, rgba(27, 27, 27, 0.06), rgba(27, 27, 27, 0.06)), #F59842',
        },

        '&:active': {
          background: 'linear-gradient(0deg, rgba(27, 27, 27, 0.18), rgba(27, 27, 27, 0.18)), #F59842',
        },
      },
      '&.MuiButton-text': text,
      '&.MuiIconButton-colorSecondary': text,
      '&.MuiButton-root, &.MuiIconButton-root': {
        border: '1px solid transparent',

        '&.Mui-focusVisible': {
          transition: 'none',
          borderColor: 'white',
          outline: '1px solid #424FF5',
        },
      },
      '&.MuiIconButton-root.Mui-focusVisible': {
        borderColor: 'transparent',
      },
    },
  },
};
