import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@mui/material';
import { Chip } from 'components/UIKit';
import { Tooltip } from '@mip/magic';

import { statuses, BINARY_REJECTION_REASONS } from 'constants/artifactListConstants';

const FAILED_STATUSES_SET = new Set(statuses.failed);

const PROCESSING_STATUSES_SET = new Set(statuses.processing);

const ArtifactStatusCell = ({ status, binaryRejectionReason }) => {
  const renderChip = () => {
    if (status === 'COMPLETED') {
      return (
        <Chip
          title="Completed"
          color="green"
        />
      );
    }

    if (FAILED_STATUSES_SET.has(status)) {
      return (
        <Tooltip title={BINARY_REJECTION_REASONS[binaryRejectionReason] || binaryRejectionReason} placement="top">
          <span>
            <Chip
              title="Failed"
              color="orange"
            />
          </span>
        </Tooltip>
      );
    }

    if (PROCESSING_STATUSES_SET.has(status)) {
      return (
        <Chip
          title="Processing"
          color="purple"
        />
      );
    }

    return null;
  };

  return (
    <TableCell>
      {renderChip()}
    </TableCell>
  );
};

ArtifactStatusCell.propTypes = {
  status: PropTypes.string.isRequired,
  binaryRejectionReason: PropTypes.string,
};

ArtifactStatusCell.defaultProps = {
  binaryRejectionReason: null,
};

export default ArtifactStatusCell;
