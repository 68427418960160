/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload: { portalRole } }) => {
      state.isResolved = true;
      state.portalRole = portalRole;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
