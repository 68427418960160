export default () => ({
  root: {
    height: '100%',
  },
  logoBlock: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: 40,
    height: 40,
  },
  logoTitle: {
    marginLeft: 12,
    color: '#474747',
    fontSize: 15,
    fontWeight: 400,
  },
  logoButton: {
    width: 40,
    height: 40,
    border: 'none !important',
    borderRadius: '50%',
    overflow: 'hidden',

    '& $logo': {
      width: '100%',
      height: '100%',
    },

    '&:hover': {
      '& $expandIconWrapper': {
        visibility: 'visible',
      },
    },
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    width: '100%',
    height: '100%',
  },
  expandIconWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    width: '100%',
    height: '100%',
    backgroundColor: '#F0F0F0',
    visibility: 'hidden',
  },
  expandIcon: {
    width: 24,
    height: 24,
  },
  divider: {
    height: 1,
    marginTop: 12,
    marginBottom: 12,
    backgroundColor: '#D4D4D4',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 65px)',
  },
  topLinks: {
    display: 'flex',
    flexDirection: 'column',
    color: '#788293',
    fontSize: 12,
  },
  topLinksTitle: {
    marginBottom: 8,
    paddingLeft: 5,
  },
  bottomLinks: {
    display: 'flex',
    flexDirection: 'column',
  },
});
