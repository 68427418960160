import React from 'react';
import { useSelector } from 'react-redux';

import AccessDeniedPage from 'pages/AccessDeniedPage';

import { userSelectors } from 'redux/selectors';

const withContentManagerRequired = WrappedComponent => (props) => {
  const isContentManager = useSelector(userSelectors.isContentManagerSelector);

  return isContentManager ? <WrappedComponent {...props} /> : <AccessDeniedPage />;
};

export default withContentManagerRequired;
