import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FormControl, MenuItem } from '@mip/magic';
import Select from 'components/UIKit/Select';

import { artifactListSelectors as selectors } from 'redux/selectors';
import { artifactListActions as actions } from 'redux/actions';

const ArtifactListLimitSelector = () => {
  const dispatch = useDispatch();

  const limit = useSelector(selectors.limitSelector);

  const handleChange = (e) => {
    dispatch(actions.setLimit({ value: e.target.value }));
    dispatch(actions.getArtifactListRequest({ page: 1 }));
  };

  return (
    <FormControl sx={{ width: 56, marginLeft: '10px' }}>
      <Select
        value={limit}
        SelectDisplayProps={{ 'data-qa': 'limitSelect' }}
        onChange={handleChange}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={20}>20</MenuItem>
        <MenuItem value={30}>30</MenuItem>
        <MenuItem value={40}>40</MenuItem>
        <MenuItem value={50}>50</MenuItem>
      </Select>
      <div data-hint="results" style={{ position: 'absolute', bottom: 18, left: '20%' }} />
    </FormControl>
  );
};

export default ArtifactListLimitSelector;
