import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, InputLabel, MenuItem } from '@mip/magic';

import Select from 'components/UIKit/Select';

import { artifactListSelectors as selectors, artifactStatisticsSelectors } from 'redux/selectors';
import { artifactListActions as actions } from 'redux/actions';

const ArtifactListStatusSelect = () => {
  const dispatch = useDispatch();
  const artifactByStatus = useSelector(artifactStatisticsSelectors.artifactsByStatusSelector);

  const selectValue = useSelector(selectors.statusSelector);

  const handleChange = (e) => {
    dispatch(actions.setStatus({ value: e.target.value }));
    dispatch(actions.getArtifactListRequest({ page: 1 }));
  };

  const menuItems = [
    {
      value: 'all',
      title: 'All',
      visible: true,
    },
    {
      value: 'processing',
      title: 'Progress',
      visible: artifactByStatus.processing > 0,
    },
    {
      value: 'completed',
      title: 'Completed',
      visible: artifactByStatus.completed > 0,
    },
    {
      value: 'failed',
      title: 'Failed',
      visible: artifactByStatus.failed > 0,
    },
  ];

  return (
    <FormControl sx={{ width: 185, mt: '-19px' }}>
      <InputLabel>Status</InputLabel>
      <Select
        SelectDisplayProps={{ 'data-qa': 'statusSelect' }}
        value={selectValue}
        onChange={handleChange}
      >
        {menuItems.map(({ value, title, visible }) => (
          <MenuItem key={value} value={value} style={{ display: visible ? undefined : 'none' }}>{title}</MenuItem>
        ))}
      </Select>
      <div data-hint="statusSelect" style={{ position: 'absolute', bottom: 18, left: '41%' }} />
    </FormControl>
  );
};

export default ArtifactListStatusSelect;
