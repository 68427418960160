import { statuses as artifactStatuses } from 'constants/artifactListConstants';

const getArtifactsSumByStatus = (data, statuses) => {
  let sum = 0;

  statuses.forEach((status) => {
    sum += data[status] || 0;
  });

  return sum;
};

const getArtifactsByStatusObject = (data) => {
  const res = {};

  ['completed', 'processing', 'failed'].forEach((status) => {
    res[status] = getArtifactsSumByStatus(data, artifactStatuses[status]);
  });

  return res;
};

export default getArtifactsByStatusObject;
