export default () => ({
  root: {
    width: 40,
    height: 40,
    borderRadius: 8,

    '&>svg': {
      width: 'auto',
      height: 'auto',
      color: '#7F7F7F',
    },

    '&.Mui-active svg': {
      color: '#0F0F0F',
    },

    '&.Mui-active, &:hover': {
      backgroundColor: '#F2F3F4 !important',
    },
  },
});
