import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { withTheme } from '@mip/theming';
import cx from 'classnames';
import { Input, IconButton } from '@mip/magic';
import InputAdornment from '@mui/material/InputAdornment';
import { Search as SearchIcon, Close as CloseIcon } from '@mip/magic/icons';

import { artifactListActions as actions } from 'redux/actions';
import { artifactListSelectors as selectors } from 'redux/selectors';

import styles from './SearchBox.styles';

const SearchBox = ({ classes, isDisabled }) => {
  const searchQuery = useSelector(selectors.searchQuerySelector);

  const [query, setQuery] = useState(searchQuery);

  const dispatch = useDispatch();

  const handleChangeQuery = (e) => {
    setQuery(e.target.value);
  };

  const searchArtifacts = () => {
    dispatch(actions.setSearchQuery({ query }));
    dispatch(actions.getArtifactListRequest({ page: 1 }));
  };

  const handleSearchButtonMouseDown = (e) => {
    e.preventDefault();
    searchArtifacts();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchArtifacts();
    }
  };

  const handleClearButtonMouseDown = (e) => {
    e.preventDefault();
    setQuery('');
    dispatch(actions.setSearchQuery({ query: '' }));
    dispatch(actions.getArtifactListRequest({ page: 1 }));
  };

  return (
    <Input
      className={classes.root}
      inputProps={{ className: classes.input }}
      value={query}
      placeholder="Search"
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            size="small"
            className={cx(classes.clearButton, query.length > 0 && classes.clearButtonVisible)}
            onMouseDown={handleClearButtonMouseDown}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={isDisabled}
            onMouseDown={handleSearchButtonMouseDown}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )}
      disabled={isDisabled}
      onChange={handleChangeQuery}
      onKeyDown={handleKeyDown}
    />
  );
};

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

SearchBox.defaultProps = {
  isDisabled: false,
};

export default withTheme(styles)(SearchBox);
