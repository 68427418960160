import React, { useEffect } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LinearProgress } from '@mip/magic';

const withAuthRequired = WrappedComponent => (props) => {
  const { orgName } = useParams();
  const [searchParams] = useSearchParams();
  const isInvitationLink = searchParams.get('invitation');
  const navigate = useNavigate();

  const {
    user,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  const hasAccess = !isInvitationLink && isAuthenticated && (!orgName || orgName === user.org_name);

  useEffect(() => {
    if (isLoading || hasAccess) {
      return;
    }
    if (orgName && orgName === user?.['org_id']) {
      navigate(`/${user.org_name}`, { replace: true });
      return;
    }
    (async (): Promise<void> => {
      await loginWithRedirect({
        appState: {
          returnTo: window.location.pathname + (isInvitationLink ? '' : window.location.search),
        },
      });
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasAccess,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  ]);

  if (hasAccess && orgName) {
    localStorage.setItem('last-org-name', orgName);
  }

  return hasAccess ? <WrappedComponent {...props} /> : <LinearProgress />;
};

export default withAuthRequired;
