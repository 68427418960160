export default () => ({
  paper: {
    width: 480,
    maxWidth: 480,
  },
  title: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 15,
    },
  },
  cancel: {
    color: '#494949',
  },
  submitBtn: {
    minWidth: 82,
  },
});
