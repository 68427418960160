export const USER_ROLES = {
  CONTENT_MANAGER: 'CONTENT_MANAGER',
  CONTENT_CONSUMER: 'CONTENT_CONSUMER',
  PLATFORM_OWNER: 'PLATFORM_OWNER',
};

export const userRolesProps = {
  [USER_ROLES.CONTENT_MANAGER]: {
    title: 'Content Manager',
    color: 'purple',
  },
  [USER_ROLES.CONTENT_CONSUMER]: {
    title: 'Content Consumer',
    color: 'blue',
  },
  [USER_ROLES.PLATFORM_OWNER]: {
    title: 'Platfom Owner',
    color: 'green',
  },
};
