export default () => ({
  paper: {
    marginTop: 19,
    paddingTop: 40,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
});
