import React from 'react';
import { withTheme } from '@mip/theming';
import { DialogHeader as MagicDialogHeader } from '@mip/magic';

import styles from './DialogHeader.styles';

const DialogHeader = props => (
  <MagicDialogHeader {...props} />
);

export default withTheme(styles)(DialogHeader);
