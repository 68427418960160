import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { LinearProgress, Typography } from '@mip/magic';
import { withTheme } from '@mip/theming';

import { useDidMount } from '@mip/utils';

import { userSelectors } from 'redux/selectors';

import { artifactService } from 'api';
import PageWrapper from 'components/PageWrapper';
import ErrorScreen from 'components/ErrorScreen';

import { notify } from 'redux/actions';

import styles from './ArtifactViewerPage.styles';

const ArtifactViewerPage = ({ classes }) => {
  const isContentManager = useSelector(userSelectors.isContentManagerSelector);
  const [errorMessage, setErrorMessage] = useState(null);
  const [is404, setIs404] = useState(false);
  const [link, setLink] = useState(null);
  const { artifactId } = useParams();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const resolveArtifactLink = async () => {
    try {
      if (isContentManager) {
        const { data: artifact } = await artifactService.getArtifact(artifactId);

        if (artifact.total_pages === 0) {
          setErrorMessage('Something went wrong');
          return;
        }
      }

      const { data } = await artifactService.getArtifactGcsObjectLink({ artifactId });
      setLink(data.signed_link);
    } catch (e) {
      if (e.response?.status === 404) {
        setIs404(true);
      } else {
        dispatch(notify({ title: 'Something went wrong', type: 'error' }));
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }
  };

  useDidMount(() => {
    resolveArtifactLink();
  });

  const getIframeLink = () => {
    const page = searchParams.get('page');

    return page ? `${link}#page=${page}` : link;
  };

  const renderContent = () => {
    if (is404) {
      return (
        <ErrorScreen
          style={{ position: 'relative' }}
          title="Document is not found"
          description="Sorry, we can’t find the document you’re looking for"
          errorCode={404}
        />
      );
    }

    if (errorMessage) {
      return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography sx={{ mt: '30px' }}>{errorMessage}</Typography>
        </div>
      );
    }

    if (!link) {
      return <LinearProgress />;
    }

    return <iframe className={classes.iframe} title="document" src={getIframeLink()} />;
  };

  return (
    <PageWrapper isFullWidthContent>
      {renderContent()}
    </PageWrapper>
  );
};

ArtifactViewerPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactViewerPage);
