import { hintList } from '../constants';

const getHintElements = () => {
  const hintsArray = [];

  const elements = Array.from(document.querySelectorAll('[data-hint]'));

  hintList.forEach((hint) => {
    const element = elements.find(item => item.getAttribute('data-hint') === hint.dataHint);

    if (element) {
      hintsArray.push({
        ...hint,
        coordinates: {
          top: element.getBoundingClientRect().top,
          left: element.getBoundingClientRect().left,
        },
      });
    }
  });

  return hintsArray;
};

export default getHintElements;
