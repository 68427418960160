export const styles = () => ({
  root: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 34,
    height: 34,
  },
  circle: {
    width: 18,
    height: 18,
    backgroundColor: '#91EAE4',
    borderRadius: '50%',
  },
  '@keyframes pulsate': {
    '0%': {
      transform: 'scale(0)',
      opacity: 0,
    },
    '50%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2)',
      opacity: 0,
    },
  },
  pulsation: {
    position: 'absolute',
    top: 8,
    left: 8,
    height: 18,
    width: 18,
    backgroundColor: 'rgba(145, 234, 228, 0.5)',
    borderRadius: '50%',
    animation: 'infinite pulsate 1.7s',
    animationTimingFunction: 'ease',
  },
});
