import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from '@mip/theming';
import cx from 'classnames';
import { Input, IconButton } from '@mip/magic';
import InputAdornment from '@mui/material/InputAdornment';
import { Search as SearchIcon, Close as CloseIcon } from '@mip/magic/icons';

import styles from './SearchBox.styles';

const SearchBox = ({
  classes,
  searchQuery,
  isDisabled,
  onSearch,
  onClearSearch,
}) => {
  const [value, setValue] = useState(searchQuery);

  useEffect(() => {
    setValue(searchQuery);
  }, [searchQuery]);

  const handleChangeQuery = (e) => {
    setValue(e.target.value);
  };

  const handleSearchButtonMouseDown = (e) => {
    e.preventDefault();
    onSearch({ value });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch({ value });
    }
  };

  const handleClearButtonMouseDown = (e) => {
    e.preventDefault();
    onClearSearch();
  };

  return (
    <Input
      className={classes.root}
      inputProps={{ className: classes.input }}
      value={value}
      placeholder="Search users"
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            size="small"
            className={cx(classes.clearButton, searchQuery.length > 0 && classes.clearButtonVisible)}
            onMouseDown={handleClearButtonMouseDown}
          >
            <CloseIcon />
          </IconButton>
          <IconButton
            size="small"
            disabled={isDisabled}
            onMouseDown={handleSearchButtonMouseDown}
          >
            <SearchIcon />
          </IconButton>
        </InputAdornment>
      )}
      disabled={isDisabled}
      onChange={handleChangeQuery}
      onKeyDown={handleKeyDown}
    />
  );
};

SearchBox.propTypes = {
  classes: PropTypes.object.isRequired,
  searchQuery: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onSearch: PropTypes.func.isRequired,
  onClearSearch: PropTypes.func.isRequired,
};

SearchBox.defaultProps = {
  isDisabled: false,
};

export default withTheme(styles)(SearchBox);
