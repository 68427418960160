export default () => ({
  root: {
    display: 'inline-block',
    fontFamily: 'Matter',
    fontSize: 16,
    fontWeight: 600,
    padding: [5, 10],
    borderRadius: 5,
    lineHeight: '17.5px',
  },
  purple: {
    color: '#7F7FD5',
    backgroundColor: '#E5E5F7',
  },
  blue: {
    color: '#7B9AD3',
    backgroundColor: '#E7EEFA',
  },
  green: {
    color: '#79C8C2',
    backgroundColor: '#E9FBFA',
  },
  orange: {
    color: '#F59842',
    backgroundColor: '#FDEAD9',
  },
});
