export const styles = () => ({
  root: {
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 33,
    borderRadius: 5,
    backgroundColor: '#21287A',
    color: '#ffffff',
  },
  left: {
    backgroundColor: '#353FC4',
  },
});
