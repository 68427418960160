import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@mip/theming';
import { useDispatch } from 'react-redux';

import { mainActions } from 'redux/actions';

import ArrowButton from './ArrowButton';
import HintPopover from './HintPopover';
import PulsatingDot from './PulsatingDot';

import { styles } from './Hint.styles';

const Hint = ({
  classes,
  style,
  content,
  isOpen,
  index,
  onDotClick,
  onNavigateBack,
  onNavigateForward,
}) => {
  const dispatch = useDispatch();

  const { title, description, placement } = content;
  const [hintRef, setHintRef] = useState();

  const isPopoverOpen = isOpen && Boolean(hintRef);

  const handleCloseHints = () => {
    dispatch(mainActions.setHintsFlowEnabled(false));
  };

  const handleDotClick = (e) => {
    e.stopPropagation();

    if (isPopoverOpen) {
      return;
    }
    onDotClick({ index });
  };

  return (
    <Fragment>
      <div className={classes.root} style={style} ref={setHintRef}>
        <PulsatingDot
          style={{ cursor: isPopoverOpen ? 'default' : 'pointer' }}
          onClick={handleDotClick}
        />
      </div>
      <HintPopover
        anchorEl={hintRef}
        open={isPopoverOpen}
        placement={placement}
        data-qa="ds-hint-popover"
        onClick={e => e.stopPropagation()}
      >
        <div className={classes.content}>
          <div className={classes.title}>{title}</div>
          <div>{description}</div>
          <div className={classes.dashedBorder} />
          <div className={classes.buttons}>
            <button
              className={classes.closeButton}
              data-qa="ds-hint-popover-closeHintsButton"
              onClick={handleCloseHints}
            >
              Close hints
            </button>
            <div className={classes.navigation} >
              <ArrowButton
                left
                style={{ marginRight: 10 }}
                data-qa="ds-hint-popover-prevButton"
                onClick={onNavigateBack}
              />
              <ArrowButton data-qa="ds-hint-popover-nextButton" onClick={onNavigateForward} />
            </div>
          </div>
        </div>
      </HintPopover>
    </Fragment>
  );
};

Hint.propTypes = {
  classes: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onDotClick: PropTypes.func.isRequired,
  onNavigateBack: PropTypes.func.isRequired,
  onNavigateForward: PropTypes.func.isRequired,
};

export default withTheme(styles)(Hint);
