import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { TableContainer } from '@mui/material';

const ArtifactListTableContainer = ({ className, ...otherProps }) => {
  const tableContainerRef = useRef(null);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      setScrolled(e.currentTarget.scrollTop > 0);
    };
    const scrollElement = tableContainerRef.current;

    scrollElement.addEventListener('scroll', handleScroll);

    return () => { scrollElement.removeEventListener('scroll', handleScroll); };
  }, []);

  return (
    <TableContainer
      className={cx(className, scrolled && 'MuiTableContainer-scrolled')}
      ref={tableContainerRef}
      {...otherProps}
    />
  );
};

ArtifactListTableContainer.propTypes = {
  className: PropTypes.string,
};

ArtifactListTableContainer.defaultProps = {
  className: undefined,
};

export default ArtifactListTableContainer;
