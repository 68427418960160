import _isObject from 'lodash.isobject';

const useColumnsVisibilityLocalStorage = (tableId, defaultValue) => {
  const itemKey = `${tableId}.columnsVisibility`;

  const setLocalStorageItem = value =>
    localStorage.setItem(itemKey, JSON.stringify(value));

  const getLocalStorageItem = () => {
    const data = localStorage.getItem(itemKey);

    try {
      const res = JSON.parse(data);

      if (!_isObject(res)) {
        return defaultValue;
      }

      return res;
    } catch (e) {
      return defaultValue;
    }
  };

  return { setLocalStorageItem, getLocalStorageItem };
};

export default useColumnsVisibilityLocalStorage;
