export const finishedProcessingStatusesSet = new Set([
  'COMPLETED', 'FAILED_TO_INGEST', 'FAILED_TO_PROCESS', 'FAILED_TO_REPROCESS',
]);

export const statuses = {
  // eslint-disable-next-line max-len
  all: ['SCHEDULED_FOR_PROCESSING', 'SCHEDULED_FOR_REPROCESSING', 'PROCESSING', 'REPROCESSING', 'FAILED_TO_INGEST', 'FAILED_TO_PROCESS', 'FAILED_TO_REPROCESS', 'COMPLETED'],
  processing: ['SCHEDULED_FOR_PROCESSING', 'SCHEDULED_FOR_REPROCESSING', 'PROCESSING', 'REPROCESSING'],
  completed: ['COMPLETED'],
  failed: ['FAILED_TO_INGEST', 'FAILED_TO_PROCESS', 'FAILED_TO_REPROCESS'],
};

export const COLUMNS = {
  NAME: 'NAME',
  STATUS: 'STATUS',
  TOTAL_PAGES: 'TOTAL_PAGES',
  PUBLICATION_DATE: 'PUBLICATION_DATE',
  UPLOAD_DATE: 'UPLOAD_DATE',
};

export const COLUMNS_LIST = [
  {
    type: COLUMNS.NAME,
    title: 'Name',
    isAlwaysVisible: true,
  },
  {
    type: COLUMNS.STATUS,
    title: 'Status',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.TOTAL_PAGES,
    title: 'Total Pages',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.PUBLICATION_DATE,
    title: 'Publication Date',
    isDefaultVisible: true,
  },
  {
    type: COLUMNS.UPLOAD_DATE,
    title: 'Upload Date',
    isDefaultVisible: true,
  },
];

export const supportedFileTypeList = [
  'application/pdf',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-powerpoint',
];

export const supportedFileTypesSet = new Set(supportedFileTypeList);

export const BINARY_REJECTION_REASONS = {
  UNSUPPORTED_CONTENT_TYPE: 'Unsupported content type',
  VIRUS: 'Possible virus',
  CORRUPTED_OR_PROTECTED_WITH_PASSWORD: 'Corrupted or protected with password',
  UNSUPPORTED_EXTENSION: 'Unsupported extension',
  MAX_FILE_SIZE_EXCEEDED: 'Max file size exceeded',
};
