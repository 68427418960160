export default [
  {
    role: 'PLATFORM_OWNER',
    created_at: '2023-08-02T16:04:33.496Z',
    email: 'reg-ext@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Egel',
    given_name: 'Roman',
    id: '101931099729676269601',
    locale: 'en',
    name: 'Roman Egel',
    nickname: 'reg-ext',
    // picture: 'https://lh3.googleusercontent.com/a/AAcHTtfxwlcFeMrcDCHrhL_3j3_2CCc3htq7x_TBg57DiSzh=s96-c',
    updated_at: '2023-08-23T14:19:17.526Z',
    user_id: 'google-apps|101931099729676269601',
    last_login: '2023-08-23T14:19:17.526Z',
    last_ip: '95.93.41.63',
    logins_count: 11,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-04-27T19:49:55.123Z',
    email: 'mkm-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'mkm-extern@marketlogicsoftware.com',
    nickname: 'mkm-extern',
    picture: 'https://s.gravatar.com/avatar/037b7a834aa5cfc11265e18914b18ada?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmk.png',
    updated_at: '2023-08-23T13:30:27.651Z',
    user_id: 'auth0|644ad1e35c0a0006d10f6568',
    last_login: '2023-08-23T13:30:27.650Z',
    last_ip: '77.85.1.226',
    logins_count: 73,
  },
  {
    role: 'CONTENT_CONSUMER',
    created_at: '2023-04-25T14:20:10.125Z',
    email: 'mkm-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'mkm-extern@marketlogicsoftware.com',
    nickname: 'mkm-extern',
    picture: 'https://s.gravatar.com/avatar/037b7a834aa5cfc11265e18914b18ada?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmk.png',
    updated_at: '2023-08-23T13:08:15.210Z',
    user_id: 'auth0|6447e19adb11d323ffecd98c',
    last_login: '2023-08-23T13:08:15.209Z',
    last_ip: '77.85.1.226',
    logins_count: 19,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-05T08:49:59.908Z',
    email: 'iro-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'iro-extern@marketlogicsoftware.com',
    nickname: 'iro-extern',
    picture: 'https://s.gravatar.com/avatar/18134f2c9096bcf31bde0296ebcdc90e?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fir.png',
    updated_at: '2023-08-23T10:30:11.879Z',
    user_id: 'auth0|6454c337dad598694bc7db24',
    last_password_reset: '2023-08-11T11:12:49.570Z',
    last_login: '2023-08-23T10:30:11.879Z',
    last_ip: '77.85.1.226',
    logins_count: 69,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-03T07:51:00.382Z',
    email: 'mkm-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Kaminskii',
    given_name: 'Mikhail',
    id: '103077245901269128608',
    locale: 'en',
    name: 'Mikhail Kaminskii',
    nickname: 'mkm-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTtffZgyjBcbkKS0AxV98XoUZfLRlK3LghV2pLRKvo23I=s96-c',
    updated_at: '2023-08-23T09:31:55.055Z',
    user_id: 'google-apps|103077245901269128608',
    last_login: '2023-08-23T09:31:55.055Z',
    last_ip: '77.85.1.226',
    logins_count: 26,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-06-07T18:06:22.849Z',
    email: 'mlsgeorgio@gmail.com',
    email_verified: true,
    name: 'E2E test portal admin',
    nickname: 'mlsgeorgio',
    picture: 'https://s.gravatar.com/avatar/f16f3f21178643ffbb54b84c79c652d1?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fml.png',
    updated_at: '2023-08-23T07:07:24.451Z',
    user_id: 'auth0|6480c71ed1446942f380434e',
    last_login: '2023-08-23T07:07:24.451Z',
    last_ip: '34.90.37.246',
    logins_count: 366,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-04T09:40:33.734Z',
    email: 'kky-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Krutitskii',
    given_name: 'Kirill',
    id: '100006394806525896842',
    locale: 'en',
    name: 'Kirill Krutitskii',
    nickname: 'kky-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTtdmkcD8Pv4PABYDBsVK72iqOzRjHrs1eaAI-dl2jaZY=s96-c',
    updated_at: '2023-08-22T12:12:41.232Z',
    user_id: 'google-apps|100006394806525896842',
    last_login: '2023-08-22T12:12:41.227Z',
    last_ip: '94.25.235.254',
    logins_count: 23,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-03T10:15:34.735Z',
    email: 'iro-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Rogozhova',
    given_name: 'Irina',
    id: '106073311816003614732',
    locale: 'en',
    name: 'Irina Rogozhova',
    nickname: 'iro-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTtc0P8zmHUeyARFohiH-rAwfpakL4rK9ZHFCE3CPF47Z=s96-c',
    updated_at: '2023-08-22T11:42:24.740Z',
    user_id: 'google-apps|106073311816003614732',
    last_login: '2023-08-22T11:42:24.739Z',
    last_ip: '77.85.1.226',
    logins_count: 26,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-16T18:16:46.620Z',
    email: 'mart.log.oa1@gmail.com',
    email_verified: true,
    name: 'E2E test content manager',
    nickname: 'mart.log.oa1',
    picture: 'https://s.gravatar.com/avatar/bae2b7f1870677251b84c4b41cd093c8?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fma.png',
    updated_at: '2023-08-22T08:29:56.808Z',
    user_id: 'auth0|6463c88e47a69cf80cc9f401',
    last_login: '2023-08-22T08:29:56.808Z',
    last_ip: '35.204.98.184',
    logins_count: 356,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-16T18:21:21.541Z',
    email: 'lightfelix@mail.ru',
    email_verified: true,
    name: 'E2E test content consumer',
    nickname: 'lightfelix',
    picture: 'https://s.gravatar.com/avatar/e22c999e8e2e7c7edd859ad8c9e99329?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fli.png',
    updated_at: '2023-08-22T08:13:27.979Z',
    user_id: 'auth0|6463c9a18ecdf91b38a89b24',
    last_login: '2023-08-22T08:13:27.979Z',
    last_ip: '35.204.98.184',
    logins_count: 421,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-07-24T15:19:15.076Z',
    email: 'kky-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'kky-extern@marketlogicsoftware.com',
    nickname: 'kky-extern',
    picture: 'https://s.gravatar.com/avatar/dced821fd60f156db040c49a88b96d03?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fkk.png',
    updated_at: '2023-08-17T08:38:53.967Z',
    user_id: 'auth0|64be9672d7717cea1b5f6a62',
    last_login: '2023-08-17T08:38:53.967Z',
    last_ip: '94.25.235.227',
    logins_count: 53,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-03T07:41:14.994Z',
    email: 'arina.artemenko@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Artemenko',
    given_name: 'Arina',
    id: '111562295409354211727',
    locale: 'en',
    name: 'Arina Artemenko',
    nickname: 'arina.artemenko',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTteYbyZDVrx6JDIJICXvyq-0srSze3Aqz-QIwfLD-wkX=s96-c',
    updated_at: '2023-08-16T12:53:41.374Z',
    user_id: 'google-apps|111562295409354211727',
    last_login: '2023-08-16T12:53:41.374Z',
    last_ip: '2001:9e8:f623:3500:e1a0:485:fa9a:3bcc',
    logins_count: 10,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-16T10:29:59.395Z',
    email: 'mkm-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'mkm-extern@marketlogicsoftware.com',
    nickname: 'mkm-extern',
    picture: 'https://s.gravatar.com/avatar/037b7a834aa5cfc11265e18914b18ada?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fmk.png',
    updated_at: '2023-08-16T10:32:58.013Z',
    user_id: 'auth0|64dca52740ef14deb220c3c0',
    last_login: '2023-08-16T10:32:58.013Z',
    last_ip: '77.85.1.226',
    logins_count: 3,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-11T13:30:05.842Z',
    email: 'yma-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Matveychuk',
    given_name: 'Yaroslav',
    id: '106732668975871443951',
    locale: 'en',
    name: 'Yaroslav Matveychuk',
    nickname: 'yma-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTteFuXISVDfnhTHI0MFwvnYJYD6NLBM9ZxR-mRosb49i=s96-c',
    updated_at: '2023-08-16T09:33:45.359Z',
    user_id: 'google-apps|106732668975871443951',
    last_login: '2023-08-16T09:33:45.358Z',
    last_ip: '134.122.74.189',
    logins_count: 7,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-15T13:38:24.852Z',
    email: 'mikhail.mironov-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Mironov',
    given_name: 'Mikhail',
    id: '117300796684075287606',
    locale: 'en',
    name: 'Mikhail Mironov',
    nickname: 'mikhail.mironov-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTtd5XqIrmgnZbRp73sE20eOHH9ndjc-5DhWP46gnMGJh=s96-c',
    updated_at: '2023-08-16T09:32:44.172Z',
    user_id: 'google-apps|117300796684075287606',
    last_login: '2023-08-16T09:32:44.171Z',
    last_ip: '83.97.27.132',
    logins_count: 9,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-15T21:02:58.964Z',
    email: 'fee-extern@marketlogicsoftware.com',
    email_verified: true,
    family_name: 'Eremeev',
    given_name: 'Fedor',
    id: '113379831834597713406',
    locale: 'en',
    name: 'Fedor Eremeev',
    nickname: 'fee-extern',
    picture: 'https://lh3.googleusercontent.com/a/AAcHTteW26TENATz9H4CH8PYsVAcgkP8zOgQ0D93Tectteun=s96-c',
    updated_at: '2023-08-15T21:02:58.964Z',
    user_id: 'google-apps|113379831834597713406',
    last_login: '2023-08-15T21:02:58.962Z',
    last_ip: '2a02:8308:0:df00:c84d:303c:fd3e:ecf',
    logins_count: 1,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-10T13:37:24.361Z',
    email: 'kky-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'kky-extern@marketlogicsoftware.com',
    nickname: 'kky-extern',
    picture: 'https://s.gravatar.com/avatar/dced821fd60f156db040c49a88b96d03?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fkk.png',
    updated_at: '2023-08-11T13:57:47.829Z',
    user_id: 'auth0|64d4e81440ef14deb21fc6a0',
    last_login: '2023-08-11T13:57:47.829Z',
    last_ip: '94.25.235.227',
    logins_count: 8,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-08-03T07:40:26.323Z',
    email: 'arina.artemenko@marketlogicsoftware.com',
    email_verified: true,
    name: 'arina.artemenko@marketlogicsoftware.com',
    nickname: 'arina.artemenko',
    picture: 'https://s.gravatar.com/avatar/4fa0adca456348d71a07846530b7a9ca?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Far.png',
    updated_at: '2023-08-07T05:47:43.727Z',
    user_id: 'auth0|64cb59ea4ea1970a5586c90e',
    last_login: '2023-08-07T05:47:43.727Z',
    last_ip: '2001:9e8:f62d:ec00:d4a1:588d:ce50:354d',
    logins_count: 11,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-06-20T15:19:14.275Z',
    email: 'reg-ext@marketlogicsoftware.com',
    email_verified: true,
    name: 'reg-ext@marketlogicsoftware.com',
    nickname: 'reg-ext',
    picture: 'https://s.gravatar.com/avatar/3cfcdc97e5024f4f59b59a31997f91fb?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fre.png',
    updated_at: '2023-08-04T12:11:32.861Z',
    user_id: 'auth0|6491c372bff6d3d0cac361f7',
    last_login: '2023-08-04T12:11:32.861Z',
    last_ip: '198.16.66.101',
    logins_count: 30,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-06-07T07:19:54.489Z',
    email: 'iro-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'iro-extern@marketlogicsoftware.com',
    nickname: 'iro-extern',
    picture: 'https://s.gravatar.com/avatar/18134f2c9096bcf31bde0296ebcdc90e?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fir.png',
    updated_at: '2023-08-03T07:55:10.256Z',
    user_id: 'auth0|64802f9ad1446942f38033f1',
    last_login: '2023-08-03T07:55:10.255Z',
    last_ip: '77.85.1.226',
    logins_count: 8,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-07-17T12:17:51.681Z',
    email: 'kky-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'kky-extern@marketlogicsoftware.com',
    nickname: 'kky-extern',
    picture: 'https://s.gravatar.com/avatar/dced821fd60f156db040c49a88b96d03?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fkk.png',
    updated_at: '2023-07-24T09:15:25.587Z',
    user_id: 'auth0|64b5316fc157759fdf794a9b',
    last_login: '2023-07-24T09:15:25.586Z',
    last_ip: '92.61.69.183',
    logins_count: 2,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-07-12T14:55:28.467Z',
    email: 'yaroslav.matveychuk@gmail.com',
    email_verified: true,
    name: 'yaroslav.matveychuk@gmail.com',
    nickname: 'yaroslav.matveychuk',
    picture: 'https://s.gravatar.com/avatar/132df6b8f732c60068f27f4f12f7253b?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fya.png',
    updated_at: '2023-07-12T14:56:58.529Z',
    user_id: 'auth0|64aebee0a665d95cd422e0e4',
    last_login: '2023-07-12T14:56:58.529Z',
    last_ip: '84.238.141.42',
    logins_count: 2,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-06-12T19:56:17.224Z',
    email: 'fee-extern@marketlogicsoftware.com',
    email_verified: true,
    name: 'fee-extern@marketlogicsoftware.com',
    nickname: 'fee-extern',
    picture: 'https://s.gravatar.com/avatar/977280c8dee809e8f4ba2f7d788fd096?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Ffe.png',
    updated_at: '2023-06-12T19:56:17.224Z',
    user_id: 'auth0|6487786115e596b13bbf1b01',
    last_login: '2023-06-12T19:56:17.221Z',
    last_ip: '2a02:8308:0:df00:c84d:303c:fd3e:ecf',
    logins_count: 1,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-09T16:51:30.160Z',
    email: 'kateryna.sotnychenko@marketlogicsoftware.com',
    email_verified: true,
    name: 'kateryna.sotnychenko@marketlogicsoftware.com',
    nickname: 'kateryna.sotnychenko',
    picture: 'https://s.gravatar.com/avatar/4547d1284b3960700714ef9e520f1902?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fka.png',
    updated_at: '2023-05-09T16:51:30.160Z',
    user_id: 'auth0|645a7a121ba6a4b04315520e',
    last_login: '2023-05-09T16:51:30.159Z',
    last_ip: '2a02:2454:39b:3e00:111f:bb30:779b:10',
    logins_count: 1,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-01T09:43:04.974Z',
    email: 'kam.m.a@ya.ru',
    email_verified: true,
    name: 'kam.m.a@ya.ru',
    nickname: 'kam.m.a',
    picture: 'https://s.gravatar.com/avatar/72bab7f47c11da513490c73493cd7dd7?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fka.png',
    updated_at: '2023-05-05T06:39:50.510Z',
    user_id: 'auth0|644f89a8627520b2b9c2a116',
    last_login: '2023-05-05T06:39:50.509Z',
    last_ip: '77.85.227.163',
    logins_count: 16,
  },
  {
    role: 'CONTENT_MANAGER',
    created_at: '2023-05-04T02:01:04.683Z',
    email: 'f1xp@mail.ru',
    email_verified: true,
    name: 'f1xp@mail.ru',
    nickname: 'f1xp',
    picture: 'https://s.gravatar.com/avatar/e6cfdda21952aafc594dc3037bc4e9e1?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Ff1.png',
    updated_at: '2023-05-04T02:36:12.191Z',
    user_id: 'auth0|645311e0647acdcc1bcbb609',
    last_password_reset: '2023-05-04T02:30:29.684Z',
    last_login: '2023-05-04T02:36:12.190Z',
    last_ip: '112.134.191.92',
    logins_count: 12,
  },
];
