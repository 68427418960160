import { useRef } from 'react';
import { useDidMount, useWillUnmount } from '@mip/utils';
import { useSelector, useDispatch } from 'react-redux';

import { artifactListActions, artifactStatisticsActions } from 'redux/actions';
import { artifactListSelectors, artifactStatisticsSelectors } from 'redux/selectors';

const PollArtifactsDataController = () => {
  const dispatch = useDispatch();
  const artifactsInProgressRef = useRef([]);
  const totalProcessingRef = useRef(0);
  const intervalIdRef = useRef(null);

  const artifactsOnPageInProgress = useSelector(artifactListSelectors.artifactsInProgressSelector);
  const totalProcessing = useSelector(artifactStatisticsSelectors.processingCountSelector);

  artifactsInProgressRef.current = artifactsOnPageInProgress;
  totalProcessingRef.current = totalProcessing;

  useDidMount(() => {
    intervalIdRef.current = setInterval(() => {
      if (artifactsInProgressRef.current.length > 0) {
        dispatch(artifactListActions.pollArtifacts({ ids: artifactsInProgressRef.current.map(({ id }) => id) }));
      }
      if (totalProcessingRef.current > 0) {
        dispatch(artifactStatisticsActions.getStatistics());
      }
    }, 15000);
  });

  useWillUnmount(() => {
    clearInterval(intervalIdRef.current);
  });

  return null;
};

export default PollArtifactsDataController;
