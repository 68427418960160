/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import backgroundPlugin from './backgroundPlugin';

const minPart = 0.012;

ChartJS.register(ArcElement, backgroundPlugin);

const getData = (filesByStatus, maxFiles) => {
  const dataInPercents = filesByStatus.map(files => {
    const part = files.count / maxFiles;
    if (part === 0) {
      return 0;
    } 
    if (part < minPart) {
      return minPart;
    }
    return part;
  });
  return dataInPercents;
}

const StorageDoughnutChart = ({ documentsByStatus, maxFiles }) => {
  const { completed, processing, failed, remaining } = documentsByStatus;

  const data = {
    datasets: [
      {
        data: getData([completed, processing, failed, remaining], maxFiles),
        backgroundColor: [
          completed.color,
          processing.color,
          failed.color,
          remaining.color,
        ],
        cutout: 75,
        borderRadius: 50,
        borderWidth: 0,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        plugins: {
          doughnutChartBackground: {
            color: 'rgba(228, 228, 228, 0.3)',
            enabled: true,
          },
        },
        events: [],
      }}
    />
  );
}

export default StorageDoughnutChart;

StorageDoughnutChart.propTypes = {
  documentsByStatus: PropTypes.object,
  maxFiles: PropTypes.number.isRequired,
};

StorageDoughnutChart.defaultProps = {
  documentsByStatus: undefined,
};
