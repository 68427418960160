export default ({
  styleOverrides: {
    root: {
      '&:not(.MuiInputBase-readOnly):not(.Mui-disabled)': {
        borderColor: '#E4E4E4',

        '&.Mui-focused': {
          boxShadow: 'none',
        },
      },
    },
  },
});
