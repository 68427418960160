export default () => ({
  root: {
    marginTop: 30,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filesCount: {
    display: 'flex',
    alignItems: 'baseline',
  },
  title: {
    margin: 0,
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '22px',
  },
  total: {
    marginLeft: 30,
    fontSize: 16,
    lineHeight: '18px',
    color: 'rgba(27, 27, 27, 0.6)',
  },
  totalCount: {
    color: '#1B1B1B',
  },
  paper: {
    marginTop: 16,
    paddingTop: 38,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
});
