import Axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

const axios = setupCache(Axios, { methods: ['get', 'post'] });

let tokenGenerator = null;

export const setTokenGenerator = (value) => { tokenGenerator = value; };

const request = async ({
  method,
  url,
  params,
  data,
  headers,
  cache = false,
}) => {
  const token = await tokenGenerator();
  const res = await axios({
    method,
    url,
    params,
    data,
    cache,
    headers: {
      Authorization: token,
      ...headers,
    },
    baseURL: `${window.__RUNTIME_CONFIG__.API_PORTAL_BASE_URL}/portal-ui-service`,
  });

  return res;
};

export default request;
