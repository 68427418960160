import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { portalUiService, oauthCore } from 'api';
import { useDispatch } from 'react-redux';
import { setIsGlobalLoading } from 'redux/actions';
import getRefreshToken from 'utils/auth0/getRefreshToken';

const useLogout = () => {
  const dispatch = useDispatch();
  const auth0 = useAuth0();

  const revokeRefreshToken = async () => {
    const refreshToken = getRefreshToken();

    await axios({
      method: 'POST',
      url: `https://${window.__RUNTIME_CONFIG__.AUTH_DOMAIN}/oauth/revoke`,
      headers: { 'content-type': 'application/json' },
      data: {
        client_id: window.__RUNTIME_CONFIG__.AUTH_CLIENT_ID,
        token: refreshToken,
      },
    });
  };

  const revokeTokenAndLogout = async () => {
    dispatch(setIsGlobalLoading(true));
    try {
      await Promise.all([
        revokeRefreshToken(),
        oauthCore.revokeToken(),
        portalUiService.revokeToken(),
      ]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    auth0.logout({ logoutParams: { returnTo: window.location.origin } });
  };

  return revokeTokenAndLogout;
};

export default useLogout;
