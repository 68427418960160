import background from 'images/background.png';

export default () => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    color: 'rgba(27, 27, 27, 0.8)',
    boxSizing: 'border-box',
    padding: [20, 20, 20, 0],
  },
  navigationContainer: {
    height: '100%',
    width: 56,
    flexShrink: 0,
    background: '#ffffff',
    padding: [16, 8],
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    boxShadow: '0px 0px 1px 0px rgba(0, 0, 0, 0.17), 0px 3px 8px 0px rgba(0, 0, 0, 0.17)',
    transition: 'width 0.3s ease',
  },
  expandedNavigation: {
    width: 204,
  },
  contentWrapper: {
    height: '100%',
    flexGrow: 1,
    minWidth: 0,
    marginLeft: 12,
  },
  content: {
    height: '100%',
    display: 'flex',
    boxSizing: 'border-box',
    backgroundColor: '#FAFAFA',
    borderRadius: 10,
    overflow: 'hidden',
  },
  mainContent: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 52,
  },
  isFullWidthContent: {
    padding: 0,
  },
  topContent: {
    display: 'flex',
  },
  header: {
    margin: 0,
    fontSize: 24,
    fontWeight: 600,
  },
  progressWrapper: {
    position: 'relative',
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
  },
  rightPanel: {
    marginLeft: 12,
    height: '100%',
    flexShrink: 0,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
