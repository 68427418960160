import React from 'react';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { artifactDeletionActions as actions,
  artifactListActions,
  artifactStatisticsActions,
  notify,
} from 'redux/actions';
import { artifactListSelectors as selectors } from 'redux/selectors';
import { artifactService } from 'api';

export function* deleteArtifactFlow({ payload: { id, title } }) {
  try {
    yield artifactService.deleteArtifact(id);

    yield put(actions.deleteArtifactSuccess({ id }));
    yield put(notify({ title: <div><b>{title}</b> was successfully deleted</div>, type: 'success' }));
    yield put(artifactListActions.getArtifactListRequest({}));
    yield put(artifactStatisticsActions.getStatistics());
  } catch (e) {
    yield put(actions.deleteArtifactFailure());
    yield put(notify({ title: 'Failed to delete selected files', type: 'error' }));
  }
}

let bulkErrorCounter = 0;

function* deleteArtifact(id) {
  try {
    yield artifactService.deleteArtifact(id);
  } catch (e) {
    bulkErrorCounter += 1;
  }
}

export function* bulkDeleteArtifactsFlow() {
  const ids = yield select(selectors.selectedArtifactsIdsSelector);

  yield all(ids.map(id => deleteArtifact(id)));

  yield put(actions.bulkDeleteArtifactsSuccess());
  yield put(artifactListActions.deselectAll());
  yield put(actions.closeBulkDeleteDialog());
  yield put(artifactListActions.getArtifactListRequest({}));
  yield put(artifactStatisticsActions.getStatistics());
  if (bulkErrorCounter > 0) {
    yield put(notify({ title: `${bulkErrorCounter} file(s) could not be deleted`, type: 'error' }));
    bulkErrorCounter = 0;
  } else {
    yield put(notify({ title: 'Selected files were successfully deleted', type: 'success' }));
  }
}

export default function* rootSaga() {
  /* istanbul ignore next */
  yield all([
    takeLatest(actions.deleteArtifact.type, deleteArtifactFlow),
    takeLatest(actions.bulkDeleteArtifacts.type, bulkDeleteArtifactsFlow),
  ]);
}
