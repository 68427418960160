import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { withTheme } from '@mip/theming';

import { Upload } from 'icons';

import styles from './DndPlaceholder.styles';

const DndPlaceholder = ({ classes, visible }) => (
  <div className={cx(classes.root, visible && classes.visible)}>
    <div className={classes.iconBlock}><Upload className={classes.icon} /></div>
    <div className={classes.title}>
      Drop files anywhere in this window to upload them
    </div>
    <div className={classes.description}>
      PDF, DOC(X), PPT(X)
    </div>
  </div>
);

DndPlaceholder.propTypes = {
  classes: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default withTheme(styles)(DndPlaceholder);
