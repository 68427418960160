import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme } from '@mip/theming';
import { Tooltip, IconButton } from '@mip/magic';

import { Bin } from 'icons';

import { artifactListSelectors as selectors } from 'redux/selectors';
import { artifactDeletionActions } from 'redux/actions';

import ColumnVisibilitySelect from 'components/TableSettingsProvider/ColumnVisibilitySelect';

import ArtifactListStatusSelect from './ArtifactListStatusSelect';

import styles from './ArtifactsTableSettingsLeftBlock.styles';

const renderSelectedArtifactsCount = count => (count === 1 ? '1 file is selected.' : `${count} files are selected.`);

const ArtifactsTableSettingsLeftBlock = ({ classes }) => {
  const dispatch = useDispatch();

  const selectedArtifacts = useSelector(selectors.selectedArtifactsSelector);

  const selectedArtifactsIds = Object.values(selectedArtifacts).filter(el => el);

  const handleOpenDeleteDialog = () => {
    dispatch(artifactDeletionActions.openBulkDeleteDialog());
  };

  if (selectedArtifactsIds.length > 0) {
    return (
      <div className={classes.selectedInfo}>
        <div>{renderSelectedArtifactsCount(selectedArtifactsIds.length)}</div>
        <div className={classes.actions}>
          <Tooltip title="Delete files" placement="top">
            <IconButton size="small" onClick={handleOpenDeleteDialog} data-qa="removeSelectedItemsButton">
              <Bin />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    );
  }

  return (
    <Fragment>
      <div style={{ marginRight: 10 }}>
        <ArtifactListStatusSelect />
      </div>
      <ColumnVisibilitySelect />
    </Fragment>
  );
};

ArtifactsTableSettingsLeftBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsTableSettingsLeftBlock);
