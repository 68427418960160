/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  artifactsByStatus: {
    processing: 0,
    failed: 0,
    completed: 0,
  },
  maxArtifacts: 0,
  isLoaded: false,
};

export const slice = createSlice({
  name: 'artifactStatistics',
  initialState,
  reducers: {
    getStatistics: state => state,
    getStatisticsSuccess: (state, { payload: { artifactsByStatus, maxArtifacts } }) => {
      state.artifactsByStatus = artifactsByStatus;
      state.maxArtifacts = maxArtifacts;
      state.isLoaded = true;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
