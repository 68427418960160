import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { withTheme } from '@mip/theming';
import { Paper, LinearProgress } from '@mip/magic';

import { artifactCreationActions as actions } from 'redux/actions';
import { artifactCreationSelectors as selectors } from 'redux/selectors';

import styles from './UploadingFiles.styles';

const ArtifactsBlock = ({ classes }) => {
  const dispatch = useDispatch();

  const total = useSelector(selectors.totalUploadingFilesSelector);
  const completedCount = useSelector(selectors.countUploadedSelector);
  const totalEnding = total === 1 ? '' : 's';

  const handleOpenProgressDialog = () => {
    dispatch(actions.setUploadProgressDialogOpen(true));
  };

  const handleOpenConfirmDialog = () => {
    dispatch(actions.setCancelAllConfirmDialogOpen(true));
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <h2 className={classes.title}>Uploading files</h2>
        <span className={classes.total}><span className={classes.totalCount}>{total}</span> upload{totalEnding}</span>
        <div className={classes.actions}>
          <div className={classes.viewProgress} onClick={handleOpenProgressDialog}>View Progress</div>
          <div className={classes.cancelAll} onClick={handleOpenConfirmDialog}>Cancel All</div>
        </div>
      </div>
      <LinearProgress
        className={classes.linearProgress}
        variant="determinate"
        value={(completedCount / total) * 100}
      />
      <div className={classes.statistics}>
        {completedCount} of {total} file{totalEnding}
      </div>
    </Paper>
  );
};

ArtifactsBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(ArtifactsBlock);
