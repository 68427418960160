import { all, fork } from 'redux-saga/effects';

import artifactCreation from './artifacts/artifactCreation/artifactCreationSaga';
import artifactList from './artifacts/artifactList/artifactListSaga';
import artifactStatistics from './artifacts/artifactStatistics/artifactStatisticsSaga';
import artifactDeletion from './artifacts/artifactDeletion/artifactDeletionSaga';
import memberList from './userManagement/memberList/memberListSaga';
import invitationList from './userManagement/invitationList/invitationListSaga';
import userInvitation from './userManagement/userInvitation/userInvitationSaga';
import userDeletion from './userManagement/userDeletion/userDeletionSaga';
import userRoleChange from './userManagement/userRoleChange/userRoleChangeSaga';

export default function* root() {
  yield all([
    fork(artifactCreation),
    fork(artifactList),
    fork(artifactDeletion),
    fork(artifactStatistics),
    fork(memberList),
    fork(invitationList),
    fork(userInvitation),
    fork(userDeletion),
    fork(userRoleChange),
  ]);
}
