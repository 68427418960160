export default () => ({
  pagination: {
    '& .MuiPaginationItem-page': {
      fontFamily: '"Matter", Helvetica, sans-serif',
      fontSize: 14,
      color: '#1B1B1B',

      '&.Mui-selected': {
        background: 'rgba(234, 235, 255, 0.4)',
        color: '#424FF5',
      },
    },
  },
  goToPage: {
    fontSize: 14,
  },
  goToPageInput: {
    fontFamily: '"Matter", Helvetica, sans-serif',
    border: '1px solid #E4E4E4 !important',

    '& input': {
      color: '#1B1B1B',
    },
  },
});
