export default () => ({
  paper: {
    width: 1046,
    maxWidth: 1046,
  },
  cancel: {
    color: '#494949',
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  uploads: {
    color: '#767676',
  },
  cancelAll: {
    marginRight: 8,
    color: '#D12525',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 52,
    borderBottom: '1px dashed #e4e4e4',
  },
  titleBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: 6,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 330,
  },
  status: {
    width: 305,
  },
  inProgress: {
    height: 9,
    backgroundColor: '#F4F4F4',

    '& span': {
      borderRadius: 8,
    },
  },
  finished: {
    width: '100%',
    height: 9,
    borderRadius: 8,
    backgroundColor: '#91EAE4',
  },
});
