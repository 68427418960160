import React from 'react';
import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { memberListActions as actions } from 'redux/actions';
import { withTheme } from '@mip/theming';

import PageWrapper from 'components/PageWrapper';
import { Tab, Tabs } from '@mui/material';

import InviteUsersDialog from 'components/dialogs/InviteUsersDialog';
import MemberTabContent from './MemberTabContent';
import InvitationTabContent from './InvitationTabContent';

import styles from './UserManagementPage.styles';

const UserManagementPage = ({ classes }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tab, orgName } = useParams();

  const handleTabChange = (value) => {
    dispatch(actions.reset());
    navigate(`/${orgName}/user-management/${value}`);
  };

  const activeTab = tab || 'members';

  const renderTabContent = () => {
    switch (activeTab) {
      case 'members': {
        return <MemberTabContent />;
      }
      case 'invitations': {
        return <InvitationTabContent />;
      }
      default: {
        return <MemberTabContent />;
      }
    }
  };

  return (
    <PageWrapper title="User Management">
      <div className={classes.root}>
        <Tabs
          value={activeTab}
          onChange={(event, value) => handleTabChange(value)}
        >
          <Tab label="Members" value="members" />
          <Tab label="Invitations" value="invitations" />
        </Tabs>
        <div id="user-management-actions-container" />
      </div>
      {renderTabContent()}
      <InviteUsersDialog isInvitationsPage={activeTab === 'invitations'} />
    </PageWrapper>
  );
};

UserManagementPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withTheme(styles)(UserManagementPage);
