import { actions as artifactCreationActions } from './artifacts/artifactCreation/artifactCreationReducer';
import { actions as artifactListActions } from './artifacts/artifactList/artifactListReducer';
import { actions as artifactDeletionActions } from './artifacts/artifactDeletion/artifactDeletionReducer';
import { actions as artifactStatisticsActions } from './artifacts/artifactStatistics/artifactStatisticsReducer';
import { actions as alertsActions } from './alerts/alertsReducer';
import { actions as mainActions } from './main/mainReducer';
import { actions as userActions } from './user/userReducer';
import { actions as memberListActions } from './userManagement/memberList/memberListReducer';
import { actions as invitationListActions } from './userManagement/invitationList/invitationListReducer';
import { actions as userInvitationActions } from './userManagement/userInvitation/userInvitationReducer';
import { actions as userDeletionActions } from './userManagement/userDeletion/userDeletionReducer';
import { actions as userRoleChangeActions } from './userManagement/userRoleChange/userRoleChangeReducer';
import { actions as layoutActions } from './layout/layoutReducer';

const { notify } = alertsActions;
const { setIsGlobalLoading } = mainActions;

export {
  artifactCreationActions,
  artifactListActions,
  artifactDeletionActions,
  artifactStatisticsActions,
  alertsActions,
  notify,
  mainActions,
  setIsGlobalLoading,
  userActions,
  memberListActions,
  invitationListActions,
  userInvitationActions,
  userDeletionActions,
  userRoleChangeActions,
  layoutActions,
};
