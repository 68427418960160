export default () => ({
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    background: '#EAEBFF',
    opacity: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  visible: {
    opacity: 1,
  },
  iconBlock: {
    width: 45,
    height: 45,
    background: '#424FF5',
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    color: '#FFFFFF',
  },
  title: {
    marginTop: 30,
    textAlign: 'center',
    fontWeight: 600,
    fontSize: 24,
    color: '#1B1B1B',
    lineHeight: '26px',
  },
  description: {
    marginTop: 15,
    color: 'rgba(27, 27, 27, 0.8)',
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.2,
  },
});
