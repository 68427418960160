export default () => ({
  root: {
    '&:hover': {
      backgroundColor: '#f6f6f6',
      cursor: 'pointer',
    },
    '& svg': {
      position: 'relative',
      top: 3,
      marginLeft: 7,
    },
  },
  sortingStateArrowDown: {
    '& path:first-child': {
      fillOpacity: '0.2',
    },
    '& path:last-child': {
      fillOpacity: '0.6',
    },
  },
  sortingStateArrowUp: {
    '& path:first-child': {
      fillOpacity: '0.6',
    },
    '& path:last-child': {
      fillOpacity: '0.2',
    },
  },
});
