const rootSelector = state => state.artifactCreation;

export const uploadingFilesSelector = state => rootSelector(state).uploadingFiles;

export const totalUploadingFilesSelector = (state) => {
  const uploadingFiles = uploadingFilesSelector(state);

  return uploadingFiles.length;
};

export const countUploadedSelector = (state) => {
  const uploadingFiles = uploadingFilesSelector(state);

  return uploadingFiles.filter(({ isUploaded }) => isUploaded).length;
};

export const areAllFilesUploadedSelector = (state) => {
  const totalUploadingFiles = totalUploadingFilesSelector(state);
  const countUploaded = countUploadedSelector(state);

  return totalUploadingFiles === countUploaded;
};

export const hasUploadingFiles = state => rootSelector(state).hasUploadingFiles;

export const isUploadProgressDialogOpenSelector = state => rootSelector(state).isUploadProgressDialogOpen;

export const isCancelAllConfirmDialogOpenSelector = state => rootSelector(state).isCancelAllConfirmDialogOpen;
