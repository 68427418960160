/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isInviteUsersDialogOpen: false,
  isLoading: false,
  revokeInvitationId: null,
  isRevokeInProgress: false,
};

export const slice = createSlice({
  name: 'userInvitation',
  initialState,
  reducers: {
    setInviteUsersDialogOpen: (state, { payload }) => {
      state.isInviteUsersDialogOpen = payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setRevokeInvitationId: (state, { payload: { id } }) => {
      state.revokeInvitationId = id;
    },
    revokeInvitation: (state) => {
      state.isRevokeInProgress = true;
    },
    revokeInvitationSuccess: (state) => {
      state.isRevokeInProgress = false;
      state.revokeInvitationId = null;
    },
    revokeInvitationFailure: (state) => {
      state.isRevokeInProgress = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
